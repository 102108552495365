import AppConfig from "../AppConfig";
import Live from "../Live/Live";
import Movies from "../Movies/Movies";
import pages, { ChangePage } from "../Remote/Pages";
import { content } from "../Utils/Content";
import { el } from "../Utils/Utils";


export default class Move {

    constructor ({ data_type, item }) {
        Move.data_type = data_type;
        Move.item = item;
        Move.moved_data = localStorage.getItem(`moved_data-${AppConfig.selected_server.id}`) || '{}';
        Move.moved_data = JSON.parse(Move.moved_data);
        Move.index = +Move.item.getAttribute('index');
    }
    static move_mode = false;
    static data_type;
    static move_type;
    static item;
    static category;
    static moved_data;
    static index;
    static category_items = document.getElementsByClassName('category-item-control');
    init() {
        Move.move_mode = true;

        if (!Move.moved_data[Move.data_type])
            Move.moved_data[Move.data_type] = {}


        Move.data = content[Move.data_type].categories;



        Move.item.addClass('movable-item');
    }

    static move_up(decrement) {
        if (Move.index == 4) return;
        Move.index += decrement

        let prev_sub_item = Move.item.previousElementSibling;

        if (!prev_sub_item) {
            Move.render_category_item({
                index: +Move.item.getAttribute('index') - 1,
                direction: 'up'
            })

            prev_sub_item = Move.item.previousElementSibling;
        }


        let index = Move.item.getAttribute('index');
        let next_index = +index - 1;

        Move.change_data({ index, next_index })

        Move.item.setAttribute('index', next_index);
        prev_sub_item.setAttribute('index', index);


        Move.item.insertAdjacentElement('afterend', prev_sub_item);


    }
    static move_down(increment) {
        if (Move.index == Move.data.length - 1) return;
        Move.index += increment


        let next_sub_item = Move.item.nextElementSibling;

        if (!next_sub_item) {

            Move.render_category_item({
                index: +Move.item.getAttribute('index') + 1,
                direction: 'down'
            })

            next_sub_item = Move.item.nextElementSibling;
        }

        let index = Move.item.getAttribute('index');
        let next_index = +index + 1;

        Move.change_data({ index, next_index })

        Move.item.setAttribute('index', next_index);
        next_sub_item.setAttribute('index', index);

        Move.item.insertAdjacentElement('beforebegin', next_sub_item);

    }

    static render_category_item({ index, direction }) {

        if (direction == 'up' && Move.index == 4) return;
        if (direction == 'down' && Move.index == Move.data.length) return;

        if (Move.data_type == 'live') {



            Live.re_render_category_item({
                index: index,
                direction,
            })
        } else {



            Movies.re_render_category_item({
                index: index,
                direction,
            })
        }
    }


    static change_data({ index, next_index }) {

        let data = Move.data[index];
        let current_position = data.position;

        let next_data = Move.data[next_index];
        let next_position = next_data.position;

        Move.data[index] = next_data;
        Move.data[index].position = current_position;

        Move.data[next_index] = data;
        Move.data[next_index].position = next_position;

        if (!Move.moved_data[Move.data_type]) Move.moved_data[Move.data_type] = {};



        Move.set_moved_data();
    }

    static set_moved_data() {
        Move.moved_data[Move.data_type] = {};
        Move.data.forEach((category, index) => {
            Move.moved_data[Move.data_type][category.id] = index;
        });
        Move.moved_data[Move.data_type].length = Object.keys(Move.moved_data[Move.data_type]).length;

        localStorage.setItem(`moved_data-${AppConfig.selected_server.id}`, JSON.stringify(Move.moved_data))
    }

    static close_move() {


        let data = {
            control_type: "categories",
            index: 0
        };

        for (let i = 0; i < Move.category_items.length; i++) {
            if (Move.category_items[i] == Move.item) {
                data.index = i;
                break;
            }
        }

        Move.item.removeClass('movable-item');

        Move.set_moved_data();
        Move.move_mode = false;

        ChangePage(pages.activePage, { control: data }, true, false)
    }

}