import { xtream_instance } from '..';
import ContinueWatch from '../Components/ContinueWatching/ContinueWatching';
import Favorite from '../Components/Favorite/Favorite';
import LockCategories from '../LockCategories/LockCategories';
import { keydown, move } from '../Remote/Keys';
import pages, { ChangeControl, ChangePage, Control } from '../Remote/Pages';
import { get_el, el, append, el_tag, remove_class, get_index } from '../Utils/Utils';
import get_word from '../Utils/Words';
import './MovieInfo.scss';
import PopUp from '../Components/PopUp/PopUp';
import ImageResizer from '../Components/ResizeImage/ImageResizer';

export class MovieInfo {
    constructor ({ data, similares, is_series = false }) {
        MovieInfo.data = data;

        MovieInfo.episodes_similares = similares;
        MovieInfo.is_series = is_series
        if (is_series) {
            MovieInfo.selected_season = data.seasons[0];
            MovieInfo.seasons = data.seasons
        }
    }
    static data = {};
    static similares = [];
    static episodes_similares = [];
    static selected_season = 0;
    static seasons = [];
    static is_series = false;
    static colored_btns = [
        {
            color: 'yellow',
            name: 'favorites'
        },
    ]
    static is_favorite = '';
    static is_watched = '';
    static is_locked = '';
    init() {


        if (!MovieInfo.is_series) {
            MovieInfo.similares = MovieInfo.episodes_similares.filter(item => item.id != MovieInfo.data.id)
        } else {
            MovieInfo.similares = MovieInfo.episodes_similares
        }


        let type = MovieInfo.is_series ? 'series' : 'movies'

        MovieInfo.is_favorite = Favorite.is_favorite(MovieInfo.data.id, type) >= 0 ? ' favorite' : '';
        MovieInfo.is_watched = ContinueWatch.is_watched(MovieInfo.data.id, type) ? ' watched' : '';
        MovieInfo.is_locked = LockCategories.is_locked(MovieInfo.data.category_id, type) ? ' locked' : '';
        this.render()
    }
    render() {

        let similare_title = MovieInfo.is_series ? get_word('episodes') : get_word('similar movies');

        const movie_info = el({
            attr: {
                class: 'movie-info-parent',
                id: 'movie_info_parent'
            },

            children: [
                {
                    attr: {
                        class: 'movie-info-poster opacity-0'
                    },
                    render: async (parent) => {
                        let img = await ImageResizer({ src: MovieInfo.data.backdrop_path, width: 1000, height: 600, type: 'image/jpg' })
                        parent.style.backgroundImage = `url(${img.src})`

                        parent.removeClass('opacity-0')
                    },
                },
                {
                    attr: {
                        class: 'movie-info-content'
                    },
                    children: [
                        {
                            attr: {
                                class: 'movie-info-cover-block'
                            },
                            children: [
                                {
                                    attr: {
                                        class: 'movie-info-cover opacity-0'
                                    },
                                    render: async (parent) => {
                                        let poster = await ImageResizer({ src: MovieInfo.data.cover, width: 544, height: 770, type: 'image/png' })
                                        parent.appendChild(poster)
                                        parent.classList.remove('opacity-0')
                                    }
                                },

                                {
                                    attr: {
                                        class: 'movie-info-play-back-btns'
                                    },
                                    render: (parent) => {
                                        ['play', 'back'].forEach((item, index) => {

                                            append(parent, el({
                                                attr: {
                                                    class: 'movie-info-btn movie-info-btn-control',
                                                    type: item,
                                                    index: index,
                                                    onclick: MovieInfo.click_info_btn,
                                                    onmouseenter: function () {

                                                        MovieInfo.mouse_enter_info_btn(index)

                                                    },
                                                },
                                                render: (parent) => {
                                                    if (item == 'play') {
                                                        append(parent, el({
                                                            attr: {
                                                                class: 'movie-info-btn-icon play'
                                                            }
                                                        }))
                                                    }

                                                    append(parent, el({
                                                        attr: {
                                                            class: 'movie-info-btn-text'

                                                        },
                                                        innerHTML: get_word(item)
                                                    }))
                                                }
                                            }))
                                        })
                                    }
                                }
                            ]
                        },
                        {
                            attr: {
                                class: 'movie-info-block'
                            },
                            children: [
                                {
                                    attr: {
                                        class: 'movie-info-title',
                                    },
                                    innerHTML: MovieInfo.data.name,
                                },
                                {
                                    attr: {
                                        class: `movie-info-icons-block ${MovieInfo.is_favorite} ${MovieInfo.is_watched} ${MovieInfo.is_locked}`,
                                        id: 'movie_info_icons_block'
                                    },
                                    render: (parent) => {
                                        ['watched', 'favorite', 'lock'].forEach(item => {
                                            append(parent, el({
                                                tagName: 'img',
                                                attr: {
                                                    class: `movie-info-icon ${item}`,
                                                    src: require(`../Components/images/${item}.png`)
                                                }
                                            }))

                                        })
                                    }
                                },
                                {
                                    attr: {
                                        class: 'movie-info-description',
                                        id: 'movie_info_description'
                                    },
                                    innerHTML: MovieInfo.data.description,
                                },
                                {
                                    attr: {
                                        class: 'movie-info-footer'
                                    },
                                    children: [
                                        {
                                            attr: {
                                                class: 'movie-info-genres-date-duration',
                                            },
                                            innerHTML: MovieInfo.data.release_genre_duration
                                        },
                                        {
                                            attr: {
                                                class: 'movie-info-rating-block'
                                            },
                                            children: [
                                                {
                                                    attr: {
                                                        class: 'movie-info-rating-icon'
                                                    }
                                                },
                                                {
                                                    attr: {
                                                        class: 'movie-info-rating-number'
                                                    },
                                                    innerHTML: MovieInfo.data.rating
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            attr: {
                                class: 'similares-block episode-block',
                                id: 'similares_block'
                            },
                            children: [
                                {
                                    attr: {
                                        class: 'similare-items-seasons-block',
                                    },
                                    render: (parent) => {
                                        if (MovieInfo.is_series) {
                                            let count = MovieInfo.seasons.length;
                                            count = count > 4 ? 4 : count;
                                            for (let i = 0; i < count; i++) {
                                                let item = MovieInfo.render_season_item(MovieInfo.data.seasons[i], i);
                                                append(parent, item)
                                            }
                                        }
                                    }
                                },
                                {
                                    attr: {
                                        class: 'similare-title',
                                    },
                                    innerHTML: similare_title
                                },
                                {
                                    attr: {
                                        class: 'similare-items-block',
                                        id: 'similare_items_block'
                                    },
                                    render: (parent) => {

                                        let count = MovieInfo.similares.length;

                                        count = count > 5 ? 5 : count;

                                        for (let i = 0; i < count; i++) {

                                            let _similare = MovieInfo.similares[i]

                                            let item = MovieInfo.render_episode_similare_item(_similare, i);
                                            append(parent, item)

                                        }
                                    }
                                },

                            ],

                        },
                        {
                            attr: {
                                class: 'similare-colored-btns'
                            },
                            render: (parent) => {
                                MovieInfo.colored_btns.forEach(item => {
                                    if (item.name == 'favorites') {
                                        append(parent, el({
                                            attr: {
                                                class: `info-page-colored-btn`,
                                                onclick: function () {
                                                    keydown({ keyName: 'yellow' })
                                                }
                                            },
                                            children: [
                                                {
                                                    attr: {
                                                        class: `info-page-colored-btn-icon  ${item.color}`
                                                    }
                                                },
                                                {
                                                    attr: {
                                                        class: 'info-page-colored-btn-text'
                                                    },
                                                    innerHTML: get_word(item.name)
                                                }
                                            ]
                                        }))
                                    }

                                })
                            }
                        }
                    ]
                }
            ]
        })

        document.body.appendChild(movie_info);
    }
    static render_season_item(data, index) {
        let selected = MovieInfo.selected_season == data ? 'selected' : '';
        return el({
            attr: {
                class: `similare-items-seasons-item seasons-item-control ${selected}`,
                index: index,
                onclick: MovieInfo.change_season,
                onmouseenter: MovieInfo.season_mouse_enter,
            },
            innerHTML: `${get_word('season')} ${data}`
        })
    }
    static re_render_season_item({ index, direction }) {
        let item = MovieInfo.render_season_item(MovieInfo.seasons[index], index);

        let parent = get_el('.similare-items-seasons-block');

        if (direction == 'left') {
            parent.insertBefore(item, parent.firstChild);
            parent.lastChild.remove();
        } else {
            parent.appendChild(item);
            parent.firstChild.remove();
        }
    }
    static render_episode_similare_item(item, index) {
        item = MovieInfo.parse_similare_episode_item(item);

        let className = MovieInfo.get_item_class(item);

        return el({
            attr: {
                class: `similare-episode-item similare-episode-control`,
                index: index,
                stream_id: item.id,
                extention: item.container_extension || '',
                onclick: MovieInfo.similare_episode_item_click,
                onmouseenter: MovieInfo.similare_episode_item_mouse_enter,
            },
            children: [
                {
                    attr: {
                        class: 'similare-episode-item-poster'
                    },
                    children: [
                        {
                            tagName: 'img',
                            attr: {
                                class: 'similare-episode-item-play-icon',
                                src: require('../Components/images/play_item.png')
                            }
                        },
                        {
                            attr: {
                                class: 'similare-episode-item-cover opacity-0',
                                id: item.id,
                            },
                            render: (parent) => {

                                setTimeout(async () => {
                                    let img = await ImageResizer({ src: item.cover, width: 137, height: 141, id: item.id })
                                    if (img)
                                        parent.appendChild(img)

                                    parent.classList.remove('opacity-0')
                                }, 500)
                            }
                        }
                    ]
                }
                ,
                {
                    attr: {
                        class: 'similiar-episode-item-info-block',
                    },
                    children: [
                        {
                            attr: {
                                class: `similare-episode-info-title-icons-block `
                            },
                            children: [
                                {
                                    attr: {
                                        class: 'similare-episode-item-title'
                                    },
                                    innerHTML: MovieInfo.is_series ? `${index + 1}. ${item.name}` : item.name
                                },
                                {
                                    attr: {
                                        class: `similare-episode-item-icons-block similare_episode_item-${item.id}  ${className}`
                                    },
                                    render: (parent) => {

                                        ['watched', 'lock', 'favorite'].forEach(item => {

                                            append(parent, el({

                                                tagName: 'img',

                                                attr: {

                                                    class: `similare-episode-item-icon ${item} `,

                                                    src: require(`../Components/images/${item}.png`)

                                                }

                                            }))

                                        })
                                    }
                                }
                            ]
                        },
                        {
                            attr: {
                                class: 'similare-episode-item-description'
                            },
                            innerHTML: item.description,
                        }

                    ]
                }
            ]
        })
    }

    static get_item_class(data) {

        let is_favorite = ''
        let is_locked = ''
        let is_watched = '';
        if (MovieInfo.is_series) {
            data;

            is_watched = ContinueWatch.is_watched(MovieInfo.data.id, 'series');
            if (is_watched) {
                is_watched = is_watched[`${data.season}-${data.episode_num}`] ? 'watched' : '';
            }
        } else {
            is_favorite = Favorite.is_favorite(data.id, 'movies') >= 0 ? 'favorite' : '';
            is_locked = LockCategories.is_locked(data.category_id, 'movies') ? 'locked' : '';
            is_watched = ContinueWatch.is_watched(data.id, 'movies') ? 'watched' : '';
        }




        return `${is_favorite} ${is_locked} ${is_watched}`

    }
    static parse_similare_episode_item(item) {


        let id;
        let type;
        if (MovieInfo.is_series) {
            id = MovieInfo.data.id;
            type = 'series';
        } else {
            id = item.id;
            type = 'movies';
        }


        let is_watched = ContinueWatch.is_watched(id, type);


        if (!MovieInfo.is_series) return {
            ...item,
            is_watched,
        };

        return {
            name: item.title,
            description: item.info.plot || item.info.overview,
            cover: item.info.movie_image || MovieInfo.data.cover,
            rating: item.info.rating,
            releaseDate: item.info.releasedate,
            container_extension: item.container_extension,
            episode_num: item.episode_num,
            season: item.season,
            added: item.added,
            id: item.id,
            is_watched: is_watched && is_watched[`${item.info.season}-${item.episode_num}`]
        }



    }
    static re_render_similare_episode({ index, direction }) {
        let item = MovieInfo.render_episode_similare_item(MovieInfo.similares[index], index);

        let parent = get_el('#similare_items_block');
        if (direction == 'up') {
            parent.insertBefore(item, parent.firstChild);
            parent.lastChild.remove();
        } else {
            parent.appendChild(item);
            parent.firstChild.remove();
        }
    }

    static similare_episode_item_click() {


        if (MovieInfo.is_series) {

            MovieInfo.play_movie(MovieInfo.similares[this.getAttribute('index')]);

        } else {

            let cat_id = MovieInfo.similares[this.getAttribute('index')].category_id;

            let is_locked = LockCategories.is_locked(cat_id, 'movies');

            if (is_locked) {

                ChangePage('ParentalCode', {

                    callback: () => {

                        MovieInfo.change_movie(this);
                    }

                })

            } else {

                MovieInfo.change_movie(this);

            }

        }
    }

    static similare_episode_item_mouse_enter() {

        let index = get_index(this);


        Control.items.index = index;

        Control.set_current('items')
    }

    static change_season() {


        remove_class('selected', get_el('.similare-items-seasons-block'))

        this.addClass('selected');

        let season = MovieInfo.seasons[this.getAttribute('index')];

        if (season == MovieInfo.selected_season) return;

        MovieInfo.selected_season = season;


        MovieInfo.similares = MovieInfo.data.episodes[MovieInfo.selected_season]

        let parent = get_el('#similare_items_block');

        parent.innerHTML = ''

        let count = MovieInfo.similares.length;

        count = count > 5 ? 5 : count;

        for (let i = 0; i < count; i++) {

            let _similare = MovieInfo.similares[i]

            let item = MovieInfo.render_episode_similare_item(_similare, i);

            append(parent, item)
        }

    }

    static season_mouse_enter() {

        let index = get_index(this);

        Control.seasons.index = index;

        Control.set_current('seasons')
    }

    static change_movie(item) {

        Control.set_current('on_hold');
        item.addClass('loading')
        let stream_id = item.getAttribute('stream_id');

        xtream_instance.get_movie_info(stream_id, MovieInfo.is_series).then((res) => {

            item.removeClass('loading');

            // ChangePage('MovieInfo', { data: res, similares: MovieInfo.episodes_similares, is_series: MovieInfo.is_series })
            const movie_info = new MovieInfo({ data: res, similares: MovieInfo.episodes_similares, is_series: MovieInfo.is_series });
            MovieInfo.destroy();
            movie_info.init();
            move()

        }).catch(err => {
            Control.set_previous();
            item.removeClass('loading');
            console.log('get info error --->', err);
        })


    }
    static click_info_btn() {
        let type = this.getAttribute('type');

        if (type == 'back') {

            ChangePage(pages.activePage, {}, true, false)

        } else {

            let data = MovieInfo.data;

            if (MovieInfo.is_series) {

                data = MovieInfo.similares[0]

                let is_watched = ContinueWatch.is_watched(MovieInfo.data.id, 'series');

                if (is_watched) {

                    let key = is_watched.last_key.split('-')[1];

                    data = MovieInfo.similares[+key - 1]
                }

            }

            MovieInfo.play_movie(data);
        }
    }

    static mouse_enter_info_btn(index) {

        Control.info_btn.index = index,

            Control.set_current('info_btn')

    }

    static play_movie(data) {


        let player_parent = el_tag('div', 'movie-player-parent', 'player_parent');

        ChangeControl('MovieInfo');

        let type = MovieInfo.is_series ? 'series' : 'movie';

        let is_watched;
        if (type == 'series') {

            is_watched = ContinueWatch.is_watched(MovieInfo.data.id, 'series');
            if (is_watched) {
                is_watched = is_watched[`${data.season}-${data.episode_num}`]
            }

        } else {
            is_watched = ContinueWatch.is_watched(data.id, 'movies');
        }

        if (is_watched) {

            MovieInfo.show_continue_watching_popup(is_watched, (time) => {

                if (!get_el('#player_parent'))
                    append(document.body, player_parent)

                ChangePage('Player', { data, type, current_time: time })
            })

            Control.popup.index = 0;
            Control.set_current('popup');


        } else {

            if (!get_el('#player_parent'))
                append(document.body, player_parent)

            ChangePage('Player', { data, type, current_time: 0 })

        }



    }

    static show_continue_watching_popup({ current_time }, callback) {

        const continu_watching_popup = new PopUp({

            message: get_word('do you want to continue'),

            callback: continue_watching_popup_click,

            current_time: current_time

        })

        continu_watching_popup.init();

        function continue_watching_popup_click() {

            MovieInfo.close_popup();

            callback(parseInt(this.getAttribute('current_time')));

        }


    }
    static close_popup() {


        Control.set_previous();

        let item = get_el('#popup_parent')

        if (item)
            item.remove();


    }
    static add_remove_favorite() {
        let id = MovieInfo.data.id;

        let type = MovieInfo.is_series ? 'series' : 'movies';


        let is_favorite = Favorite.is_favorite(id, type) >= 0;

        get_el('#movie_info_icons_block').classList.toggle('favorite')
        if (is_favorite) {
            Favorite.remove(id, type)
        } else {
            Favorite.add(id, type)
        }
    }
    static destroy() {
        get_el('#movie_info_parent').remove();
        MovieInfo.similares = [];
        MovieInfo.selected_season = 0;
        MovieInfo.is_series = false;

        // destroy control
        Control.current = 'info_btn';
        Control.info_btn.index = 0;
        Control.items.index = 0;
        Control.seasons.index = 0;

    }
    static show_colored_btns() {
        get_el('.similare-colored-btns').removeClass('d-none')
    }
    static hide_colored_btns() {
        get_el('.similare-colored-btns').addClass('d-none')
    }
    static on_hold_back() {
        xtream_instance.abort();
    }
}