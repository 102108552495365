import { controller } from "../../Utils/Xtream"


export default class Tmdb {
    static api_host = "https://api.themoviedb.org/3"

    static READ_ACCESS_TOKEN = "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIzMDg4OGU4ZjI3MWYxNjk4ZGNmYjAyMjhmNTg5YmVjMSIsInN1YiI6IjVkYzNjNGFlOTkyNTljMDAxMzdhZTY4OCIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.nZn6l08oa9ZXTI4HdCWdyrK5W57yUZB6wqExBRWJnaU"
    static API_KEY = "30888e8f271f1698dcfb0228f589bec1"
    static language = "en-US"
    static language_iso = localStorage.getItem('tmdb_language') || "en"
    static image_paths = {
        original: "https://image.tmdb.org/t/p/original",
        w500: "https://image.tmdb.org/t/p/w500",
        w200: "https://image.tmdb.org/t/p/w200"
    }
    static PATHS = {
        searchMovies: "/search/movie",
        searchTvs: "/search/tv",
        moveInfo: "/movie/",
        person: "/person/",
        multiple: "/search/multi",
        tv_info: "/tv/",
        castSearch: "/search/person",
        tv_season: function (tvId, seasonNumber) {
            return "/tv/" + tvId + "/season/" + seasonNumber;
        },
        tv_season_credits: function (tvId, season_number) {
            return "/tv/" + tvId + "/season/" + season_number + "/credits"
        },
        episode_details: function (tvId, seasonNumber, episodeNumber) {
            return "/tv/" + tvId + "/season/" + seasonNumber + "/episode/" + episodeNumber
        },
        getMovieImages: function (movieId) {
            return "/movie/" + movieId + "/images"
        }
    }

    static search({ name, is_series }, cb, error) {

        if (!name) return;

        name = name.split("|").pop().trim();

        name = name.toLowerCase();

        name = name
            .replace("[", "")
            .replace("]", "")
            .replace("(", "")
            .replace(")", "");

        let name_arr = name.split(" ");

        if (name_arr[0][name_arr[0].length - 1] == ':') name_arr.splice(0, 1);
        name = [];

        let year = null;

        for (let i = 0; i < name_arr.length; i++) {
            let str = name_arr[i];

            if (str.length == 4 && parseInt(str) > 1000) {
                year = parseInt(str);
            } else if (!year) {
                name.push(str);
            }
        }

        name = name.join(" ");

        if (is_series) {
            Tmdb.search_series({ name, year }, cb, error);
        } else {
            Tmdb.search_movie({ name, year }, cb, error);
        }
    }


    static search_series({ name, year }, cb, error) {
        Tmdb.search_series_by_name({ name, year }, function (data) {

            let index = Tmdb.get_tmdb_language_index(data);
            if (data.length) {
                Tmdb.get_series_info(data[index].id, (movie) => {
                    if (cb)
                        cb(movie[0])
                });
            } else {
                cb();
            }
        }, error);
    }
    static search_series_by_name({ name, year }, cb, error) {
        var query = { query: name, language: Tmdb.language };

        if (year) query.first_air_date_year = year;

        Tmdb.request(Tmdb.PATHS.searchTvs, query, function (data) {
            data = Tmdb.connect_with_images(data.results, ["poster_path", "backdrop_path"]);
            cb(data);
        }, error);
    }
    static get_series_info(id, cb, error = () => { }) {
        Tmdb.request(Tmdb.PATHS.tv_info + id, { language: Tmdb.language, append_to_response: "videos,images,credits,translations" }, function (data) {
            data = Tmdb.connect_with_images([data], ["poster_path", "backdrop_path"]);
            cb(data);
        }, error);
    }
    static get_episode_info(id, season_num, episode_num, cb, error = () => { }) {
        Tmdb.request(Tmdb.PATHS.episode_details(id, season_num, episode_num), { language: Tmdb.language, append_to_response: "translations" }, function (data) {
            data = Tmdb.connect_with_images([data], ["still_path"]);
            cb(data[0]);
        }, error)
    }


    static search_movie({ name, year }, cb, error = () => { }) {
        Tmdb.search_movie_by_name({ name, year }, function (data) {

            let index = Tmdb.get_tmdb_language_index(data);
            if (data.length) {
                Tmdb.get_movie_info(data[index].id, (movie) => {
                    if (cb)
                        cb(movie[0])
                });
            } else {
                cb();
            }
        }, error);
    }
    static search_movie_by_name({ name, year }, cb, error = () => { }) {
        let query = { query: name, language: Tmdb.LANGUAGE };

        if (year) query.year = year;

        Tmdb.request(Tmdb.PATHS.searchMovies, query, function (data) {
            data = Tmdb.connect_with_images(data.results, ["poster_path", "backdrop_path"]);
            cb(data);
        }, error);
    }
    static get_movie_info(id, cb, error = () => { }) {
        Tmdb.request(Tmdb.PATHS.moveInfo + id, { language: Tmdb.LANGUAGE, append_to_response: "videos,images,credits,translations" }, function (data) {
            data = Tmdb.connect_with_images([data], ["poster_path", "backdrop_path"]);
            cb(data);
        }, error);
    }

    static get_tmdb_language_index(data) {
        for (let i = 0; i < data.length; i++) {
            if (data[i].original_language == Tmdb.language_iso) {
                return i;
            }
        }
        return 0;
    }

    static connect_with_images(items, keys) {

        for (let i = 0; i < items.length; i++) {
            for (let j = 0; j < keys.length; j++) {
                if (items[i][keys[j]]) {
                    let path = items[i][keys[j]];
                    items[i][keys[j]] = {
                        original: Tmdb.image_paths.original + path,
                        w500: Tmdb.image_paths.w500 + path,
                        w200: Tmdb.image_paths.w200 + path
                    }
                }
            }
        }
        return items;
    }



    static create_query(path, params) {
        let params_string = "";

        if (params) {
            for (let key in params) {
                if (params_string !== "") {
                    params_string += "&";
                }
                params_string += key + "=" + encodeURIComponent(params[key]);
            }

            params_string = `?api_key=${Tmdb.API_KEY}&${params_string}`;
        }

        return `${Tmdb.api_host}${path}${params_string}`;
    }

    static request(path, params, success, err) {
        let url = Tmdb.create_query(path, params);

        let req = new XMLHttpRequest();

        req.timeout = 30000;

        req.onreadystatechange = function () {

            if (this.readyState == 4 && this.status == 200) {
                success(JSON.parse(this.responseText));
            }

        }
        req.onerror = function (e) {
            if (err) err("incorrect playlist url");
        }
        req.ontimeout = function () {
            if (err) err("timeout");
        }
        req.open("get", url);
        req.send();

        controller.push(req);
    }

}