import { append, el, get_el, get_els, get_exp_date } from "../Utils/Utils";
import get_word from "../Utils/Words";
import { content } from "../Utils/Content";
import MovieItem from "../Components/MovieItem/MovieItem";
import './Menu.scss';
import { ChangePage, Control } from "../Remote/Pages";
import { xtream_instance } from "..";
import { OS } from "../Utils/Os";
import LockCategories from "../LockCategories/LockCategories";
import AppConfig from "../AppConfig";

class Menu {
    static menu_items = [
        {
            name: 'live tv',
            type: 'Live',
            icon: 'live.png',
            id: 0,
        },
        {
            name: 'movies',
            type: 'Movies',
            icon: 'movies.png',
            id: 1,
        },
        {
            name: 'series',
            type: 'Series',
            icon: 'series.png',
            id: 2,
        },
        {
            name: 'settings',
            type: 'Settings',
            icon: 'settings.png',
            id: 3,
        }
    ]
    static root = document.getElementById('root')
    static latest_movies_count = 6;
    static selected_groups_count = 14;
    init() {

        this.render();
    }
    render() {




        const menu_page = el({
            attr: {
                class: 'menu-page-parent',
                id: 'menu_page_parent'
            },
            children: [
                {
                    attr: {
                        class: 'menu-items-block',
                    },
                    children: [
                        {
                            attr: {
                                class: 'menu-logo',
                                id: 'menu_logo'
                            },
                            children: [
                                {
                                    tagName: 'img',
                                    attr: {
                                        src: require('./images/logo.png'),
                                        alt: 'logo',
                                    }
                                }
                            ]
                        },
                        {
                            attr: {
                                class: 'menu-items-container',
                                id: 'menu_items_container'
                            },
                            render: (parent) => {
                                Menu.menu_items.forEach((item, index) => {
                                    let element = el({
                                        attr: {
                                            class: 'menu-item menu-item-control',
                                            type: item.type,
                                            index: index,
                                            onclick: Menu.menu_item_click,
                                            onmouseenter: function () {
                                                Menu.menu_item_mouse_enter(this.getAttribute('index'))
                                            },
                                        },
                                        children: [
                                            {
                                                tagName: 'img',
                                                attr: {
                                                    class: 'menu-item-icon',
                                                    src: require(`./images/${item.icon}`),
                                                }
                                            },
                                            {
                                                attr: {
                                                    class: 'menu-item-name',
                                                },
                                                innerHTML: get_word(item.name)
                                            }
                                        ]
                                    })

                                    append(parent, element)
                                })
                            }

                        },
                        {
                            attr: {
                                class: 'menu-footer'
                            },
                            children: [
                                {
                                    attr: {
                                        class: `expired-date ${AppConfig.selected_server.id == 'demo' ? 'd-none' : ''}`,
                                        id: 'expired_date'
                                    },
                                    children: [
                                        {
                                            attr: {
                                                class: 'expired-date-title'
                                            },
                                            innerHTML: `${get_word('expired date')}: `
                                        },
                                        {
                                            attr: {
                                                class: 'expired-date-value'
                                            },
                                            innerHTML: get_exp_date(+ AppConfig.selected_server_info.user_info.exp_date * 1000)
                                        }
                                    ]

                                }
                            ]
                        }
                    ]
                },
                {
                    attr: {
                        class: 'menu-right-block',
                    },
                    children: [
                        {
                            attr: {
                                class: 'latest-vod-block',
                                id: 'latest_vod_block'
                            },
                            render: (parent) => {
                                ['movies', 'series'].forEach((type, rowIndex) => {
                                    let item = el({
                                        attr: {
                                            class: `latest-${type}-block`,
                                        },
                                        children: [
                                            {
                                                attr: {
                                                    class: `latest-vod-title`,
                                                },
                                                innerHTML: get_word(`latest ${type}`),

                                            },
                                            {
                                                attr: {
                                                    class: `latest-vod-container latest-vod-container-control`,
                                                    id: `latest_${type}_container`
                                                },
                                                render: (parent) => {

                                                    let data = [];

                                                    try{
                                                        data =  content[type].data['all'].items;
                                                    }catch(e){
                                                        console.log('error --->',e);
                                                    }

                                                    if(!data.length){
                                                        append(parent, el({
                                                            attr:{
                                                                class: 'no-data',
                                                            },
                                                            innerHTML: get_word(`no ${type} data`)
                                                        }))

                                                        return
                                                    }
                                                    for (let i = 0; i < Menu.latest_movies_count; i++) {

                                                        if (!data[i]) break;

                                                        let item = new MovieItem({
                                                            data: data[i],
                                                            index: `${rowIndex}_${i}`,
                                                            className: 'menu',
                                                            is_series: type == 'series',
                                                            callback: Menu.click_vod_item,
                                                            mouseenter: Menu.vod_item_mouse_enter
                                                        });

                                                        append(parent, item.init())
                                                    }

                                                }
                                            }

                                        ]

                                    })

                                    append(parent, item)
                                })
                            }
                        },
                        {
                            attr: {
                                class: 'selected-groups-block',
                                id: 'selected_groups_block',
                            },
                            children: [
                                {
                                    attr: {
                                        class: `latest-group-title ${!content.groups?.length ? 'hide' : ''} `,
                                    },
                                    innerHTML: get_word(`groups`),

                                },
                                {
                                    attr: {
                                        class: `selected-group-container`,
                                        id: `selected_group_container`
                                    },
                                    render: (parent) => {
                                        let data = content.groups;

                                        if (data) {
                                            let count = data.length > Menu.selected_groups_count ? Menu.selected_groups_count : data.length;

                                            for (let i = 0; i < count; i++) {

                                                let item = Menu.create_group_item(data[i], i);

                                                append(parent, item)

                                            }
                                        }

                                    }
                                }
                            ]

                        }
                    ]
                }
            ]
        })

        append(Menu.root, menu_page)
    }

    static create_group_item(item, index) {
        return el({
            tagName: 'img',
            attr: {
                class: 'selected-group-item group-items-control',
                type: item.type,
                index: index,
                cat_id: item.category_id,
                cat_name: item.category_name,
                src: item.category_image,
                onclick: Menu.group_item_click,
                onmouseenter: function () {

                    Menu.group_item_mouse_enter(this.getAttribute('index'))

                },
                onerror: function () {
                    this.src = require('./images/logo.png')
                }
            },
        })
    }
    static re_render_group_item({ index, direction }) {


        let item = Menu.create_group_item(content.groups[index], index);


        let parent = get_el(`#selected_group_container`)

        if (direction == 'left') {
            parent.insertBefore(item, parent.firstChild)
            parent.lastChild.remove();
        } else {
            append(parent, item)
            parent.firstChild.remove();
        }


    }

    static re_render_movie_item({ type, index, direction }) {
        let data = content[type].data['all'].items;

        let itemIndex = index.split('_')[1];

        let item = new MovieItem({

            data: data[itemIndex],

            index: index,

            className: 'menu',

            is_series: type == 'series',

            callback: Menu.click_vod_item,

            mouseenter: Menu.vod_item_mouse_enter

        });

        let removed_item;

        let parent = get_el(`#latest_${type}_container`)

        if (direction == 'left') {

            parent.insertBefore(item.init(), parent.firstChild)

            removed_item = parent.lastChild;

        } else {

            append(parent, item.init())

            removed_item = parent.firstChild;

        }

        removed_item.remove();


    }
    static click_vod_item(item) {

        Control.set_current('on_hold');

        const stream_id = item.getAttribute('stream_id');
        let type = item.getAttribute('type')
        let is_series = type == 'series';

        xtream_instance.get_movie_info(stream_id, is_series).then((res) => {
            Control.set_previous();
            Menu.show_movie_info(res, type);
        }).catch(err => {
            Control.set_previous();
            console.log('get info error --->', err);
        })
    }

    static vod_item_mouse_enter({ rowIndex, index }) {


        Control.items.index = index;

        Control.items.row = rowIndex;

        Control.set_current('items')
    }


    static show_movie_info(data, type) {

        let similares;

        let is_series = type == 'series'

        if (is_series) {

            similares = data.episodes[data.seasons[0]];

        } else {

            type = 'movies';

            similares = content[type].data['all'].items;

        }

        ChangePage('MovieInfo', { data: data, similares: similares, is_series: is_series })
    }

    static menu_item_click() {
        let type = this.getAttribute('type');

        let data = content[type.toLowerCase()];
        let is_series = type == 'Series';

        if (is_series) type = 'Movies'
        ChangePage(type, { data: data, is_series: is_series }, true)
    }

    static menu_item_mouse_enter(index = 0) {

        Control.Menu.index = index

        Control.set_current('Menu')

    }


    static group_item_click() {
        let type = this.getAttribute('type');
        let cat_name = this.getAttribute('cat_name');

        let data = content[type.toLowerCase()];
        let is_series = type == 'Series';

        if (is_series) type = 'Movies'


        ChangePage(type, { data: data, is_series: is_series, cat_name: cat_name }, true);
    }

    static group_item_mouse_enter(index) {

        Control.groups.index = index;

        Control.set_current('groups')
    }

    static on_hold_back() {

        Control.set_previous();
        xtream_instance.abort();

        let items = get_els('.loading')

        for (let i = 0; i < items.length; i++) {
            items[i].removeClass('loading')
        }
    }

}


export default Menu