import Favorite from "../Favorite/Favorite";
import Live from "../../Live/Live";
import LockCategories from "../../LockCategories/LockCategories";
import pages, { ChangePage, Control, ChangeControl } from "../../Remote/Pages";
import { el, get_el, remove_class } from "../../Utils/Utils";
import './LiveItem.scss'

import ImageResizer from "../ResizeImage/ImageResizer";
import Move from "@/src/Move/Move";
import MoveChannels from "@/src/MoveChannels/MoveChannels";
import Search from "@/src/Search/Search";

export default class LiveItem {
    constructor (data, index = 0) {
        this.data = data;
        this.index = index;
        this.selected = false
        this.is_favorite = false;
        this.is_locked = false;
        this.text_scroll = '';
    }

    init() {

        if (Live.selected_channel) {
            this.selected = Live.selected_channel.stream_id == this.data.stream_id;
        }

        this.is_favorite = Favorite.is_favorite(this.data.stream_id, 'live') >= 0;
        this.is_locked = LockCategories.is_locked(this.data.category_id, 'live');
        this.is_locked = this.is_locked && this.is_locked != Live.selected_category;

        this.text_scroll = this.data.name.length > 14 ? 'scroll-text' : '';

        return this.render();
    }
    render() {

        const that = this;
        const category_item = el({
            attr: {
                class: `live-item live-item-control ${this.selected ? 'selected' : ''}${this.is_favorite ? ' favorite' : ''}${this.is_locked ? ' locked' : ''} `,

                id: `live_item-${this.data.stream_id}`,
                stream_id: this.data.stream_id,
                index: this.index,
                onclick: function (type) {

                    that.item_click(this)
                },
                onmouseenter: this.mouseEnter
            },
            children: [
                {
                    attr: {
                        class: 'live-item-number',
                    },
                    innerHTML: this.index + 1
                },
                {
                    attr: {
                        class: 'live-item-icon opacity-0',
                        id: this.data.stream_id,
                    },
                    render: (parent) => {

                        setTimeout(async () => {
                            let icon = await ImageResizer({ src: this.data.stream_icon, width: 55, height: 55, type: 'image/png', id: this.data.stream_id })

                            if (icon)
                                parent.appendChild(icon)
                            parent.classList.remove('opacity-0')
                        }, 500)
                    }

                },

                {
                    attr: {
                        class: 'live-item-name-container',
                    },
                    children: [
                        {
                            attr: {
                                class: `live-item-name ${this.text_scroll}`,
                            },
                            innerHTML: this.data.name
                        }
                    ]
                },
                {
                    attr: {
                        class: 'live-item-status',
                    },
                    render: (parent) => {
                        ['favorite', 'lock'].forEach((item) => {
                            let icon = el({
                                tagName: 'img',
                                attr: {
                                    class: `live-item-status-icon live-item-status-icon-${item}`,
                                    src: require(`../images/${item}.png`),
                                }
                            })

                            parent.appendChild(icon)
                        })
                    }
                }
            ]
        })

        return category_item;
    }
    item_click(item) {

        if (Move.move_mode || MoveChannels.move_mode) return

        let selected_channel_id = Live.selected_channel && Live.selected_channel.id
        if (this.is_locked && selected_channel_id != this.data.id) {
            const that = this;
            ChangePage('ParentalCode', {
                callback: () => {
                    that.change_channel(item)
                }
            })
        } else {
            this.change_channel(item)
        }

    }
    change_channel(that) {


        // close search when it opened
        if (pages.current == 'Search') {

            ChangePage(pages.activePage, {}, true, false)

        }

        remove_class('selected', get_el('#live_channels_content'))

        Live.get_selected_channel(that.getAttribute('index'));

        that.addClass('selected')

        if (Live.full_screen_channels_mode)
            Live.hide_channels_in_full_screen()


    }

    mouseEnter() {

        if (Move.move_mode || MoveChannels.move_mode) return

        let parent = this.parentElement;

        let index = 0;

        for (let i = 0; i < parent.children.length; i++) {

            if (parent.children[i] == this) {

                index = i;

                break;

            }
        }

        ChangeControl(pages.activePage)

        Control.channels.index = index;

        Control.set_current('channels')

    }
}