import { xtream_instance } from ".."
import { favoriteData } from "../Components/Favorite/Favorite";
import { recently_watched } from "../Components/RecentlyWatched/RecentlyWatched";
import LockCategories from "../LockCategories/LockCategories";
import Requests from "./Requests"

//Selected playlist content
let content = {};
let ex_data = {};


async function get_selected_playlist_content() {


    return new Promise((resolve, reject) => {


        let count = 0;

 
        try {

            ['live', 'movies', 'series'].forEach(type => {
                    
                    ex_data[type] = {
                        categories: [],
                        data: {}
                    }

                    switch (type) {
                        case 'live':
                            xtream_instance.get_live_data().then(data => {
                                
                                ex_data.live = data
                                count++;
                                done();
                            }) .catch(e => {
                                console.log('error', e)
                              reject(false);
                            })   
                            break;

                        case 'movies':
                            xtream_instance.get_movies_data().then(data => {
                                
                                ex_data.movies = data
                                count++;
                                done();
                            }).catch(e => {
                                console.log('error', e)
                                reject(false);
                            })   
                            break;
                        case 'series':
                            xtream_instance.get_series_data().then(data => {
                                
                                ex_data.series = data
                                count++;
                                done();
                            }).catch(e => {
                                console.log('error', e)
                                reject(false);
                            })   
                            break;
                    }


            })

        } catch (e) {

        
            
            if (e == 'aborted') return reject(false);


        }

        function done(){

            if(count < 3) return;

            try{


            ['live', 'movies', 'series'].forEach(type => {

                let categories = ex_data[type].data;

                for (let key in categories) {

                    let cat = categories[key];


                    if (['search', 'all', 'favorites', 'recently watched', 'continue watched'].indexOf(cat.category_name.toLowerCase()) != -1) continue;

                    cat.category_image = ex_data.all_cats?.[cat.category_name] || '';

                }
            })


                delete ex_data.all_cats

                content = { ...ex_data }


                restore_old_local_data();


                LockCategories.check_hided_data();

                resolve(true)

            }catch(e){

                reject("some error with parse playlist data")
            } 
        }
    })
  
}

function getDemoPlaylist() {


    return [{
        createdAt: "2023-10-12T13:17:05.000Z",
        device_id: 36,
        id: 'demo',
        is_selected: false,
        password: "4",
        reseller_id: null,
        server: {
            id: 22,
            name: 'playlist',
            host: 'http://79.143.180.88:25461',
            username: '4',
            password: '4'
        },
        server_id: 22,
        updatedAt: "2023-10-13T11:58:17.000Z",
        username: "4"
    }]
}


async function get_categories(id) {

    ex_data = {};

    const _cats = new Promise((resolve, reject) => {

        Requests.get({

            url: Requests.categories_url(id)

        }, (data) => {

            resolve(data);

        }, (err) => {

            console.log('error', err);

            reject(err)

        })

    })

    try {

        let cats_object = await _cats;

        let cat_icons = {};

        let selected_categories = [];

        ['live', 'movie', 'series'].forEach(cat => {

            cats_object[cat].forEach(element => {

                if (element.is_selected) {

                    let type = cat == 'movie' ? 'Movies' : cat.charAt(0).toUpperCase() + cat.slice(1);

                    element.type = type

                    selected_categories.push(element)

                }

                cat_icons[element.category_name] = element.category_image

            })


        })


        ex_data.groups = selected_categories

        ex_data.all_cats = cat_icons;

        return true;

    } catch (e) {

        if (e == 'aborted') return false;

    }
}

function restore_old_local_data() {

    favoriteData.live = null;

    favoriteData.movies = null;

    favoriteData.series = null;

    recently_watched.live = null;
}

window.content = content
export { get_selected_playlist_content, getDemoPlaylist, get_categories, content }