import AppConfig from "../AppConfig";
import Exit from "../Exit/Exit";
import { el } from "../Utils/Utils";
import get_word from "../Utils/Words";
import './Info.scss'


export default class Info {

    constructor(data){

        this.message = data.message || "";
    }

    init() {


        this.render();
    }


    render() {

        const info_page = el({

            attr: {
                class: 'info-page',
                id: 'info_page'
            },
            children: [
                {
                    attr: {
                        class: 'info-page-content'
                    },
                    children: [
                        {
                            tagName: 'img',

                            attr: {

                                class: 'info-page-icon',

                                src: require('../images/logo.png')

                            },

                        },
                        {
                            attr: {

                                class: 'info-page-title'

                            },

                            innerHTML: AppConfig.app_name,

                        },
                        {
                            attr: {

                                class: 'info-page-mac'

                            },

                            innerHTML: `${get_word('mac')}: ${localStorage.getItem('mac')}`

                        },
                        {
                            attr:{
                                class:'info-page-message'
                            },
                            innerHTML: this.message  
                        },
                        {
                            attr: {
                                class: 'buttons-container'
                            },
                            render: (parent) => {
                                ['exit', 'reload'].forEach((button) => {

                                    parent.appendChild(this.renderButton(button))

                                })
                            }
                        }
                    ]
                }
            ]
        })


        document.getElementById('root').appendChild(info_page);
    }

    renderButton(button) {

        return el({

            attr: {
                class: 'info-page-button info-page-button-control',
                id: button,
                onclick: () => { this.buttonClick(button) },
            },
            innerHTML: get_word(button)
        })
    }

    buttonClick(type) {

        if (type == 'reload') {
            window.location.reload();
            return
        }

        Exit.exit_app();

    }

}