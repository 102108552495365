import { remove_class } from "../Utils/Utils";
import { move } from "../Remote/Keys";
import pages, { ChangePage } from "../Remote/Pages";
import { MovieInfo } from "./MovieInfo";




const Control = {
    current: "info_btn",
    previous: "",
    set_current: function (current) {

        if (this.current != current)
            this.previous = this.current;

        this.current = current;
        move();
    },
    set_previous: function () {
        this.current = this.previous;
        move();
    },
    keydown: function (param) {
        if (param == 'back' && (this.current != 'on_hold' && this.current != 'popup')) {
            ChangePage(pages.activePage, {}, true, false)
            return true
        }
    },
    info_btn: {
        index: 0,
        items: document.getElementsByClassName('movie-info-btn-control'),
        yellow: function () {
            MovieInfo.add_remove_favorite();
        },
        left: function () {
            if (this.index == 0) return
            this.index--
            this.move();
        },
        right: function () {
            if (this.index == this.items.length - 1) {
                Control.set_current('items')
                return
            };

            this.index++;
            this.move();
        },
        ok: function () {
            this.items[this.index].click();
        },

        move: function () {
            remove_class();
            this.items[this.index].addClass('active');
            MovieInfo.show_colored_btns();
        },
    },
    items: {
        is_animation_started: false,
        index: 0,
        items: document.getElementsByClassName('similare-episode-control'),
        keydown: function () {
            if (this.is_animation_started) return true;
        },
        up: function () {
            let item_index = this.items[0].getAttribute('index');

            if (this.index == 0 && item_index != 0) {
                MovieInfo.re_render_similare_episode({
                    index: +item_index - 1,
                    direction: 'up'
                });
            } else if (this.index == 0) {
                if (Control.seasons.items.length > 0)
                    Control.set_current('seasons');
                return
            } else {
                this.index--;
            }
            this.move();
        },
        down: function () {
            let item_index = this.items[this.items.length - 1].getAttribute('index');
            if (this.index == this.items.length - 1 && item_index != MovieInfo.similares.length - 1) {
                MovieInfo.re_render_similare_episode({
                    index: +item_index + 1,
                    direction: 'down'
                });
            } else if (this.index == this.items.length - 1) {
                return
            } else {
                this.index++;
            }
            this.move();
        },
        left: function () {
            Control.set_current('info_btn')
        },
        ok: function () {
            this.items[this.index].click();
        },

        move: function () {
            remove_class();
            this.items[this.index].addClass('active');
            MovieInfo.hide_colored_btns();
            this.is_animation_started = true;

            setTimeout(() => {
                this.is_animation_started = false;
            }, 100)

        }
    },
    seasons: {
        index: 0,
        items: document.getElementsByClassName('seasons-item-control'),
        down: function () {
            Control.set_current('items')
        },
        left: function () {
            let item_index = this.items[0].getAttribute('index');
            if (this.index == 0 && item_index != 0) {
                MovieInfo.re_render_season_item({
                    index: +item_index - 1,
                    direction: 'left'
                })
            } else if (this.index == 0) {
                return
            } else {
                this.index--
            }

            this.move();
        },
        right: function () {
            let item_index = this.items[this.items.length - 1].getAttribute('index');

            if (this.index == this.items.length - 1 && item_index != MovieInfo.seasons.length - 1) {
                MovieInfo.re_render_season_item({
                    index: +item_index + 1,
                    direction: 'right'
                })
            } else if (this.index == this.items.length - 1) {
                return
            } else {
                this.index++;
            }

            this.move();
        },
        ok: function () {
            this.items[this.index].click();
        },
        move: function () {
            remove_class();
            this.items[this.index].addClass('active');
            MovieInfo.hide_colored_btns();
        }
    },
    popup: {
        index: 0,
        items: document.getElementsByClassName('popup-btn-control'),
        left: function () {
            if (this.index == 0) return
            this.index--
            this.move()
        },
        right: function () {
            if (this.index == this.items.length - 1) return
            this.index++
            this.move()
        },
        back: function () {
            MovieInfo.close_popup()
        },
        ok: function () {
            this.items[this.index].click()
        },
        move: function () {
            remove_class();
            this.items[this.index].addClass('active');
        }
    },
    on_hold: {
        back: function () {
            MovieInfo.on_hold_back();

        }
    }

}

export default Control;