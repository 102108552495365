import MoveItems from "./MoveChannels";



const Control = {
    current: 'items',
    previous: "",
    set_current: function (current) {

        if (this.current != current)
            this.previous = this.current;

        this.current = current;

    },
    set_previous: function () {
        this.current = this.previous;

    },
    items: {

        up: function () {
            MoveItems.move_up(-1)
        },
        down: function () {
            MoveItems.move_down(1)
        },
        ok: function () {
            MoveItems.close_move()
        },
        back: function () {
            MoveItems.close_move()
        },
        MoveItems: function () {
            // MoveItems
        }
    }
}



export default Control;