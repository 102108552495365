import { remove_class } from "../Utils/Utils";



const Control = {
    current: "exit",
    previous: "",
    set_current: function (current) {

        if (this.current != current)
            this.previous = this.current;

        this.current = current;

        move();
    },
    set_previous: function () {
        this.current = this.previous;
        move();
    },
    exit: {
        index: 0,
        items: document.getElementsByClassName('exit-item-control'),
        left: function () {
            this.index = 0;
            this.move();
        },
        right: function () {
            this.index = 1;
            this.move();
        },
        ok: function () {
            this.items[this.index].click();
        },
        back: function () {
            this.items[1].click();
        },
        move: function () {
            remove_class();
            this.items[this.index].addClass('active')
        }
    }
}

export default Control;