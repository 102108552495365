import AppConfig from '../AppConfig'
import { el, append, get_el } from '../Utils/Utils'
import get_word from '../Utils/Words'
import './SplashScreen.scss'
import spinnerSVG from "../images/spinner.svg"

export default class SplashScreen {

    static show() {

        const splash_screen = el({
            attr: {
                class: 'splash-screen',
                id: 'splash_screen',
                style: `background-image: url(${AppConfig.background})`
            },
            children: [
              
                {
                    tagName: 'img',
                    attr: {
                        class: 'splash-screen-logo',
                        src: require('../images/logo.png')
                    }
                },
              
                {
                    attr:{
                        class:'loading-content-view',
                        id:'loading_content_view'
                    },
                    render:(parent)=>{

                            ["live_categories","live_streams","vod_categories","vod_streams","series_categories","series"].forEach(item =>{

                                let text = this.getText(item)
                                parent.appendChild(el({                  
                                    attr:{
                                        class:'loading-content-item',
                                        
                                    },
                                    children:[
                                        {
                                            attr:{
                                                class:"loading-content-item-text"
                                            },
                                            innerHTML:text
                                        },
                                        {
                                            attr:{
                                                class:"loading-content-item-percent",
                                                id:`percent_${item}`
                                            },
                                            innerHTML:'0'
                                        },
                                        {
                                            attr:{
                                                class:"loading-content-item-spinner",
                                                id:`spinner_${item}`,
                                                style:`background-image: url(${spinnerSVG})`
                                            }
                                        }
                                    ]
                                }))
                            })

                    },
                },
                {
                    tagName: 'div',
                    attr: {
                        class: 'splash-screen-version',
                    },
                    innerHTML: `${get_word('app version')}: ${AppConfig.app_version}`

                }
            ]
        })


        append(document.body, splash_screen)


    }

    static getText(item){

        switch(item){
            case "live_categories":
                return get_word('loading live categories')
            case "live_streams":
                return get_word('loading live streams')
            case "vod_categories":
                return get_word('loading movie categories')
            case "vod_streams":
                return get_word('loading movies')
            case "series_categories":
                return get_word('loading series categories')
            case "series":
               return get_word('loading series')    
                
        }
    }

    static hide() {
        let parent = get_el('#splash_screen')

        if (parent) parent.remove();
    }
}