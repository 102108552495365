import AppConfig from "../AppConfig";
import { controller } from "./Xtream";


const { api } = AppConfig;

class Requests {
    static login_url() {
        // post  request 
        return `${api}/login`
    }
    static profile() {
        return `${api}/profile `
    }
    static categories_url(id) {
        return `${api}/categories?id=${id}`
    }


    static get({ url }, suceess, error) {


        let xhr = new XMLHttpRequest();

        xhr.open('GET', url);

        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('token'));

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    try {
                        suceess(JSON.parse(xhr.responseText));

                    } catch (e) {
                        if (error) error();
                    }
                } else {
                    if (error) error(xhr.responseText);
                }
            }
        }

        xhr.onerror = function () {
            if (error) error();
        }
        xhr.ontimeout = function () {
            if (error) error();
        }


        xhr.send();
    }
    static post({ url, data }, suceess, error) {


        let xhr = new XMLHttpRequest();

        xhr.open('POST', url);

        xhr.setRequestHeader('Content-Type', 'application/json');



        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    try {
                        suceess(JSON.parse(xhr.responseText));

                    } catch (e) {
                        if (error) error();
                    }
                } else {
                    if (error) error(xhr.responseText);
                }
            }
        }

        xhr.onerror = function () {
            if (error) error();
        }
        xhr.ontimeout = function () {
            if (error) error();
        }

        xhr.send(JSON.stringify(data));

    }
    static delete({ url, data }, suceess, error) {


        let xhr = new XMLHttpRequest();

        xhr.open('DELETE', url);

        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('token'));


        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    try {
                        suceess(JSON.parse(xhr.responseText));

                    } catch (e) {
                        if (error) error();
                    }
                } else {
                    if (error) error(xhr.responseText);
                }
            }
        }

        xhr.onerror = function () {
            if (error) error();
        }
        xhr.ontimeout = function () {
            if (error) error();
        }

        xhr.send(JSON.stringify(data));

    }
    static put() {

    }
    static remove_playlist() {
        return `${api}/playlist`
    }

    static request(url, success, error) {

        let xhr = new XMLHttpRequest();

        xhr.open('GET', url);

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    try {
                        success(JSON.parse(xhr.responseText));

                    } catch (e) {
                        if (error) error();
                    }
                } else {
                    if (error) error(xhr.responseText);
                }
            }
        }

        xhr.onerror = function () {
            if (error) error();
        }
        xhr.ontimeout = function () {
            if (error) error();
        }

        xhr.send();

        controller.push(xhr);
    }
}

export default Requests;