import pages, { ChangePage } from "../Remote/Pages";
import { OS } from "../Utils/Os";
import { append, el } from "../Utils/Utils";
import get_word from "../Utils/Words";
import './Exit.scss'


export default class Exit {


    init() {

        this.render();
    }

    render() {

        const that = this;
        const exit_page = el({
            attr: {
                class: 'exit-parent',
                id: 'exit_parent'
            },
            children: [
                {
                    attr: {
                        class: 'exit-page-container',
                    },
                    children: [
                        {
                            attr: {
                                class: 'exit-page-title',
                            },
                            innerHTML: get_word('do you want to exit')
                        },
                        {
                            attr: {
                                class: 'exit-page-buttons-container',
                            },
                            render: (parent) => {
                                ['yes', 'no'].forEach(item => {
                                    append(parent, el({
                                        attr: {
                                            class: 'exit-page-button exit-item-control',
                                            type: item,
                                            onclick: function () {
                                                that.click_item(this)
                                            }
                                        },
                                        innerHTML: get_word(item)
                                    }))
                                })
                            }
                        }
                    ]
                }
            ]
        })

        append(document.body, exit_page)
    }

    click_item(that) {
        let type = that.getAttribute('type');

        if (type == 'yes') {
            Exit.exit_app();
        } else {
            this.close();
        }
    }
    static exit_app() {
        if (OS == 'android') {
            // window.android.closeApp();
        } else if (OS == 'tizen') {
            window.tizen.application.getCurrentApplication().exit();
        } else {
            if (webOS) {
                webOS.platformBack();

            } else {
                window.close();

            }
        }

        if (pages.previous)
            ChangePage(pages.previous, {}, true, false)
    }

    close() {
        ChangePage(pages.previous, {}, true, false)
    }
}