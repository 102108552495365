

const AppConfig = {
    api: 'https://api.4k4matic-panel.info/api',
    socket_host: 'https://api.4k4matic-panel.info/',
    app_name: '4K Matic',
    app_version: '1.0.25',
    servers: [],
    selected_server: null,
    selected_server_info: {},
    background: require('./images/bg.jpg'),
    language: localStorage.getItem('lang') || "en",
    parental_code: localStorage.getItem('parental_code') || "0000",
    locked_categories: {},
    hided_categories: {},
    xtream_epg: localStorage.getItem('xtream_epg') || "1",
    tmdb_api: localStorage.getItem('tmdb_api') || "0",
    remove_subtitle_background: localStorage.getItem('remove_subtitle_background') || "0",
    hided_data: {
        live: {},
        movies: {},
        series: {},
        items: {},
    },

}

window.AppConfig = AppConfig;
export default AppConfig;