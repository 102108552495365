

import { xtream_instance } from "..";
import PlayerSettings from "../Components/PlayerSettings/PlayerSettings";
import { get_el } from "../Utils/Utils";
import Player from "./Player";
import pauseIcon from './images/pause.png'
import playIcon from './images/play.png'
// import PlayerSettings from "./PlayerSettings";

export default class AvPlayer {
    constructor () { }
    static player_data = null;
    static seek_to_retry = 0;
    static retry_count = 0;
    static retry_timeout = null;
    async init(data, current_time) {
        this.destroy();

        if (data) {
            AvPlayer.player_data = data
            Player.played_data = data;
        };


        AvPlayer.retry_count = 0;

        Player.recently_watched(data)

        Player.video_loading();
        Player.video_error('remove');


        let url = xtream_instance.get_stream_url(data);
        // url = 'https://media.istockphoto.com/id/1426881162/video/soccer-football-championship-stadium-with-crowd-of-fans-blue-team-attack-and-score-goal.mp4?s=mp4-640x640-is&k=20&c=5uaH1mM-5nF9n6dqQmo7-Wp4n21EaUqnVanyH9Th4Rs='
        this.set_tizen_listeners();

        let TIME = current_time || 0;
        this.play(url, TIME)



    }
    set_tizen_listeners() {

        AvPlayer.listeners_seted = true;
        const _TIZEN_LISTENERS_ = {
            onbufferingstart: function () { },
            onbufferingcomplete: function () { },
            onstreamcompleted: function () {
                if (Player.played_data.stream_type == 'live') {
                    Player.player_instance.init(AvPlayer.player_data);
                } else {
                    Player.stop();
                }
            },
            oncurrentplaytime: function (time) {


                Player.video_error('remove');
                Player.video_loading('remove');

                if (Player.played_data.stream_type != 'live') {

                    if (!Player.change_sec_mode && Player.is_player_init) {
                        let current_time = parseInt(time / 1000) || 0;
                        let duration = (webapis.avplay.getDuration() || 0) / 1000;

                        if (Player.current_time <= Player.duration) {
                            Player.current_time = current_time;
                            Player.duration = duration;

                            Player.set_time_update();
                        }

                    }

                    if (PlayerSettings.show_subtitle && PlayerSettings.is_selected_open_subtitle) {
                        Player.show_selected_open_subtitle();
                    }

                }
            },
            ondrmevent: function (drmEvent, drmData) { },
            onerror: function (type, data) {

                if (AvPlayer.retry_count < 5 && Player.played_data.stream_type == 'live') {

                    AvPlayer.retry_timeout = setTimeout(() => {

                        AvPlayer.retry_count++;

                        let changeM3u = AvPlayer.retry_count > 2 ? true : false;

                        Player.player_instance.play(xtream_instance.get_stream_url(Player.played_data, changeM3u));

                    }, 1000)

                } else {
                    Player.video_loading('remove')
                    Player.video_error()
                    Player.player_state = 'pause';
                }

            },
            onbufferingprogress: function (percent) { },
            onevent: function (eventType, eventData) { },
            onsubtitlechange: function (duration, text, data3, data4) {

                if (PlayerSettings.show_subtitle && !PlayerSettings.is_selected_open_subtitle) {
                    let subtitle_text = get_el('#subtitle_text');

                    if (subtitle_text) {
                        subtitle_text.innerHTML = text;
                        setTimeout(function () {
                            if (subtitle_text)
                                subtitle_text.innerHTML = "";
                        }, duration);
                    }


                }
            }
        }

        webapis.avplay.setListener(_TIZEN_LISTENERS_);

    }
    play(url, current_time = 0) {

        webapis.avplay.close();
        webapis.avplay.open(url);
        
        try {

            webapis.avplay.prepareAsync(function () {

                Player.player_state = 'play';
                AvPlayer.get_tizen_position();
                Player.video_error('remove')

                Player.video_loading('remove')

                webapis.avplay.play();

                if (Player.pause_icon) Player.pause_icon.removeClass('show');
                if (current_time) {
                    webapis.avplay.seekTo(current_time * 1000, function () {

                    })
                }

                if (Player.play_button) Player.play_button.setAttribute('style', `background-image: url(${pauseIcon})`)

                // get total track info
                let type = Player.played_data.stream_type || 'series';

                PlayerSettings.init(type);

            }, function () {

                if (AvPlayer.retry_count > 5) {
                    Player.video_loading('remove')
                    Player.video_error()
                    Player.player_state = 'pause';
                }

            })
        } catch (e) {
            console.log('prepareAsync error-> ', e);
        }
    }
    play_video() {

        webapis.avplay.play();

        Player.player_state = 'play';

        if (Player.pause_icon) Player.pause_icon.removeClass('show');

        if (Player.play_button) Player.play_button.setAttribute('style', `background-image: url(${pauseIcon})`)

    }
    pause() {

        webapis.avplay.pause();
        Player.player_state = 'pause';
        if (Player.pause_icon) Player.pause_icon.addClass('show');

        if (Player.play_button) Player.play_button.setAttribute('style', `background-image: url(${playIcon})`)
    }
    stop() {
        const that = this;
        webapis.avplay.seekTo(0, function () {
            setTimeout(() => {
                that.pause();
            }, 500)
        });

    }
    play_pause() {
        if (Player.player_state == 'pause') {

            webapis.avplay.play();

            if (Player.pause_icon) Player.pause_icon.removeClass('show');

            if (Player.play_button) Player.play_button.setAttribute('style', `background-image: url(${pauseIcon})`)

            Player.player_state = 'play';

        } else {

            webapis.avplay.pause();

            Player.player_state = 'pause';

            if (Player.pause_icon) Player.pause_icon.addClass('show');

            if (Player.play_button) Player.play_button.setAttribute('style', `background-image: url(${playIcon})`)

        }
    }
    seek_to() {
        const that = this;
        let time = parseInt(Player.current_time * 1000)
        webapis.avplay.seekTo(time, function () {
            if (Player.state_after_seek == 'play') {
                Player.player_instance.play_video()
            }
            AvPlayer.seek_to_retry = 0;
            Player.change_sec_mode = false
        }, function (data) {
            if (AvPlayer.seek_to_retry < 3) {
                AvPlayer.seek_to_retry++;
                setTimeout(() => {
                    that.seek_to();
                }, 300)
                return
            }
            Player.change_sec_mode = false;
        });
    }
    destroy() {

        clearInterval(Player.retry_timeout);

        webapis.avplay.close();

        AvPlayer.player_data = null;

    }
    set_full_screen() {

        get_el('#player_parent').addClass('full-screen');

        AvPlayer.get_tizen_position();

    }

    hide_full_screen() {

        get_el('#player_parent').removeClass('full-screen');

        AvPlayer.get_tizen_position();

    }

    static get_tizen_position() {

        const rect = document.getElementById('player_item').getBoundingClientRect();

        let x = rect.x ? rect.x : rect.left;

        let y = rect.y ? rect.y : rect.top;

        webapis.avplay.setDisplayRect(x, y, rect.width, rect.height);

    }


}