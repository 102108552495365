import { remove_class } from "../Utils/Utils";



const Control = {
    current: "info",
    previous: "",
    set_current: function (current) {
        this.previous = this.current;
        this.current = current;
        move();
    },
    set_previous: function () {
        this.current = this.previous;
        move();
    },
    info: {
        index: 0,
        items: document.getElementsByClassName('info-page-button-control'),
        left: function () {
            this.index = 0;
            this.move();
        },
        right: function () {
            this.index = 1;
            this.move();
        },
        ok: function () {
            this.items[this.index].click();
        },
        back: function () {
            this.items[1].click();
        },
        move: function () {

            remove_class();

            this.items[this.index].addClass('active')

        }
    }
}

export default Control;