
import Live from "../Live/Live";
import Menu from "../Menu/Menu";
import { MovieInfo } from "../MovieInfo/MovieInfo";
import LockCategories from "../LockCategories/LockCategories";
import Movies from "../Movies/Movies";
import ParentalCode from "../ParentalCode/ParentalCode";
import Player from "../Player/Player";
import Settings from "../Settings/Settings";
import { get_el, get_els } from "../Utils/Utils";
import { move } from "./Keys";
import Search from "../Search/Search";
import Move from "../Move/Move";
import Exit from "../Exit/Exit";
import MoveChannels from "../MoveChannels/MoveChannels";
import Info from "../Info/Info";


export let Control;

const pages = {
    root: document.getElementById('root'),
    current: "",
    previous: "",
    activePage: '',
    root: document.getElementById('root'),
    set_current: function (current, data = {}, hide = false, show) {

        if (current != this.current) {
            this.previous = this.current;

            if (this.previous && hide) {
                this[this.previous].hide(data);
            }

        }
        this.current = current;

        Control = require('../' + current + '/' + current + 'Control').default;

        if (this[current] && show) {

            this[current].show(data);
        }
        else {

            if (data.control) {
                const { control_type, index } = data.control
                Control[control_type].index = index;
            }
            move()
        }

    },

    set_previous: function () {

        this.set_current(this.previous);
    },
    Menu: {
        show: function () {

            pages.root.innerHTML = '';

            pages.activePage = 'Menu';

            const menu = new Menu();
            Control.Menu.index = 0;
            Control.items.row = 0;
            Control.items.index = 0;
            Control.groups.index = 0;

            menu.init();
            move()

        },
        hide: function () {

            Control.current = 'Menu'
        }
    },
    Live: {
        show: function (data) {
            pages.root.innerHTML = '';

            pages.activePage = 'Live';
            const live = new Live(data.data, data.cat_name);

            Control.categories.index = 0;
            Control.channels.index = 0;

            live.init();
            move();

        },
        hide: function () {
            Live.destroy();
            Control.current = 'channels'
        }
    },
    Movies: {
        show: function (data) {

            pages.root.innerHTML = '';

            pages.activePage = 'Movies';

            const movie = new Movies(data);

            movie.init();

            Control.categories.index = this.findeIndex();
            Control.movies.index = 0;
            Control.movies.row = 0;

            move();


        },
        findeIndex: function () {
            let items = get_els('.category-item-control')
            let index = 0;
            if (items.length) {
                for (let i = 0; i < items.length; i++) {
                    let item = items[i];
                    if (item.className.indexOf('selected') != -1) {
                        index = i;
                        break;
                    }
                }
            }

            return index;
        },
        hide: function () {
            Movies.destroy();

            Control.current = 'categories';
        }

    },
    MovieInfo: {
        show: function (data) {
            const movieInfo = new MovieInfo(data);

            movieInfo.init();
            move();
        },
        hide: function () {
            MovieInfo.destroy();
        },
    },
    Player: {
        player: null,
        show: function ({ data, type, current_time = 0 }) {

            this.player = new Player();

            this.player.init(data, type, current_time)

            Control.set_current('progress_bar');
        },
        hide: function () {
            this.player.destroy();
            this.player = null;
            let item = get_el('#player_parent');
            if (item) item.remove();
        }
    },
    Settings: {
        show: function () {
            pages.root.innerHTML = '';

            pages.activePage = 'Settings';

            const settings = new Settings();
            Control.categories.index = 0;
            Control.settings.index = 0;

            settings.init();

            Control.current = 'categories';
            move();

        },
        hide: function () {
            Settings.destroy();
        }
    },
    ParentalCode: {
        show: function (data) {

            const parentalCode = new ParentalCode(data);
            Control.parental_code.index = 0;

            parentalCode.init();

            move();


        },
        hide: function () {
            let parent = get_el('#parental_code_popup');
            if (parent) parent.remove();

            Control.current = 'parental_code';
        }
    },
    LockCategories: {
        show: function (data) {

            const lockCategories = new LockCategories(data);

            Control.lock_category.index = 0;
            Control.lock_category.row = 0;

            lockCategories.init();

            move();

        },
        hide: function () {

            let item = get_el('#lock_categories_parent');

            if (item)
                item.remove();

            Control.current = 'lock_category'
        }
    },
    Search: {
        show: function (data) {

            Control.search.index = 0;
            Control.search.row = 0;

            const search = new Search(data);
            search.init();

            move();

        },
        hide: function () {
            // destroy
            let item = get_el('#search_parent');
            if (item) item.remove();
            // Control.current = 'categories'; 
        }
    },
    Move: {
        show: function (data) {
            // create
            const move = new Move(data)

            move.init();
        },
        hide: function () {
            // destroy


        }
    },
    MoveChannels: {
        show: function (data) {
            // create
            const move_channels = new MoveChannels(data)

            move_channels.init();
        },
        hide: function () {
            // destroy


        }
    },

    Info: {

        show: function (data) {
            // create
            const info = new Info(data)

            info.init();

            Control.info.index = 0;

            move();
        },
        hide: function () {
            // destroy
        }
    },
    Exit: {
        show: function () {
            const exit = new Exit();

            exit.init();
            Control.exit.index = 0;
            move();
        },
        hide: function () {
            let item = get_el('#exit_parent');
            if (item) item.remove();
        }
    }



};


export const ChangePage = (page = 'Login', data = {}, hide = false, show = true) => {
    pages.set_current(page, data, hide, show);
}

export const ChangeControl = (page = '') => {

    Control = require('../' + page + '/' + page + 'Control').default;
}

export default pages;

window.pages = pages;