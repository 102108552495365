import { remove_class } from "../Utils/Utils";
import { move } from "../Remote/Keys";
import Menu from "./Menu";
import { ChangePage } from "../Remote/Pages";
import { content } from "../Utils/Content";


const Control = {
    current: "Menu",
    previous: "",
    set_current: function (current) {

        if (this.current != current)
            this.previous = this.current;

        this.current = current;

        move();
    },
    set_previous: function () {
        this.current = this.previous;

        move();
    },
    Menu: {
        index: 0,
        items: document.getElementsByClassName('menu-item-control'),
        up: function () {
            if (this.index == 0) return;

            this.index--;
            this.move();
        },
        down: function () {
            if (this.index == this.items.length - 1) return;

            this.index++;
            this.move();
        },
        right: function () {

            if(!content.series.data.all && !content.movies.data.all) return
            Control.set_current('items')
        },
        ok: function () {
            this.items[this.index].click();
        },
        back: function () {
            ChangePage('Exit')

        },
        move: function () {
            remove_class('active');
            this.items[this.index].addClass('active');
        },
    },
    items: {
        index: 0,
        row: 0,
        type: '',
        items: document.getElementsByClassName('latest-vod-container-control'),
        up: function () {
            if (this.row == 0) return;

            if(this.items[1-this.row].children.length == 0) return
            this.row--;
            this.move();
        },
        down: function () {
          
            if (this.row == this.items.length - 1) {
                if (Control.groups.items.length)
                    Control.set_current('groups')
                return
            };

            
            if(this.items[1+this.row].children.length == 0) return

            this.row++;

            this.move();

        },
        left: function () {
            let item_index = this.items[this.row].children[0].getAttribute('index').split('_')[1];

            if (this.index == 0 && item_index != 0) {
                Menu.re_render_movie_item({
                    type: this.type,
                    index: `${this.row}_${+item_index - 1}`,
                    direction: 'left'
                })
            } else if (this.index == 0) {
                Control.set_current('Menu')
                return
            } else {
                this.index--;
            }

            this.move();
        },
        right: function () {

            let data_index = content[this.type].data.all.items.length - 1;
            let item_index = this.items[this.row].children[this.items[this.row].children.length - 1].getAttribute('index').split('_')[1];

            let last_index = this.items[this.row].children.length - 1
            if (this.index == last_index && item_index != data_index) {
                Menu.re_render_movie_item({
                    type: this.type,
                    index: `${this.row}_${+item_index + 1}`,
                    direction: 'right'
                })
            } else if (this.index == last_index) {
                return
            } else {
                this.index++;

            }
            this.move();
        },
        ok: function () {
            this.items[this.row].children[this.index].click();
        },
        back: function () {
            Control.set_current('Menu')
        },
        move: function () {
            remove_class();
            if (this.index > this.items[this.row].children.length - 1) this.index = this.items[this.row].children.length - 1;

            if (this.row == 0)
                this.type = 'movies';
            else
                this.type = 'series';
            this.items[this.row].children[this.index].addClass('active');
        },
    },
    groups: {
        index: 0,
        items: document.getElementsByClassName('group-items-control'),
        up: function () {
            Control.set_current('items')
        },
        left: function () {
            let item_index = this.items[0].getAttribute('index');
            if (this.index == 0 && item_index != 0) {
                Menu.re_render_group_item({
                    index: +item_index - 1,
                    direction: 'left'
                })
            } else if (this.index == 0) {
                Control.set_current('Menu')
                return
            } else {
                this.index--;
            }

            this.move();
        },
        right: function () {
            let item_index = this.items[this.items.length - 1].getAttribute('index');
            if (this.index == this.items.length - 1 && item_index != content.groups.length - 1) {
                Menu.re_render_group_item({
                    index: +item_index + 1,
                    direction: 'right'
                })
            }
            else if (this.index == this.items.length - 1) {
                // Control.set_current('Menu')
                return
            } else {
                this.index++;
            }
            this.move();
        },
        ok: function () {
            this.items[this.index].click();
        },
        back: function () {
            Control.set_current('Menu')
        },
        move: function () {
            remove_class();
            this.items[this.index].addClass('active');
        },

    },
    on_hold: {
        back: function () {
            Menu.on_hold_back()
        },
    }

}

export default Control;