import AppConfig from "../AppConfig";

const words = {
    "live tv": {
        en: "Live TV",
        ar: "البث المباشر",
        fr: "Direct",
        de: "Live TV",
        es: "TV en vivo",
    },
    "movies": {
        en: "Movies",
        ar: "أفلام",
        fr: "Films",
        de: "Filme",
        es: "Películas",

    },
    "series": {
        en: "Series",
        ar: "مسلسلات",
        fr: "Séries",
        de: "Serie",
        es: "Series",

    },
    "settings": {
        en: "Settings",
        ar: "الإعدادات",
        fr: "Paramètres",
        de: "Einstellungen",
        es: "Ajustes",

    },
    "latest movies": {
        en: "Latest movies",
        ar: "أحدث الأفلام",
        fr: "Derniers films",
        de: "Neueste filme",
        es: "Últimas películas",
    },
    "latest series": {
        en: "Latest series",
        ar: "أحدث المسلسلات",
        fr: "Dernières séries",
        de: "Neueste serie",
        es: "Últimas series",
    },
    "groups": {
        en: "Groups",
        ar: "مجموعات",
        fr: "Groupes",
        de: "Gruppen",
        es: "Grupos",
    },
    "all": {
        en: "All",
        ar: "الكل",
        fr: "Tout",
        de: "Alle",
        es: "Todos",
    },
    "favorites": {
        en: "Favorites",
        ar: "المفضلة",
        fr: "Favoris",
        de: "Favoriten",
        es: "Favoritos",
    },
    "program not found": {
        en: "Program not found",
        ar: "البرنامج غير موجود",
        fr: "Programme introuvable",
        de: "Programm nicht gefunden",
        es: "Programa no encontrado",
    },
    "lock": {
        en: "Lock",
        ar: "قفل",
        fr: "Verrouiller",
        de: "Sperren",
        es: "Bloquear",
    },
    'full screen': {
        en: "Full screen",
        ar: "ملء الشاشة",
        fr: "Plein écran",
        de: "Vollbild",
        es: "Pantalla completa",
    },
    "move": {
        en: "Move",
        ar: "تحريك",
        fr: "Déplacer",
        de: "Bewegen",
        es: "Mover",
    },
    "back": {
        en: "Back",
        ar: "رجوع",
        fr: "Retour",
        de: "Zurück",
        es: "Atrás",
    },
    "play": {
        en: "Play",
        ar: "تشغيل",
        fr: "Jouer",
        de: "Spielen",
        es: "Jugar",
    },
    "application info": {
        en: "Application info",
        ar: "معلومات التطبيق",
        fr: "Info application",
        de: "Anwendungsinformationen",
        es: "Información de la aplicación",
    },
    "settings": {
        en: "Settings",
        ar: "الإعدادات",
        fr: "Paramètres",
        de: "Einstellungen",
        es: "Ajustes",
    },
    "servers": {
        en: "Servers",
        ar: "الخوادم",
        fr: "Serveurs",
        de: "Server",
        es: "Servidores",
    },
    "use xtream code epg": {
        en: "Use XTREAM code EPG",
        ar: "استخدم XTREAM code EPG",
        fr: "Utiliser XTREAM code EPG",
        de: "Verwenden Sie XTREAM code EPG",
        es: "Utilizar EPG de código XTREAM",
    },
    "use tmdb api": {
        en: "Use TMDB api",
        ar: "استخدم TMDB api",
        fr: "Utiliser TMDB api",
        de: "Verwenden Sie TMDB api",
        es: "Utilizar api de TMDB",
    },
    "remove subtitle background": {
        en: "Remove subtitle background",
        ar: "إزالة خلفية العنوان الفرعي",
        fr: "Supprimer l'arrière-plan des sous-titres",
        de: "Untertitelhintergrund entfernen",
        es: "Eliminar el fondo de los subtítulos",
    },
    "change language": {
        en: "Change language",
        ar: "غير اللغة",
        fr: "Changer la langue",
        de: "Sprache ändern",
        es: "Cambiar idioma",
    },
    "change parental code": {
        en: "Change parental code",
        ar: "تغيير الرمز الأبوي",
        fr: "Changer le code parental",
        de: "Elterncode ändern",
        es: "Cambiar código parental",
    },
    "lock categories": {
        en: "Lock categories",
        ar: "تأمين الفئات",
        fr: "Verrouiller les catégories",
        de: "Kategorien sperren",
        es: "Bloquear categorías",
    },
    "hide categories": {
        en: "Hide categories",
        ar: "إخفاء الفئات",
        fr: "Masquer les catégories",
        de: "Kategorien ausblenden",
        es: "Ocultar categorías",
    },
    "mac address": {
        en: "Mac address",
        ar: "عنوان ماك",
        fr: "Adresse Mac",
        de: "Mac Adresse",
        es: "Dirección Mac",
    },
    "app version": {
        en: "App version",
        ar: "إصدار التطبيق",
        fr: "Version de l'application",
        de: "App Version",
        es: "Versión de la aplicación",
    },
    "you don't have servers": {
        en: "You don't have servers",
        ar: "ليس لديك خوادم",
        fr: "Vous n'avez pas de serveurs",
        de: "Sie haben keine Server",
        es: "No tienes servidores",
    },
    "choose server": {
        en: "Choose server",
        ar: "اختر الخادم",
        fr: "Choisissez le serveur",
        de: "Server auswählen",
        es: "Elige servidor",
    },
    "choose language": {
        en: "Choose language",
        ar: "اختر اللغة",
        fr: "Choisissez la langue",
        de: "Sprache wählen",
        es: "Elige el idioma",

    },
    "enter parental code": {
        en: "Enter parental code",
        ar: "أدخل الرمز الأبوي",
        fr: "Entrez le code parental",
        de: "Geben Sie den Elterncode ein",
        es: "Ingrese el código parental",
    },
    "wrong code": {
        en: "Wrong code",
        ar: "رمز خاطئ",
        fr: "Code incorrect",
        de: "Falscher Code",
        es: "Código incorrecto",
    },
    "enter new parental code": {
        en: "Enter new parental code",
        ar: "أدخل الرمز الأبوي الجديد",
        fr: "Entrez le nouveau code parental",
        de: "Geben Sie den neuen Elterncode ein",
        es: "Ingrese el nuevo código parental",
    },
    "not have any item": {
        en: "Not have any item",
        ar: "لا يوجد أي عنصر",
        fr: "Ne pas avoir d'article",
        de: "Keinen Artikel haben",
        es: "No tener ningún artículo",
    },
    "not have channels": {
        en: "Not have channels",
        ar: "لا يوجد قنوات",
        fr: "Ne pas avoir de chaînes",
        de: "Keine Kanäle haben",
        es: "No tener canales",
    },
    "not have movies": {
        en: "Not have movies",
        ar: "لا يوجد أفلام",
        fr: "Ne pas avoir de films",
        de: "Keine Filme haben",
        es: "No tener películas",
    },
    "continue watched": {
        en: "Continue watched",
        ar: "مشاهدة مستمرة",
        fr: "Continuer à regarder",
        de: "Weiter beobachtet",
        es: "Continuar viendo",
    },
    "recently watched": {
        en: "Recently watched",
        ar: "شاهدت مؤخرا",
        fr: "Récemment regardé",
        de: "Kürzlich gesehen",
        es: "Visto recientemente",
    },
    "search": {
        en: "Search",
        ar: "بحث",
        fr: "Chercher",
        de: "Suche",
        es: "Buscar",
    },
    "do you want to continue": {
        en: "Do you want to continue?",
        ar: "هل تريد الاستمرار؟",
        fr: "Voulez-vous continuer?",
        de: "Möchten Sie fortfahren?",
        es: "¿Quieres continuar?",
    },
    "yes": {
        en: "Yes",
        ar: "نعم",
        fr: "Oui",
        de: "Ja",
        es: "Sí",
    },
    "no": {
        en: "No",
        ar: "لا",
        fr: "Non",
        de: "Nein",
        es: "No",
    },
    "do you want to exit": {
        en: "Do you want to exit?",
        ar: "هل تريد الخروج؟",
        fr: "Voulez-vous sortir?",
        de: "Möchten Sie beenden?",
        es: "¿Quieres salir?",
    },
    "expired date": {
        en: "Expired date",
        ar: "تاريخ الانتهاء",
        fr: "Date d'expiration",
        de: "Ablaufdatum",
        es: "Fecha de caducidad",
    },
    "app version": {
        en: "App version",
        ar: "إصدار التطبيق",
        fr: "Version de l'application",
        de: "App Version",
        es: "Versión de la aplicación",

    },
    "delete": {
        en: "Delete",
        ar: "حذف",
        fr: "Effacer",
        de: "Löschen",
        es: "Borrar",
    },
    "do you want to remove this server": {
        en: "Do you want to remove this server?",
        ar: "هل تريد إزالة هذا الخادم؟",
        fr: "Voulez-vous supprimer ce serveur?",
        de: "Möchten Sie diesen Server entfernen?",
        es: "¿Quieres eliminar este servidor?",
    },
    "no movies data":{
        en: "No movies data",
        ar: "لا توجد بيانات أفلام",
        fr: "Pas de données de films",
        de: "Keine Filmdaten",
        es: "No hay datos de películas",
    },
    "no series data":{
        en: "No series data",
        ar: "لا توجد بيانات مسلسلات",
        fr: "Pas de données de séries",
        de: "Keine Seriendaten",
        es: "No hay datos de series",
    },
    "loading live categories":{
        en: "Loading live categories",
        ar: "تحميل فئات البث المباشر",
        fr: "Chargement des catégories en direct",
      
        de: "Live-Kategorien laden",
        es: "Cargando categorías en vivo",
    },
    "loading live streams":{
        en: "Loading live streams",
        ar: "تحميل البث المباشر",
        fr: "Chargement des flux en direct",
        de: "Live-Streams laden",
        es: "Cargando transmisiones en vivo",
    },
    "loading series":{
        en: "Loading series",
        ar: "تحميل المسلسلات",
        fr: "Chargement des séries",
        de: "Serien laden",
        es: "Cargando series",
    },
    "loading movie categories":{
        en: "Loading movie categories",
        ar: "تحميل فئات الأفلام",
        fr: "Chargement des catégories de films",
        de: "Filmkategorien laden",
        es: "Cargando categorías de películas",
    },
    "loading movies":{
        en: "Loading movies",
        ar: "تحميل الأفلام",
        fr: "Chargement des films",
        de: "Filme laden",
        es: "Cargando películas",
    },
    "loading series categories":{
        en: "Loading series categories",
        ar: "تحميل فئات المسلسلات",
        fr: "Chargement des catégories de séries",
        de: "Serienkategorien laden",
        es: "Cargando categorías de series",
    }
}



function get_word(word) {

    let lang = AppConfig.language;

    if (!words[word])
        return word;

    return words[word][lang] || word;
}


export function translate() {
    let items = document.getElementsByClassName('translate');

    for (let i = 0; i < items.length; i++) {
        let word = items[i].getAttribute('word');
        items[i].innerHTML = get_word(word);
    }


}

export default get_word;