import AppConfig from "../../AppConfig";
import { content } from "../../Utils/Content";


export let recently_watched = {
    live: null
};

export default class RecentlyWatched {

    static get() {
        if (!recently_watched.live)
            recently_watched.live = RecentlyWatched.get_data();

        return recently_watched.live;
    }

    static get_data() {
        let data = localStorage.getItem(`recently_watched-${AppConfig.selected_server.id}`);

        if (data) {
            return JSON.parse(data);
        } else {
            return []
        }
    }

    static set(data) {

        recently_watched.live = data;

        localStorage.setItem(`recently_watched-${AppConfig.selected_server.id}`, JSON.stringify(data));
    }

    static add(id) {
        let recently_watched = RecentlyWatched.get();
        recently_watched.unshift(id);
        RecentlyWatched.set(recently_watched);
    }
    static remove(id) {

        let recently_watched = RecentlyWatched.get();

        recently_watched = recently_watched.filter(item => item != id);

        RecentlyWatched.set(recently_watched);
    }

    static is_watched(id) {

        let recently_watched = RecentlyWatched.get();

        return recently_watched.findIndex(item => item == id);

    }

    static change_data({ type, id, action }) {
        let data = content[type].data.all.items.find(item => item.id == id);

        if (action == 'remove') {
            content.live.data['recently watched'].items = content.live.data['recently watched'].items.filter(item => item.id != id);
        } else {
            content.live.data['recently watched'].items.unshift(data);
        }

    }
}