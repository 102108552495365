import { get_el, remove_class } from "../Utils/Utils";
import { move } from "../Remote/Keys";
import Settings from './Settings'
import { ChangePage } from "../Remote/Pages";
import AppConfig from "../AppConfig";


const Control = {
    current: "categories",
    previous: "",
    set_current: function (current) {

        if (this.current != current)
            this.previous = this.current;

        this.current = current;

        move();
    },
    set_previous: function () {
        this.current = this.previous;

        move();
    },
    categories: {
        index: 0,
        items: document.getElementsByClassName('category-item-control'),
        up: function () {
            if (this.index == 0) return;
            this.index--;
            this.move();
        },
        down: function () {
            if (this.index == this.items.length - 1) return;

            this.index++;
            this.move();
        },
        right: function () {
            if (Control.settings.items.length) {
                Control.set_current('settings')
            }
        },
        ok: function () {
            this.items[this.index].click();
        },
        back: function () {
            ChangePage('Menu', {}, true)
        },
        move: function () {
            remove_class('active');
            this.items[this.index].addClass('active');
        },
    },
    settings: {

        index: 0,
        items: document.getElementsByClassName('settings-item-control'),
        up: function () {

            if (this.index == 0) return;
            this.index--;
            this.move();
        },
        down: function () {
            if (this.index == this.items.length - 1) return;
            this.index++;
            this.move();
        },
        ok: function () {
            this.items[this.index].click();
        },
        back: function () {
            ChangePage('Menu', {}, true)
        },
        left: function () {
            Control.set_current('categories')
        },
        move: function () {
            remove_class('active');
            this.items[this.index].addClass('active');
        },
    },
    servers: {
        index: 0,
        items: document.getElementsByClassName('settings-item-control'),
        red: function () {
            Settings.show_remove_server_popup(this.items[this.index].getAttribute('server_id'))
        },
        up: function () {

            let item_index = this.items[this.index].getAttribute('index');
            if (this.index == 0 && item_index != 0) {
                Settings.re_render_server_item({
                    index: +item_index - 1,
                    direction: 'up'
                })
            } else if (this.index == 0) return;
            else
                this.index--;

            this.move();
        },
        down: function () {
            let item_index = this.items[this.items.length - 1].getAttribute('index');
            if (this.index == this.items.length - 1 && item_index != AppConfig.servers.length - 1) {
                Settings.re_render_server_item({
                    index: +item_index + 1,
                    direction: 'down'
                })
            } else if (this.index == this.items.length - 1) return;
            else
                this.index++;


            this.move();
        },
        ok: function () {
            this.items[this.index].click();
        },
        back: function () {
            ChangePage('Menu', {}, true)
        },
        left: function () {
            Control.set_current('categories')
        },
        move: function () {
            remove_class('active');
            this.items[this.index].addClass('active');
        },
    },
    remove_server: {
        index: 0,
        items: document.getElementsByClassName('popup-btn-control'),
        left: function () {
            this.index = 0;
            this.move();
        },
        right: function () {
            this.index = 1;
            this.move();
        },
        back: function () {
            Settings.hide_remove_server_popup()
        },
        ok: function () {
            this.items[this.index].click();
        },
        move: function () {
            remove_class('active', get_el('#popup_parent'));
            this.items[this.index].addClass('active');
        },
    },
    change_language: {
        index: 0,
        items: document.getElementsByClassName('language-item-control'),
        up: function () {
            if (this.index == 0) return;
            this.index--;
            this.move();
        },
        down: function () {
            if (this.index == this.items.length - 1) return;
            this.index++;
            this.move();
        },
        back: function () {
            Settings.change_language_back()
        },
        ok: function () {
            this.items[this.index].click();
        },
        move: function () {
            remove_class('active');
            this.items[this.index].addClass('active');
        }
    },
    on_hold: {
        back: function () {
            Settings.on_hold_back()
        },
    }

}

export default Control;