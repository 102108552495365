import { el, get_el } from "../../Utils/Utils";
import get_word from "../../Utils/Words";

import './NotHave.scss'

export default class NotHave {

    static render(parent = document.body, message = 'not have any item') {

        let is_have = get_el('#not_have')
        if (is_have) is_have.remove();

        let item = el({
            attr: {
                class: 'not-have',
                id: 'not_have',

            },
            children: [
                {
                    tagName: 'img',
                    attr: {
                        class: 'not-have-icon',
                        src: require('../images/empty.png')
                    }
                },
                {
                    attr: {
                        class: 'not-have-text',
                    },
                    innerHTML: get_word(message)
                }
            ]
        })


        parent.appendChild(item);
    }
    static remove() {
        let item = document.getElementById('not_have');
        if (item) {
            item.remove();
        }
    }
}