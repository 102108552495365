import { get_device_profile } from "..";
import AppConfig from "../AppConfig";
import { move } from "../Remote/Keys";
import Settings from "../Settings/Settings";
import { get_el } from "./Utils";

let socket;

let try_count = 0;

function socketSetup() {
    let s = document.createElement("script");
    s.onload = socketInit;
    s.src = "https://cdnjs.cloudflare.com/ajax/libs/socket.io/3.0.1/socket.io.min.js";
    s.onerror = function () {
        console.log('socket error');
        setTimeout(() => {
            if (try_count < 5) {
                socketSetup()
                try_count++;
            }
        }, 1000);
    };

    document.head.appendChild(s);


    function socketInit() {


        socket = io.connect(AppConfig.socket_host, { extraHeaders: { authorization: `Bearer ${localStorage.getItem('token')} ` } })

        socket.on('error', function (err) {
            console.log('socket error => ' + err);
        })
        socket.on("connect", function () {
            console.log("connected");
            socketMessage('hello')
        })
        socket.on('activate', function () {
            console.log('activate');
        });
        socket.on('deactivate', function () {
            console.log('deactivate');
        });
        socket.on('update_playlist', function () {
            update_playlist()

        });

    }
}

function socketDisconnect() {
    socket.disconnect();
}

function socketMessage(mess) {
    socket.emit("message", { message: mess })
}


async function update_playlist() {

    let _prof = get_device_profile();

    let profile = await _prof;

    AppConfig.servers = profile.rows[0].playlists;


    if (AppConfig.selected_server) {

        let { id = '' } = AppConfig.selected_server || {};


        let selected_playlist = AppConfig.servers.find(server => server.id == id);

        if (!selected_playlist) {

            location.reload();

            return

        }
    }




    if (Settings.selected_category == 'servers') {
        servers_list.remove();
        Settings.render_selected_category()
        move();
    }

}



export { socketSetup, socketDisconnect, socketMessage }