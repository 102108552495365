import { content } from "./Content";
import get_word from "./Words";
import { el, get_release_date_genre_info } from "../Utils/Utils";
import Favorite from "../Components/Favorite/Favorite";
import RecentlyWatched from "../Components/RecentlyWatched/RecentlyWatched";
import ContinueWatch from "../Components/ContinueWatching/ContinueWatching";
import AppConfig from "../AppConfig";
import Tmdb from "../Components/Tmdb/Tmdb";

// images 
import iamgeAll from '../images/all.png';
import imageFavorite from '../images/favorite.png';
import imageSearch from '../images/search.png';
import imageResently from '../images/recently.png';
import checked from '../images/checked.png';
import spinnerSVG from '../images/spinner.svg';

export let controller = [];

class Xtream {

    constructor ({ host, username, password }) {
        this.host = host;
        this.username = username;
        this.password = password;
        this.user_info = {};
        this.server_info = {};
    }

    set_host({ host, username, password }) {
        this.host = host;
        this.username = username;
        this.password = password;
    }
    async init(cb, error) {

        return new Promise((resolve, reject) => {
            let url = this.genereteUrl();
            let that = this;

            this.request(url, (data) => {
                // data.user_info.status = 'Expired';
                if (data.user_info.status == 'Expired' || data.user_info.auth == 0) {
                    if (error) error('expired');
                    reject('expired')
                    return
                }
                that.user_info = data.user_info;
                that.server_info = data.server_info;

                AppConfig.selected_server_info = {
                    user_info: that.user_info,
                    server_info: that.server_info,
                }
                if (cb) cb();
                resolve()
            }, function (err) {
                if (error) error(err);
                console.log('error->', err);
                reject()
            });
        })

    }
    async get_live_data() {

        let that = this;

        let count = 0;
        let _categories = [];
        let _channels = [];

        return new Promise((resolve, reject) => {

            let catUrl = that.live_categories()

            that.request(catUrl, (data) => {

                _categories = data;
                count++;
                done()

            }, function (err) {

                console.log('err', err);

                reject(err)

            });


            let url = that.channels()

            that.request(url, (data) => {
                
                _channels = data;
                count++;
                done();
               
            }, function (err) {
                console.log(err);
           
                    reject(err)
         
            });


            function done(){

                if(count != 2) return

                let ex_data = {
                    favorites: [],
                    recently_watched: []
                };


                _channels.forEach(item => {
                    if (!ex_data[item.category_id]) ex_data[item.category_id] = [];

                    item.id = item.stream_id;

                    ex_data[item.category_id].push(item);

                    let fav_index = Favorite.is_favorite(item.id, 'live');
                    if (fav_index >= 0) {
                        ex_data.favorites[fav_index] = item;
                    }

                    let watched_index = RecentlyWatched.is_watched(item.id, 'live');
                    if (watched_index >= 0) {
                        ex_data.recently_watched[watched_index] = item;
                    }
                })

                ex_data.all = _channels

                  // sort channels
       
                  let moved_channels = localStorage.getItem(`moved_channels-${AppConfig.selected_server.id}`) || '{}';
       
                  moved_channels = JSON.parse(moved_channels);
          
                  _channels = that.sort_data(ex_data, moved_channels)

      
                
        
                // marge data 
        
        
               resolve(that.marge_data(_channels, _categories, 'live'));
             }


        })
       
    }

    async get_movies_data() {

        let that = this;
        let count = 0;
        let _categories = [];
        let _movies = [];

        return new Promise((resolve, reject) => {

            let catUrl = that.movie_categories();


            that.request(catUrl, (data) => {

                _categories = data;
                count++
                done();

            }, function (err) {

                reject(err)

            });


            let url = that.movies()

            that.request(url, (data) => {

                _movies = data;
                count++
                done();

            }, function (err) {

                console.log('get movies error->', err);

               reject(err)

            });


            function done() {

                if(count != 2) return

                _movies.sort((a, b) => b.added - a.added)

                let ex_data = {
                    favorites: [],
                    continue_watched: []
                };

                _movies.forEach(item => {

                    if (!ex_data[item.category_id]) ex_data[item.category_id] = [];

                    item.id = item.stream_id;

                    let fav_index = Favorite.is_favorite(item.id, 'movies');

                    if (fav_index >= 0) {

                        ex_data.favorites[fav_index] = item;
                    }

                    let is_watched = ContinueWatch.is_watched(item.id, 'movies');

                    if (is_watched) {

                        let index = ContinueWatch.get_watched_index(item.id, 'movies');

                        ex_data.continue_watched[index] = item;
                    }

                    item.cover = item.stream_icon;
                    ex_data[item.category_id].push(item);

                })

                ex_data.all = _movies


                resolve(that.marge_data(ex_data, _categories, 'movies'));
            }

        })
    }

    async get_series_data() {

        let that = this;
        let count = 0;
        let _categories = [];
        let _series = [];

        return new Promise((resolve, reject) => {

            let catUrl = that.series_category();

            that.request(catUrl, (data) => {

                _categories = data;
                count++
                done();

            }, function (err) {

                reject(err)

            });


            let url = that.series()

            that.request(url, (data) => {
                    
                    _series = data;
                    count++
                    done();
    
                }, function (err) {

                    console.log('get movies error->', err);
    
                    reject(err)
    
                })


            function done(){

                if(count != 2) return
                    
                    let ex_data = {
                        favorites: [],
                        continue_watched: []
                    };
    
                    _series.forEach(item => {
                        if (!ex_data[item.category_id]) ex_data[item.category_id] = [];
    
                        item.id = item.series_id;
    
                        let fav_index = Favorite.is_favorite(item.id, 'series');
                        if (fav_index >= 0) {
                            ex_data.favorites[fav_index] = item;
                        }
                        let is_watched = ContinueWatch.is_watched(item.id, 'series');
                        if (is_watched) {
                            let index = ContinueWatch.get_watched_index(item.id, 'series');
                            ex_data.continue_watched[index] = item;
                        }
    
                        item.stream_type = 'series';
                        ex_data[item.category_id].push(item);
                    })

                    ex_data.all = _series
    
                    resolve(that.marge_data(ex_data, _categories, 'series'));
            }

        })      

    }
    async get_movie_info(id, is_series = false) {

        const url = is_series ? this.series_info(id) : this.movie_info(id);


        let that = this;

        const data = new Promise((resolve, reject) => {

            that.request(url, (data) => {

                resolve(data);
            }, function (err) {
                console.log('get movie info error->', err);
                reject({});
            });
        })

        let _info = await data;

        _info = this.parse_movie_info(_info, is_series, id);
        if (AppConfig.tmdb_api == 1) {
            _info = await this.get_tmdb_info(_info, is_series);
        }

        return _info;
    }
    parse_movie_info(data, is_series = false, id) {


        let sende_info = {};
        let release_genre_duration = '';
        let description = '';
        let extention = '';
        let cover = '';


        if (is_series) {
            release_genre_duration = get_release_date_genre_info(data.info.releaseDate, data.info.genre, data.info.episode_run_time * 60)
            description = data.info.plot
            cover = data.info.cover;
            sende_info.episodes = data.episodes;
            sende_info.seasons = Object.keys(data.episodes);
            sende_info.category_id = data.info.category_id;
        } else {
            release_genre_duration = get_release_date_genre_info(data.info.releasedate, data.info.genre, data.info.duration_secs)
            description = data.info.description || data.info.plot;
            extention = data.movie_data.container_extension;
            cover = data.info.movie_image
            sende_info.category_id = data.movie_data.category_id;
            sende_info.tmdb_id = data.info.tmdb_id || '';
        }

        let rating = data.info.rating || 0;
        rating = parseInt(rating);

        if (rating - data.info.rating > 0) {
            rating = `${rating}+`
        }

        // sended info
        sende_info.name = data.info.name || data.movie_data.name;
        sende_info.description = description;
        sende_info.genre = data.info.genre;
        sende_info.release_date = data.info.releasedate;
        sende_info.release_genre_duration = release_genre_duration;
        sende_info.rating = rating;
        sende_info.cover = cover;
        sende_info.backdrop_path = data.info.backdrop_path && data.info.backdrop_path[0] || '';
        sende_info.container_extension = extention;
        sende_info.id = id;
        sende_info.stream_type = is_series ? 'series' : 'movie';



        return sende_info
    }
    async get_tmdb_info(data, is_series = false) {

        const { name } = data;
        const that = this;
        let tmd_data = new Promise((resolve, reject) => {
            Tmdb.search({ name, is_series }, (tmdb_data) => {
                if (tmdb_data) {
                    tmdb_data = that.parse_tmdb_info(tmdb_data, is_series, data);

                    resolve(tmdb_data)
                } else {
                    resolve(data);
                }

            }, () => {
                resolve(data);

            })
        })

        let parsed_data = await tmd_data;

        return parsed_data;
    }
    parse_tmdb_info(data, is_series, xtream_data) {
        let sende_info = {};
        let release_genre_duration = '';

        let extention = '';
        let genres = ''

        data.genres.forEach(item => {
            genres += item.name + ', '
        })
        if (is_series) {

            let time = data.episode_run_time.length ? data.episode_run_time[0] : 0;
            release_genre_duration = get_release_date_genre_info(data.first_air_date, genres, time * 60)
            sende_info.episodes = xtream_data.episodes;
            sende_info.seasons = xtream_data.seasons;
        } else {
            release_genre_duration = get_release_date_genre_info(data.release_date, genres, (data.runtime * 60))
            extention = xtream_data.container_extension;

        }

        let rating = parseInt(data.vote_average);

        if (rating - data.vote_average != 0) {
            rating = `${rating}+`
        }

        // sended info
        sende_info.tmdb_id = data.id;
        sende_info.name = data.original_title || data.original_name;
        sende_info.category_id = xtream_data.category_id;
        sende_info.description = data.overview;
        sende_info.genre = genres;
        sende_info.release_date = data.release_date || data.first_air_date;
        sende_info.release_genre_duration = release_genre_duration;
        sende_info.rating = rating;
        sende_info.cover = data.poster_path.original;
        sende_info.backdrop_path = data.backdrop_path ? data.backdrop_path.original : '';
        sende_info.container_extension = extention;
        sende_info.id = xtream_data.id;

        sende_info.stream_type = is_series ? 'series' : 'movie';

        return sende_info
    }

    marge_data(data, categories, type) {

        const sended_data = {

            categories: [],

            data: {},

        };

        if (type == 'live') {
            categories.unshift({ category_id: 'recently_watched', category_image: imageResently, category_name: 'recently watched', parent_id: 0 });
        } else {
            categories.unshift({ category_id: 'continue_watched', category_image: imageResently, category_name: 'continue watched', parent_id: 0 });

        }

        categories.unshift({ category_id: 'all', category_image: iamgeAll, category_name: 'all', parent_id: 0 });
        categories.unshift({ category_id: 'favorites', category_image: imageFavorite, category_name: 'favorites', parent_id: 0 });
        categories.unshift({ category_id: 'search', category_image: imageSearch, category_name: 'search', parent_id: 0 });

        let dont_skeep = ['all', 'favorites', 'search', 'recently_watched', 'continue_watched'];

        let server = AppConfig.selected_server || {};

        let moved_categories = localStorage.getItem(`moved_data-${server.id}`);

        if (moved_categories) {

            moved_categories = JSON.parse(moved_categories)[type] || {};

        } else {

            moved_categories = {
                length: 0,
            };
        }

        categories.forEach((item, index) => {

            if (!data[item.category_id] && dont_skeep.indexOf(item.category_id) == -1) return
  
         
            
            item.items = data[item.category_id] || [];


            sended_data.data[item.category_name] = item;

            let position = moved_categories[item.category_id];

            if (position < 0 || position == undefined) {

                position = moved_categories.length
                
                if (!position) position = index;
            
            }

            sended_data.categories.push({
                id: item.category_id,
                name: item.category_name,
                position: position,
            });

        })

        sended_data.categories.sort((a, b) => {
            return a.position - b.position;
        })

        

        return sended_data;
    }


    sort_data(data, moved_data) {

        let keys = Object.keys(moved_data);

        if (!keys.length) return data;


        keys.forEach(key => {

            let positions = moved_data[key];

      
            
            data[key].forEach(item => {
                let position = positions[item.id];
                if (position < 0 || position == undefined) {
                    position = positions.length
                    if (!position) position = 0;
                }

                item.position = position;
            })

            data[key].sort((a, b) => a.position - b.position)

        })


        return data;
    }


    get_stream_url(data, m3u8 = false) {

        const { container_extension = 'mp4', id, stream_type } = data;

        if (stream_type == 'live') {
            return this.genereteLiveUrl(id, m3u8);

        } else if (stream_type == 'movie') {

            return this.genereteMovieUrl(id, container_extension);
        } else {

            return this.genereteSeriesUrl(id, container_extension);
        }
    }
    generateLoginUrl() {
        return this.host + "/player_api.php?username=" + this.username + "&password=" + this.password
    }
    generatePlaylistUrl() {
        return this.host + "/get.php?username=" + this.username + "&password=" + this.password + '&type=m3u_plus&output=mpegts'
    }
    genereteLiveUrl(id, m3u8) {

        if (m3u8)
            return this.host + '/live/' + this.username + '/' + this.password + '/' + id + '.m3u8';

        return this.host + "/" + this.username + "/" + this.password + "/" + id
    }
    getServerList() {
        return this.host + '/api.php?action=server&sub=list'
    }
    genereteMovieUrl(id, ext) {
        return this.host + "/movie/" + this.username + "/" + this.password + "/" + id + '.' + ext
    }
    genereteSeriesUrl(id, ext) {
        return this.host + "/series/" + this.username + "/" + this.password + "/" + id + '.' + ext
    }
    genereteUrl() {
        return this.host + "/player_api.php?username=" + this.username + "&password=" + this.password
    }
    getPlayerUrl(type, id, extension) {

        if (type == 'live') return this.host + '/' + this.username + '/' + this.password + '/' + id;
        return this.host + '/' + type + '/' + this.username + '/' + this.password + '/' + id + '.' + extension;
    }
    getm3u8url(id) {

        return this.host + '/live/' + this.username + '/' + this.password + '/' + id + '.m3u8';
    }
    channels(id) {

        if (!id) {
            return `${this.genereteUrl()}&action=get_live_streams`;
        }


        return this.genereteLiveUrl() + id;

    }
    getEpg(id) {
        return `${this.genereteUrl()}&action=get_simple_data_table&stream_id=${id}`
    }
    live_categories() {

        return `${this.genereteUrl()}&action=get_live_categories`;
    }
    movies() {

        return `${this.genereteUrl()}&action=get_vod_streams`;
    }
    movie_categories() {
        return `${this.genereteUrl()}&action=get_vod_categories`;
    }
    movie_info(id) {

        return `${this.genereteUrl()}&action=get_vod_info&vod_id=${id}`;

    }
    movies_stream({ id, ext }) {
        let url = this.genereteMovieUrl();

        url += id + "." + ext;

        return url;
    }
    series() {
        return `${this.genereteUrl()}&action=get_series`;
    }
    series_category() {
        return `${this.genereteUrl()}&action=get_series_categories`;
    }
    series_stream(id, ext) {
        return `${this.genereteSeriesUrl()}${id}.${ext}`;
    }
    series_info(id) {
        return `${this.genereteUrl()}&action=get_series_info&series_id=${id}`;
    }
    request(url, success, err) {

        let req = new XMLHttpRequest();

        req.timeout = 30000;

        req.onreadystatechange = function () {

         
            if (this.readyState == 4) {

                if (this.status >= 200 && this.status < 300) {

                    var result = true;
                    try {
                        var data = JSON.parse(this.responseText);

                    } catch (e) {
                        result = false
                    }
                    if (result)
                        success(data);
                    else {
                        if (data == 'aborted')
                            err(data)
                        else
                            err('incorrect playlist url')
                    }

                } else {
                    if (this.readyState == 0 || this.status == 0) {
                        err('aborted')
                    } else {
                        err('incorrect playlist url')

                    }

                }
            }

        }

        req.onloadstart =  () => {

            let type = this.checkType(url);
         
            let item = document.querySelector(`#spinner_${type}`);
         
            let percentItem = document.querySelector(`#percent_${type}`);
           
            if(item){
                item.classList.remove("smoll");
                item.style.backgroundImage = `url(${spinnerSVG})`
            }

            if(percentItem){
                percentItem.innerHTML = '0';
            }
        }

        req.onprogress =  (e) =>{
            
            this.checkAndShowProgress(url,e);
        }

        req.onloadend =  ()=> {
            let type = this.checkType(url);

            let item = document.querySelector(`#spinner_${type}`);

            if(item){
                item.classList.add("smoll")
                item.style.backgroundImage = `url(${checked})`

            } 
        }

        req.onerror = function (e) {
            if (err) err("incorrect playlist url");
        }

        req.ontimeout = function (e) {
            if (err) err("incorrect playlist url");
        }

        req.open("get", url);

        req.send();

        req.onabort = function () {
            if (err) err('aborted')
        }


        
        controller.push(req)
    }


    checkAndShowProgress(url, e) {

        let type = this.checkType(url);
        
        if(!type) return ;

        let parent = document.getElementById('loading_content_view');

        if (!parent) return;


        let percent = 0;

        if(e.total){
         
            percent = `${Math.round((e.loaded / e.total) * 100)}%`;
        
        }else{
            // parse byte to kb
            percent = `${(Math.round(e.loaded / 1024) )}kb`;
        }

        let item = parent.querySelector(`#percent_${type}`);

        item.innerHTML = percent;
        

    }


    checkType(url) {

        if(url.indexOf("get_live_categories") > -1)  return 'live_categories';

        if(url.indexOf("get_live_streams") > -1)  return 'live_streams';

        if(url.indexOf("get_vod_categories") > -1)  return 'vod_categories';

        if(url.indexOf("get_vod_streams") > -1)  return 'vod_streams';

        if(url.indexOf("get_series_categories") > -1)  return 'series_categories';

        if(url.indexOf("get_series") > -1)  return 'series';

    }

    abort() {

        controller.forEach(req => {

            req.abort();
        })

        controller = [];
    }
}


export default Xtream;

