import { set_appconfig_status, xtream_instance } from "..";
import AppConfig from "../AppConfig";
import CategoryItem from "../Components/CategoryItem";
import PopUp from "../Components/PopUp/PopUp";
import { ChangePage, Control } from "../Remote/Pages";
import { get_categories, get_selected_playlist_content } from "../Utils/Content";
import Requests from "../Utils/Requests";
import { append, el, get_el, get_els, get_index, item_on_whell, remove_class } from "../Utils/Utils";
import get_word, { translate } from "../Utils/Words";

import './Settings.scss';

export default class Settings {
    constructor () {
        Settings.selected_category = Settings.categories[0].category_id;
        Settings.local_data = Settings.get_local_data();
    }
    static categories = [
        {
            category_name: 'application info',
            word: 'application info',
            category_id: 'application_info',
            category_image: require('./images/account.png'),
        },
        {
            category_name: 'settings',
            word: 'settings',
            category_id: 'settings',
            category_image: require('./images/settings.png'),
        },
        {
            category_name: 'servers',
            word: 'servers',
            category_id: 'servers',
            category_image: require('./images/servers.png'),
        }
    ]
    static selected_category
    static local_data
    static root = document.getElementById('root')
    static parental_code_item_index = 0
    init() {

        this.render();
    }
    render() {
        const settings_page = el({
            attr: {
                class: 'settings-page-parent',
                id: 'settings_page_parent'
            },
            children: [
                {
                    attr: {
                        class: 'categories-block',
                        id: 'categories_block',
                        onwheel: item_on_whell
                    },
                    render: (parent) => {
                        Settings.categories.forEach((item, index) => {
                            let cat_item = new CategoryItem({
                                data: item,
                                index,
                                callback: Settings.change_category,
                                is_selected: item.category_id == Settings.selected_category,
                                type: 'settings',
                            })

                            let element = cat_item.init()

                            let cat_name = element.querySelector('.category-item-name');
                            cat_name.setAttribute('word', item.word)
                            cat_name.addClass('translate')
                            append(parent, element)
                        })
                    }
                },
                {
                    attr: {
                        class: 'selected-settings-block',
                        id: 'selected_settings_block'
                    },
                    children: [
                        {
                            attr: {
                                class: 'selected-settings-block-content',
                                id: 'selected_settings_block_content'
                            },
                            render: (parent) => {
                                append(parent, Settings.render_application_info());
                            }
                        }
                    ]

                }
            ]
        })


        append(Settings.root, settings_page)
    }

    static change_category(that) {

        let cat_id = that.getAttribute('cat_id');

        if (cat_id == Settings.selected_category) return;

        Settings.selected_category = cat_id;

        Settings.render_selected_category();
    }
    static render_selected_category() {

        let parent = get_el('#selected_settings_block_content');

        parent.innerHTML = ''

        let item;
        switch (Settings.selected_category) {
            case 'application_info':
                item = Settings.render_application_info();
                break;
            case 'settings':
                item = Settings.render_settings();
                break;
            case 'servers':
                item = Settings.render_servers();
                break;
        }


        parent.appendChild(item)

        if (Settings.selected_category != 'application_info') {
            if (!Control[Settings.selected_category].items.length) return;
            Control[Settings.selected_category].index = 0;
            Control.set_current(Settings.selected_category)
        }

    }
    static render_application_info() {


        return el({
            attr: {
                class: 'application-info-block',
                id: 'application_info_block'
            },
            children: [
                {
                    attr: {
                        class: 'application-info-title',
                        id: 'application_info_title'
                    },
                    innerHTML: get_word('application info')
                },
                {
                    attr: {
                        class: 'application-info-content',
                    },
                    render: (parent) => {
                        [
                            {
                                name: get_word('mac address'),
                                value: localStorage.getItem('mac') || '',
                            },
                            {
                                name: get_word('app version'),
                                value: AppConfig.app_version,
                            }
                        ].forEach(item => {
                            append(parent, el({
                                attr: {
                                    class: 'application-info-item',
                                },
                                children: [
                                    {
                                        attr: {
                                            class: 'application-info-item-name',
                                        },
                                        innerHTML: `${get_word(item.name)}:`
                                    },
                                    {
                                        attr: {
                                            class: 'application-info-item-value',
                                        },
                                        innerHTML: item.value
                                    }
                                ]
                            }))
                        })
                    }
                }
            ]
        })
    }

    static render_settings() {

        // settings block items 
        let items = [
            {
                name: 'use xtream code epg',

                config: 'xtream_epg',
                type: 'switch',
            },
            {
                name: 'use tmdb api',
                config: 'tmdb_api',
                type: 'switch',
            },
            {
                name: 'remove subtitle background',
                config: 'remove_subtitle_background',
                type: 'switch',
            },
            {
                name: 'change language',
                config: 'change_language',
                type: 'popup'
            },
            {
                name: 'change parental code',
                config: 'change_parental_code',
                type: 'popup'
            },
            {
                name: 'lock categories',
                config: 'lock_categories',
                type: 'popup'
            },
            {
                name: 'hide categories',
                config: 'hide_categories',
                type: 'popup'
            }
        ];
        let settings_item_block = el({
            attr: {
                class: 'settings-items-block',
                id: 'settings_items_block'
            },
            render: (parent) => {
                items.forEach(item => {
                    let settings_item = el({
                        attr: {
                            class: 'settings-item settings-item-control',
                            id: `settings_item-${item.config}`,
                            type: item.type,
                            config: item.config,
                            onclick: Settings.settings_item_click,
                            onmouseenter: function () {
                                Settings.items_mouse_enter('settings', this)
                            },
                        },
                        children: [
                            {
                                attr: {
                                    class: 'settings-item-name translate',
                                    word: item.name,
                                },
                                innerHTML: get_word(item.name)
                            },
                            {
                                attr: {
                                    class: 'settings-item-value',
                                },
                                render: (parent) => {
                                    if (item.type == 'switch') {

                                        let is_enable = Settings.local_data[item.config] == 1 ? 'enabled' : '';
                                        append(parent, el({
                                            attr: {
                                                class: `settings-item-slider ${is_enable}`,
                                                id: `settings_item_slider-${item.name}`
                                            },
                                        }))
                                    }
                                }
                            }
                        ]
                    })

                    append(parent, settings_item)
                })
            }
        })


        return settings_item_block;

    }
    static settings_item_click() {
        let type = this.getAttribute('type');

        if (type == 'switch') {
            Settings.settings_item_switch_click(this)
        } else {
            Settings.settings_item_popup_click(this)
        }
    }
    static settings_item_switch_click(that) {
        let config = that.getAttribute('config')

        that.querySelector('.settings-item-slider').classList.toggle('enabled');

        Settings.set_local_data(config, Settings.local_data[config] == 1 ? 0 : 1)

    }
    static settings_item_popup_click(that) {

        let config = that.getAttribute('config');


        switch (config) {
            case 'change_language':
                Settings.render_language_popup();
                Control.set_current('change_language');
                break;
            case 'change_parental_code':
                ChangePage('ParentalCode', { type: 'change' })
                break;
            case 'lock_categories':
                ChangePage('ParentalCode', {
                    type: 'check', callback: () => {
                        ChangePage('LockCategories', {})
                    }
                })
                break;
            case 'hide_categories':
                ChangePage('ParentalCode', {
                    type: 'check', callback: () => {
                        ChangePage('LockCategories', { type: 'hid' })
                    }
                })
                break;

        }




    }


    static render_language_popup() {
        let languages = [
            {
                name: 'English',
                code: 'en',
            },
            {
                name: 'العربية',
                code: 'ar',
            },
            {
                name: 'Français',
                code: 'fr',
            },
            {
                name: 'Deutsch',
                code: 'de',
            },
            {
                name: 'Español',
                code: 'es',
            }
        ]

        const language_popup = el({
            attr: {
                class: 'language-popup',
                id: 'language_popup',
                style: `background-image: url(${AppConfig.background})`
            },
            children: [
                {
                    attr: {
                        class: 'language-popup-title translate',
                        word: 'choose language',
                    },
                    innerHTML: get_word('choose language')
                },
                {
                    attr: {
                        class: 'language-popup-items',
                        id: 'language_popup_items'
                    },
                    render: (parent) => {
                        languages.forEach((item, index) => {
                            append(parent, Settings.render_language_item(item, index))
                        })
                    }
                }
            ]

        })


        append(document.body, language_popup)
    }
    static render_language_item(item, index) {

        let selected = item.code == AppConfig.language ? 'selected' : '';

        return el({

            attr: {

                class: `language-item language-item-control ${selected}`,

                code: item.code,

                onclick: Settings.change_language,

                onmouseenter: function () {

                    Settings.items_mouse_enter('change_language', this)

                }
            },

            children: [
                {
                    tagName: 'img',
                    attr: {
                        class: 'language-item-icon',
                        src: require(`./images/flags/${item.code}.png`),
                    }
                },
                {
                    attr: {
                        class: 'language-item-name',
                    },
                    innerHTML: item.name
                }
            ]
        })
    }
    static change_language() {
        let code = this.getAttribute('code');

        remove_class('selected', get_el('#language_popup'))
        this.addClass('selected');

        localStorage.setItem('lang', code);
        AppConfig.language = code;

        translate();
    }
    static change_language_back() {
        Control.set_previous();
        let parent = get_el('#language_popup');
        if (parent) parent.remove();

    }



    static render_servers() {

        let data = AppConfig.servers;

        if (!data.length) {
            return Settings.render_no_servers();
        } else {

            return Settings.render_servers_list(data);
        }
    }
    static render_servers_list(data) {

        return el({
            attr: {
                class: 'servers-list',
                id: 'servers_list'
            },
            children: [
                {
                    attr: {
                        class: 'server-list-title'
                    },
                    innerHTML: get_word('choose server')
                },
                {
                    attr: {
                        class: 'server-list-items',
                        id: 'server_list_items',
                        onwheel: item_on_whell,
                    },
                    render: (parent) => {
                        let count = data.length > 6 ? 6 : data.length;
                        for (let i = 0; i < count; i++) {
                            let item = data[i];
                            append(parent, Settings.render_server_item(item, i))
                        }

                    }
                }
            ]

        })
    }
    static re_render_server_item({ index, direction }) {

        let item = Settings.render_server_item(AppConfig.servers[index], index);

        let parent = get_el('#server_list_items');
        if (direction == 'up') {
            parent.insertBefore(item, parent.firstChild)
            parent.lastChild.remove();
        } else {
            parent.appendChild(item);
            parent.firstChild.remove();
        }
    }
    static render_server_item(item, index) {

        let is_selected = item.id == AppConfig.selected_server.id


        return el({
            attr: {
                class: `server-item settings-item-control ${is_selected ? 'selected' : ''}`,
                server_id: item.id,
                index: index,
                onclick: Settings.server_item_click,
                onmouseenter: function () {
                    Settings.items_mouse_enter('servers', this)
                },
            },
            children: [
                {
                    attr: {
                        class: 'server-item-delete',
                    },
                    innerHTML: get_word('delete')
                },
                {
                    attr: {
                        class: 'server-item-name',
                    },
                    innerHTML: item.server.name,
                }
            ]
        })

    }

    static server_item_click() {


        let _id = this.getAttribute('server_id');

        if (_id == AppConfig.selected_server.id) return;

        Control.set_current('on_hold');

        this.addClass('loading');

        remove_class('error')

        let selected = AppConfig.servers.find(item => item.id == _id);

        const { server: { host, name }, username, password, id, device_id, server_id } = selected;


        xtream_instance.set_host({
            host: host,
            username: username,
            password: password
        });

        let that = this;

        let before = JSON.parse(JSON.stringify(AppConfig.selected_server));

        xtream_instance.init(async () => {

            localStorage.setItem('selected_playlist', JSON.stringify({
                username: username,
                password: password,
                host: host,
                id: id,
                device_id: device_id,
                server_id: server_id,
                server_name: name
            }));

            AppConfig.selected_server = JSON.parse(localStorage.getItem('selected_playlist'));

         try{
            set_appconfig_status();

            await get_categories(server_id);

            let content = await get_selected_playlist_content();

            
            if (!content) return;

         
            remove_class('selected', get_el('#server_list_items'));
            that.addClass('selected');
    
        }catch(e){

            that.addClass('error');

            xtream_instance.set_host({
                host: before.host,
                username: before.username,
                password: before.password
            })

            AppConfig.selected_server = before;
        }

         that.removeClass('loading');
            Control.set_previous();

        }, (err) => {
            Control.set_previous();
            that.removeClass('loading');
            that.addClass('error');
            xtream_instance.set_host({
                host: before.host,
                username: before.username,
                password: before.password
            })
            console.log(err);
        });



    }

    static items_mouse_enter(type, that) {

        let index = get_index(that);

        Control[type].index = index;

        Control.set_current(type)

    }


    static render_no_servers() {

        return el({
            attr: {
                class: 'no-servers',
            },
            innerHTML: get_word("you don't have servers")

        })
    }

    static show_remove_server_popup(server_id) {
        const remove_server_popup = new PopUp({
            message: get_word('do you want to remove this server'),
            callback: function () {
                Settings.remove_server({
                    type: this.getAttribute('type'),
                    id: server_id,
                })
            },
            class_name: 'backgr-09',
        })

        remove_server_popup.init();

        Control.set_current('remove_server');
    }
    static remove_server({ type, id }) {


        if (type == 'no') {
            done()
            return
        }

        let url = Requests.remove_playlist();
        Requests.delete({
            url: url,
            data: { id }
        }, function (data) {

            if (AppConfig.selected_server.id != 'demo' && AppConfig.selected_server.id == id) {
                location.reload()
                return
            }
            AppConfig.servers = AppConfig.servers.filter(item => item.id != id)
            done();

            Settings.render_selected_category()
        }, function (error) {
            console.error('remove playlist error -->>', error);
            done();
        })

        function done() {
            Settings.hide_remove_server_popup();
        }

    }
    static hide_remove_server_popup() {
        console.error('hide_remove_server_popup')
        Control.set_previous();
        let item = get_el('#popup_parent')
        if (item)
            item.remove();
    }
    static set_local_data(config, value) {

        Settings.local_data[config] = value;
        AppConfig[config] = value;
        localStorage.setItem(config, value);
    }
    static get_local_data() {


        return {
            xtream_epg: AppConfig.xtream_epg,
            tmdb_api: AppConfig.tmdb_api,
            remove_subtitle_background: AppConfig.remove_subtitle_background,
        }
    }
    static on_hold_back() {
        Control.set_previous();
        xtream_instance.abort();

        let items = get_els('.settings-item-control')

        for (let i = 0; i < items.length; i++) {
            items[i].removeClass('loading')
        }

    }
    static destroy() {
        Settings.selected_category = Settings.categories[0].category_id
    }
}