
import { get_el, remove_class } from "../Utils/Utils";
import { move } from "../Remote/Keys";
import LockCategories from "./LockCategories";



const Control = {
    current: "lock_category",
    previous: "",
    set_current: function (current) {

        if (this.current != current)
            this.previous = this.current;

        this.current = current;

        move();
    },
    set_previous: function () {
        this.current = this.previous;

        move();
    },
    lock_category: {
        index: 0,
        row: 0,
        data_type: '',
        items: document.getElementsByClassName('lock-categories-control'),
        left: function () {

            if (this.row == 0) return

            this.row--;
            this.move();
        },
        right: function () {
            if (this.row == this.items.length - 1) return

            this.row++;
            this.move();
        },
        up: function () {

            let item_index = this.items[this.row].children[0].getAttribute('index');

            if (this.index == 0 && item_index != 0) {

                LockCategories.re_render_item({

                    data_type: this.data_type,

                    index: +item_index - 1,

                    direction: 'up'

                })

            } else if (this.index == 0) {

                return

            } else {

                this.index--

            }

            this.move();
        },
        down: function () {

            let elements = this.items[this.row].children;

            let item_index = elements[elements.length - 1].getAttribute('index');

            if (this.index == elements.length - 1 && item_index != LockCategories.data[this.data_type].length - 1) {

                LockCategories.re_render_item({

                    data_type: this.data_type,

                    index: +item_index + 1,

                    direction: 'down'

                })

            } else if (this.index == elements.length - 1) {

                return

            } else {

                this.index++

            }

            this.move();

        },

        ok: function () {

            this.items[this.row].children[this.index].click();

        },

        back: function () {

            LockCategories.lock_category_back();

        },

        move: function () {

            remove_class('active');


            this.data_type = this.items[this.row].getAttribute('data_type');

            if (this.index > this.items[this.row].children.length - 1) this.index = this.items[this.row].children.length - 1;

            this.items[this.row].children[this.index].addClass('active');
        }
    },

    on_hold: {
        move: function () {
        }
    }
}


export default Control;