import { append, el } from "../../Utils/Utils";
import get_word from "../../Utils/Words";




export default class PopUp {
    constructor ({ message = '', callback = () => { }, current_time = 0, class_name = '' }) {
        this.callback = callback;
        this.message = message;
        this.current_time = current_time;
        this.class_name = class_name;
    }

    init() {

        const popup = el({
            attr: {
                class: `popup-parent ${this.class_name}`,
                id: 'popup_parent'
            },
            children: [
                {
                    attr: {
                        class: 'popup-content'
                    },
                    children: [
                        {
                            attr: {
                                class: 'popup-title'
                            },
                            innerHTML: this.message
                        },
                        {
                            attr: {
                                class: 'popup-btns'
                            },
                            children: [
                                {
                                    attr: {
                                        class: 'popup-btn popup-btn-control',
                                        current_time: this.current_time,
                                        type: 'yes',
                                        onclick: this.callback
                                    },
                                    innerHTML: get_word('yes')
                                },
                                {
                                    attr: {
                                        class: 'popup-btn popup-btn-control',
                                        current_time: 0,
                                        type: 'no',
                                        onclick: this.callback
                                    },
                                    innerHTML: get_word('no')
                                }
                            ]
                        }
                    ]
                }
            ]
        })

        append(document.body, popup)
    }
}