

import { remove_class } from "../Utils/Utils";
import { move } from "../Remote/Keys";
import Movies from "./Movies";
import pages, { ChangeControl, ChangePage } from "../Remote/Pages";
import LockCategories from "../LockCategories/LockCategories";


const Control = {
    current: "categories",
    previous: "",
    set_current: function (current) {

        if (this.current != current && this.current != 'on_hold')
            this.previous = this.current;

        this.current = current;

        move();
    },

    set_previous: function () {

        this.current = this.previous;

        move();
    },

    categories: {

        index: 0,

        items: document.getElementsByClassName('category-item-control'),

        green: function () {

            Movies.move_category(this.items[this.index])

        },

        blue: function () {
            // lock 

            let item_index = this.items[this.index].getAttribute('index');

            if (item_index < 4) return

            const that = this;

            const type = Movies.is_series ? 'series' : 'movies';

            ChangePage('ParentalCode', {

                callback: () => {

                    new LockCategories({ type: 'lock' });

                    LockCategories.item_click({

                        item: that.items[that.index],

                        index: that.items[that.index].getAttribute('index') - 4,

                        data_type: type,

                        type: 'lock'
                    })

                }
            })
        },

        up: function () {

            let item_index = this.items[0].getAttribute('index');

            if (this.index == 0 && item_index != 0) {

                Movies.re_render_category_item({

                    index: +item_index - 1,

                    direction: 'up'

                })

            } else if (this.index == 0) {

                return

            } else {

                this.index--;

            }

            this.move();
        },

        down: function () {

            let item_index = this.items[this.items.length - 1].getAttribute('index');

            if (this.index == this.items.length - 1 && item_index != Movies.data.categories.length - 1) {

                Movies.re_render_category_item({

                    index: +item_index + 1,

                    direction: 'down'

                })
            }
            else if (this.index == this.items.length - 1) {

                return
            }
            else {

                this.index++;
            }

            this.move();
        },

        right: function () {

            if (Control.movies.items.length)
                Control.set_current('movies')

        },
        ok: function () {

            Control.movies.row = 0;

            Control.movies.index = 0;

            this.items[this.index].click();

        },
        back: function () {

            ChangePage('Menu', {}, true)

        },
        move: function () {

            remove_class('active');

            this.items[this.index].addClass('active');

            Movies.colored_btns_for_category(this.index)
        },
    },

    movies: {

        row: 0,

        index: 0,

        items: document.getElementsByClassName('movies-row-control'),

        yellow: function () {

            Movies.add_remove_favorite(this.items[this.row].childNodes[this.index]);

        },

        up: function () {

            let item_index = this.items[0].getAttribute('index');

            if (this.row == 0 && item_index != 0) {

                Movies.re_render_movie_row({

                    index: +item_index - 1,

                    direction: 'up'

                })

            } else if (this.row == 0) {

                return

            } else {

                this.row--;

            }

            this.move();
        },
        down: function () {

            let item_index = this.items[this.items.length - 1].getAttribute('index');

            let row_count = Movies.selected_category_row_count - 1;

            if (this.row == this.items.length - 1 && item_index != row_count) {

                Movies.re_render_movie_row({

                    index: +item_index + 1,

                    direction: 'down'

                })

            } else if (this.row == this.items.length - 1) {

                return

            } else {

                this.row++;

            }

            this.move();
        },
        left: function () {

            if (this.index == 0) {

                if (pages.current == 'Search') {

                    ChangeControl('Search');

                    move();

                } else {

                    Control.set_current('categories');

                }

                return
            }

            this.index--;

            this.move();

        },

        right: function () {

            if (this.index == this.items[this.row].children.length - 1) return;

            this.index++;

            this.move();

        },

        ok: function () {

            this.items[this.row].children[this.index].click();

        },

        back: function () {

            ChangePage('Menu', {}, true)

        },

        move: function () {

            remove_class('active');

            if (this.index > this.items[this.row].children.length - 1) this.index = this.items[this.row].children.length - 1;

            this.items[this.row].children[this.index].addClass('active');

            Movies.colored_btns_for_movies()

        },

    },
    movie_info: {
        back: function () {
            Movies.hide_movie_info();
        }
    },
    on_hold: {
        back: function () {
            Control.set_previous();
            Movies.on_hold_back();
        },
    }


}

export default Control;