
import { el } from '../../Utils/Utils';
import errorImage from '@/src/images/logo.png'


const ImageResizer = async (props) => {

    let { id = '' } = props;

    if (id && !document.getElementById(id)) return;

    let { src, error = '', className = '' } = await imageLoader(props);

    if (error) {
        return el({
            tagName: 'img',
            attr: {
                class: 'error-image',
                src: errorImage,
            }
        })
    }

    return el({
        tagName: 'img',
        attr: {
            src: src,
            class: className,
        }
    })

}



async function imageLoader(props) {
    const { src, width, height, type = 'image/jpeg' } = props;


    return new Promise((resolve, reject) => {
        let img = new Image();
        img.src = src;
        img.crossOrigin = "anonymous";
        img.onload = () => {
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);
            let dataURL = canvas.toDataURL(type, 0.5);
            resolve({ src: dataURL });
        }
        img.onerror = (err) => {

            resolve({ src: errorImage, error: true });
        }
    })


}
export default ImageResizer;


