import { controller } from "../../Utils/Xtream";



export default class OpenSubtitle {
    constructor () {
        this.api_url = 'https://api.opensubtitles.com/api/v1/';
        this.api_key = 'XGvqL8afeCgDyrU9HR513zp8fvzxx50A';
        this.token = null;
        this.username = 'qeshishyan';
        this.password = 'Akmli6964';
        this.requests = [];
        this.timeout = null;
        this.x_user_agent = '4K_Matic v1.0.0';
        this.tmdb_id = '';
    }

    init(tmdb_id) {
        this.tmdb_id = tmdb_id;
    }

    login() {
        let data = JSON.stringify({
            "username": this.username,
            "password": this.password
        });

        this.request({
            type: 'login',
            method: 'POST',
            data
        }, (response) => {
            this.token = response.token;
        }, (error) => {
            console.log('subtitle error', error);
        });
    }
    search({ data, cb, error }) {
        let url = "subtitles?";

        let params = [];
        for (var key in data) {
            params.push(key + "=" + data[key]);
        }

        params.sort((a, b) => a > b ? 1 : -1)
        params = params.join("&");
        url += params;
        this.request({
            type: url,
            method: 'GET',
            data: {}
        }, (response) => {
            cb && cb(response);
        }, () => {
            if (error) error();
        });
    }
    download(file_id, cb, error) {
        let data = JSON.stringify({ "file_id": file_id, "type": "srt" });
        const that = this;
        this.request({
            type: 'download',
            method: 'POST',
            data
        }, (response) => {
            let xhr = new XMLHttpRequest();
            xhr.open('GET', response.link);
            xhr.send();
            xhr.onload = function () {
                let parsed_data = that.parse(xhr.responseText);
                cb && cb(parsed_data);
            };
            xhr.onerror = function () {
                if (error) error();
                console.log("Error", xhr.statusText);
            };

        }, () => {
            if (error) error();
        });
    }

    parse(str) {
        let lines = str.split('\n');

        let parsed = {};

        let tmp = null;

        for (let i = 0; i < lines.length; i++) {
            let line = lines[i];

            line = line.trim()

            if (line == '') {
                tmp = null;
                continue;
            }

            if (line.indexOf('-->') != -1) {
                let times = line.split("-->");

                let start = times[0].trim().split(",")[0];
                let end = times[1].trim().split(",")[0];

                let start_secund = this.getSubtitleTime(start);
                let end_secund = this.getSubtitleTime(end);

                tmp = { time: end_secund - start_secund, text: "" };

                parsed[start_secund] = tmp;
            } else if (tmp) {
                tmp.text += line + "\n";
            }
        }

        return parsed;
    }

    getSubtitleTime(str) {
        let arr = str.split(":");

        let secunds = 0;

        secunds += parseInt(arr[0]) * 3600;
        secunds += parseInt(arr[1]) * 60;
        secunds += parseInt(arr[2]);

        return secunds;
    }



    request({ type, method, data }, sucess, error) {

        clearImmediate(this.timeout)

        let xhr = new XMLHttpRequest();

        const that = this;
        xhr.onload = function () {

            try {
                sucess(JSON.parse(this.responseText));
            } catch (e) {
                console.log('catch error ', e);
                that.timeout = setTimeout(() => {
                    that.request({ type, method, data }, sucess);
                }, 500);

            }
        };

        xhr.onerror = function () {
            if (error) error();
            console.log("Error", xhr.statusText);
        };

        xhr.ontimeout = function () {
            if (error) error();
            console.log('timeout', error);
        }
        xhr.open(method, this.api_url + type);
        xhr.setRequestHeader("Api-Key", this.api_key);
        xhr.setRequestHeader("X-User-Agent", this.x_user_agent);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.setRequestHeader("Accept", "application/json");

        if (this.token) xhr.setRequestHeader("Authorization", "Bearer " + this.token);

        xhr.send(data);

        this.requests.push(xhr);
        controller.push(xhr)
    }
    abort() {
        clearTimeout(this.timeout);

        this.requests.forEach(request => {
            request.abort();
        });

        this.requests = [];
    }
}