import AppConfig from "../AppConfig";
import Favorite, { favoriteData } from "../Components/Favorite/Favorite";
import Live from "../Live/Live";
import pages, { ChangePage } from "../Remote/Pages";
import { content } from "../Utils/Content";
import { get_el } from "../Utils/Utils";




export default class MoveChannels {

    constructor ({ item, selected_category }) {

        MoveChannels.item = item;
        MoveChannels.moved_data = localStorage.getItem(`moved_channels-${AppConfig.selected_server.id}`) || '{}';
        MoveChannels.moved_data = JSON.parse(MoveChannels.moved_data);
        MoveChannels.index = +MoveChannels.item.getAttribute('index');
        MoveChannels.selected_category = selected_category;
    }

    static data_type = 'live';
    static selected_category;
    static move_type;
    static item;
    static category;
    static moved_data;
    static index;
    static move_mode = false;
    static rendered_items = document.getElementsByClassName('live-item-control');
    init() {


        MoveChannels.move_mode = true;
        MoveChannels.data = content[MoveChannels.data_type].data[MoveChannels.selected_category];
        MoveChannels.selected_category = MoveChannels.data.category_id;

        MoveChannels.data = MoveChannels.data.items;


        if (!MoveChannels.moved_data[MoveChannels.selected_category]) {
            MoveChannels.moved_data[MoveChannels.selected_category] = {}

        }

        MoveChannels.item.addClass('movable-item');
    }

    static move_up(decrement) {
        if (MoveChannels.index == 0) return;
        MoveChannels.index += decrement

        let prev_sub_item = MoveChannels.item.previousElementSibling;


        if (!prev_sub_item) {
            MoveChannels.render_item({
                index: +MoveChannels.item.getAttribute('index') - 1,
                direction: 'up'
            })

            prev_sub_item = MoveChannels.item.previousElementSibling;
        }


        let index = MoveChannels.item.getAttribute('index');
        let next_index = +index - 1;

        MoveChannels.change_data({ index, next_index })

        MoveChannels.item.setAttribute('index', next_index);
        prev_sub_item.setAttribute('index', index);


        MoveChannels.item.insertAdjacentElement('afterend', prev_sub_item);

        MoveChannels.change_number(prev_sub_item);

    }
    static move_down(increment) {
        if (MoveChannels.index == MoveChannels.data.length - 1) return;
        MoveChannels.index += increment


        let next_sub_item = MoveChannels.item.nextElementSibling;

        if (!next_sub_item) {

            MoveChannels.render_item({
                index: +MoveChannels.item.getAttribute('index') + 1,
                direction: 'down'
            })

            next_sub_item = MoveChannels.item.nextElementSibling;
        }

        let index = MoveChannels.item.getAttribute('index');


        let next_index = +index + 1;

        MoveChannels.change_data({ index, next_index })

        MoveChannels.item.setAttribute('index', next_index);
        next_sub_item.setAttribute('index', index);



        MoveChannels.item.insertAdjacentElement('beforebegin', next_sub_item);

        MoveChannels.change_number(next_sub_item);

    }

    static change_number(next_item) {

        let channel_number = MoveChannels.item.querySelector('.live-item-number');
        let next_channel_number = next_item.querySelector('.live-item-number');

        let current_number = channel_number.innerHTML;
        channel_number.innerHTML = next_channel_number.innerHTML;
        next_channel_number.innerHTML = current_number;

        if (MoveChannels.item.className.indexOf('selected') != -1) {
            let selected_channel_number = get_el('#selected_channel_number');
            let live_player_channel_number = get_el('#live_player_channel_number');
            if (selected_channel_number) selected_channel_number.innerHTML = channel_number.innerHTML;
            if (live_player_channel_number) live_player_channel_number.innerHTML = channel_number.innerHTML;

        }
    }
    static render_item({ index, direction }) {

        if (direction == 'up' && MoveChannels.index == 4) return;
        if (direction == 'down' && MoveChannels.index == MoveChannels.data.length) return;

        Live.re_render_channel_item({
            index: index,
            direction,
        })

    }


    static change_data({ index, next_index }) {

        let data = MoveChannels.data[index];
        let current_position = data.position;

        let next_data = MoveChannels.data[next_index];
        let next_position = next_data.position;

        MoveChannels.data[index] = next_data;
        MoveChannels.data[index].position = current_position;

        MoveChannels.data[next_index] = data;
        MoveChannels.data[next_index].position = next_position;

        if (!MoveChannels.moved_data[MoveChannels.selected_category]) MoveChannels.moved_data[MoveChannels.selected_category] = {};



        MoveChannels.set_moved_data();
    }

    static set_moved_data() {
        MoveChannels.moved_data[MoveChannels.selected_category] = {};


        MoveChannels.data.forEach((item, index) => {
            MoveChannels.moved_data[MoveChannels.selected_category][item.id] = index;
        });
        MoveChannels.moved_data[MoveChannels.selected_category].length = Object.keys(MoveChannels.moved_data[MoveChannels.selected_category]).length;

        localStorage.setItem(`moved_channels-${AppConfig.selected_server.id}`, JSON.stringify(MoveChannels.moved_data))
    }

    static close_move() {


        let data = {
            control_type: 'channels',
            index: 0
        };

        for (let i = 0; i < MoveChannels.rendered_items.length; i++) {
            if (MoveChannels.rendered_items[i] == MoveChannels.item) {
                data.index = i;
                break;
            }
        }

        MoveChannels.item.removeClass('movable-item');

        if (MoveChannels.selected_category == 'favorites') {

            MoveChannels.set_favorite_local_storage();

        } else {

            MoveChannels.set_moved_data();

        }



        MoveChannels.move_mode = false;
        ChangePage(pages.previous, { control: data }, true, false)
    }


    static set_favorite_local_storage() {

        let data_ids = Live.data.data[MoveChannels.selected_category].items.map(item => item.id);

        Favorite.set({ data: data_ids, type: 'live' });

    }

}