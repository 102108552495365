
import { remove_class } from "../Utils/Utils";
import { move } from "../Remote/Keys";
import ParentalCode from "./ParentalCode";


const Control = {
    current: "parental_code",
    previous: "",
    set_current: function (current) {

        if (this.current != current)
            this.previous = this.current;

        this.current = current;

        move();
    },
    set_previous: function () {
        this.current = this.previous;

        move();
    },
    parental_code: {
        index: 0,
        items: document.getElementsByClassName('parental-code-popup-keyboard-item-control'),
        left: function () {

            if (this.index == 0) {
                this.index = this.items.length - 1;
            } else {
                this.index--;
            }


            this.move();
        },
        right: function () {
            if (this.index == this.items.length - 1) {
                this.index = 0;
            } else {
                this.index++;
            }


            this.move();
        },
        ok: function () {
            this.items[this.index].click();
        },
        back: function () {
            ParentalCode.parental_code_back();
        },
        move: function () {
            remove_class('active');
            this.items[this.index].addClass('active');
        }
    },
    on_hold: {
        move: function () {
        }
    }
}


export default Control;