import { append, el, el_tag, get_el } from '../Utils/Utils';
import AvPlayer from './Avplayer';
import NativePlayer from './Nativplayer';
import { OS } from '../Utils/Os';
import './Player.scss';
import get_word from '../Utils/Words';
import RecentlyWatched from '../Components/RecentlyWatched/RecentlyWatched';
import ContinueWatch from '../Components/ContinueWatching/ContinueWatching';
import { MovieInfo } from '../MovieInfo/MovieInfo';
import Movies from '../Movies/Movies';
import Live from '../Live/Live';
import PlayerSettings from '../Components/PlayerSettings/PlayerSettings';
import Control from './PlayerControl';
import AppConfig from '../AppConfig';
import { ChangePage } from '../Remote/Pages';

class Player {
    static played_data = null;
    static player_state = 'pause';
    static player_instance = null;
    static is_player_init = false;
    static player_type = '';
    static player_item = null;
    static is_show_player = false;
    static show_controle_timeout = null;
    static duration = null;
    static current_time = null;
    static state_after_seek = null
    static change_sec_mode = false;
    static change_sec_timeout = null;
    // static pause_icon = document.getElementById('pause_icon');
    static pause_icon = null;
    static sec_changed_round = 1;
    static that;
    static seek_by_mouse_timeout;

    init(data, type, current_time = 0) {
        Player.played_data = data;

        Player.that = this;

        if (!Player.is_player_init) {

            if (type)
                Player.player_type = type;

            this.render_player();

            if (type == 'live') {
                this.render_live_controle();
            } else {
                this.render_movies_controle();
            }

            if (OS == 'tizen')
                Player.player_instance = new AvPlayer();
            else
                Player.player_instance = new NativePlayer();
        }



        Player.player_instance.init(data, current_time);


        Player.show_control();

        // Player.pause_icon = get_el('#pause_icon');
        Player.play_button = get_el('#player_control_button_play');
    }
    render_player() {
        Player.is_player_init = true;
        const player_item = el({
            attr: {
                class: 'player-item',
                id: 'player_item',
                onmousemove: Player.show_control,
                onclick: Player.click_player_item,
            },
            children: [
                {
                    attr: {
                        class: 'player-backword',
                        id: 'player_backword'
                    },
                    children: [
                        {
                            attr: {
                                class: 'player-backword-icon-item'
                            }
                        },
                        {
                            attr: {
                                class: 'player-backword-forward-time',
                                id: 'player_backword_time'
                            },

                        }
                    ]
                },
                {
                    attr: {
                        class: 'player-forward',
                        id: 'player_forward'
                    },
                    children: [
                        {
                            attr: {
                                class: 'player-forward-icon-item'
                            }
                        },
                        {
                            attr: {
                                class: 'player-backword-forward-time',
                                id: 'player_forward_time'
                            },

                        }
                    ]
                },
            ],
            render: (parent) => {
                let tag_name = OS == 'tizen' ? 'object' : 'video';

                let video_player = el({
                    tagName: tag_name,
                    attr: {
                        class: 'video-player',
                        id: 'video_player',
                    }
                })

                if (tag_name = 'object')
                    video_player.setAttribute('type', 'application/avplayer');

                append(parent, video_player);

                if (Player.player_type == 'live') {
                    let _channel_number = el({
                        attr: {
                            class: 'channel-number',
                            id: 'channel_number'
                        }
                    })
                    append(parent, _channel_number);
                }


            }
        })


        append(document.getElementById('player_parent'), player_item)

        Player.player_item = player_item;
    }
    render_live_controle() {

        const _live_controle = el({
            attr: {
                class: 'live-player-control-parent',
                id: 'live_player_control_parent'
            },
            children: [
                {
                    attr: {
                        class: 'live-player-control-info-block'
                    },
                    children: [
                        {
                            attr: {
                                class: 'live-player-channel-number',
                                id: 'live_player_channel_number'
                            },
                        },
                        {
                            attr: {
                                class: 'live-player-icon-block'
                            },
                            children: [
                                {
                                    attr: {
                                        class: 'live-player-icon opacity-0',
                                        id: 'live_player_icon',

                                    }
                                }
                            ]
                        },
                        {
                            attr: {
                                class: 'live-player-info-block'
                            },
                            children: [
                                {
                                    attr: {
                                        class: 'live-player-channel-name',
                                        id: 'live_player_channel_name'
                                    },
                                    innerHTML: Player.played_data.name
                                },
                                {
                                    attr: {
                                        class: 'live-player-program-name',
                                        id: 'live_player_program_name'
                                    },
                                    innerHTML: `--:-- ${get_word('program not found')}`
                                }
                            ]
                        }
                    ]
                },
                {
                    attr: {
                        class: 'live-player-control-resolution-block'
                    },
                    children: [
                        {
                            attr: {
                                class: 'live-player-resolution-item',
                                id: 'live_player_video_resolution'
                            },

                        }
                    ]
                }
            ]
        })

        append(get_el('#player_item'), _live_controle);
    }
    render_movies_controle() {
        let name = Player.played_data.name || Player.played_data.title;



        const _movie_controle = el({
            attr: {
                class: 'payer-control-parent',
                id: 'player_control_parent'
            },
            children: [
                {
                    attr: {
                        class: 'player-control-info-block'
                    },
                    children: [
                        {
                            attr: {
                                class: 'player-control-movie-name'
                            },
                            innerHTML: name,
                        }
                    ]
                },
                {
                    attr: {
                        class: 'player-buttons-container',
                    },
                    render: (parent) => {

                        ['backward', 'prev', 'play', 'next', 'forward'].forEach((item, index) => {

                            parent.appendChild(Player.renderControlButton(item, index));

                        })
                    }
                },
                {
                    attr: {
                        class: 'player-control-progress-block'
                    },
                    children: [
                        {
                            attr: {
                                class: 'current-time',
                                id: 'player_current_time'
                            },
                            innerHTML: '00:00:00'
                        },
                        {
                            attr: {
                                class: 'progress-bar',
                                id: 'progress_bar',
                                onmouseenter: Player.progress_item_mouse_enter,
                                onclick: Player.change_time_by_click
                            },
                            children: [
                                {
                                    attr: {
                                        class: 'progress-bar-item player-item-control',
                                        id: 'player_progress_bar_item',
                                        onmousedown: Player.change_time_by_mouse
                                    }
                                }
                            ]
                        },
                        {
                            attr: {
                                class: 'duration-time',
                                id: 'player_duration_time'
                            },
                            innerHTML: '00:00:00'
                        }
                    ]
                }
            ]
        })


        const pause_icon = el_tag('div', 'pause-icon', 'pause_icon');

        const settings_item = el_tag('div', 'player-control-settings-item d-none ', 'player_settings_item')

        settings_item.onclick = Player.show_video_settings;
        settings_item.onmouseenter = Player.video_settings_mouse_enter;


        const subtitle = el_tag('div', 'subtitle-text', 'subtitle_text')

        if (AppConfig.remove_subtitle_background == 1) {
            subtitle.addClass('transparent')
        }

        let parent = get_el('#player_item')
        append(parent, _movie_controle);
        append(parent, pause_icon);
        append(parent, settings_item);
        append(parent, subtitle);
    }

    static video_loading(type) {

        let item = get_el('#player_loading_item')
        if (item)
            item.remove()



        if (type == 'remove' || Player.player_type == 'live') return;

        const loading_item = el({
            attr: {
                class: 'player-loading-item',
                id: 'player_loading_item'
            },
            children: [
                {
                    attr: {
                        class: 'player-loading-icon',
                        id: 'player_loading_icon'
                    }
                }
            ]
        })

        append(Player.player_item, loading_item);
    }
    static video_error(type) {

        let item = get_el('#player_error_item');

        if (type == 'remove' || item) {
            if (item)
                item.remove()
            return;
        }
        const error_item = el({
            attr: {
                class: 'player-error-item',
                id: 'player_error_item'
            },
            children: [
                {
                    attr: {
                        class: 'player-error-icon',
                        id: 'player_error_icon'
                    }
                }
            ]
        })


        append(Player.player_item, error_item);


    }
    static set_full_screen() {
        Player.player_instance.set_full_screen();
    }
    static hide_full_screen() {
        Player.player_instance.hide_full_screen();
    }

    static click_player_item() {

        if (Player.played_data.stream_type == 'live') return;

        Player.player_instance.play_pause();

    }

    static show_control() {

        Player.is_show_player = true;

        clearTimeout(Player.show_controle_timeout)

        let player_controle = get_el('#player_control_parent');

        if (player_controle) player_controle.addClass('show');

        let player_settings = get_el('#player_settings_item');

        if (player_settings) player_settings.addClass('show');

        Player.show_controle_timeout = setTimeout(() => {

            if (Player.player_state == 'pause') return;

            Player.hide_control();

        }, 5000)

    }

    static hide_control() {

        clearTimeout(Player.show_controle_timeout)



        Player.is_show_player = false;

        let player_controle = get_el('#player_control_parent');

        if (player_controle) player_controle.removeClass('show');

        let player_settings = get_el('#player_settings_item');

        if (player_settings) player_settings.removeClass('show');

    }


    static progress_item_mouse_enter() {

        Control.set_current('progress_bar')

    }

    static seekTo(sec, now) {

        clearTimeout(Player.change_sec_timeout);

        if (!Player.duration) return;

        if (!Player.change_sec_mode) {

            Player.state_after_seek = Player.player_state;
            Player.player_instance.pause();
        }

        sec = sec * Player.sec_changed_round;

        if (Player.sec_changed_round < 2)
            Player.sec_changed_round += 0.2;

        Player.change_sec_mode = true



        Player.current_time += sec;

        if (now) {
            Player.seeking()
            return
        }


        Player.show_backword_forward(sec)

        if (Player.current_time < 0) Player.current_time = 0;

        if (Player.current_time >= Player.duration) Player.current_time = Player.duration - 5;

        let percent = (Player.current_time * 100) / Player.duration;

        let progress_item = get_el('#player_progress_bar_item')

        if (progress_item) progress_item.style.width = `${percent}%`;

        let current_time_item = get_el('#player_current_time');


        current_time_item.innerHTML = Player.current_time.toString().toHHMMSS();


        Player.change_sec_timeout = setTimeout(() => {
            Player.seeking()
        }, 500)



    }

    static seeking() {

        if (Player.current_time == Player.duration) Player.current_time = Player.duration - 5;

        Player.current_time = parseInt(Player.current_time);

        Player.player_instance.seek_to()

        Player.sec_changed_round = 1;

        Player.hide_backword_forward()
    }


    static change_time_by_mouse(e) {


        Player.change_sec_mode = true

        function mousemove(e) {

            Player.seek_time_by_progres(e)

        }

        mousemove(e);

        document.body.addEventListener("mousemove", mousemove);

        document.body.addEventListener("mouseup", function () {
            document.body.removeEventListener("mousemove", mousemove);
        });

        document.body.addEventListener("mouseleave", function () {
            document.body.removeEventListener("mousemove", mousemove);
        });

    }

    static change_time_by_click(e) {

        e.stopPropagation();

        Player.seek_time_by_progres(e, true)

    }


    static seek_time_by_progres(e, now) {


        clearTimeout(Player.seek_by_mouse_timeout);

        let rect = document.getElementById("progress_bar").getBoundingClientRect();

        var percent = (e.pageX - rect.left) / rect.width;


        if (percent > 1) percent = 1;
        if (percent < 0) percent = 0;

        Player.current_time = percent * Player.duration;

        get_el('#player_current_time').innerHTML = Player.current_time.toString().toHHMMSS();

        get_el('#player_progress_bar_item').style.width = percent * 100 + '%';

        if (now) {
            Player.seeking()
            return
        }

        Player.seek_by_mouse_timeout = setTimeout(function () {

            Player.seeking()

        }, 1000);

    }

    static show_backword_forward(sec) {
        sec = parseInt(sec)
        if (sec < 0) {
            let parent = get_el('#player_backword');
            parent.addClass('show');
            let time = get_el('#player_backword_time');
            time.innerHTML = `${Math.abs(sec)}sec`;

            let prev_item = get_el('#player_forward');
            prev_item.removeClass('show');
        } else {
            let parent = get_el('#player_forward');
            parent.addClass('show');
            let time = get_el('#player_forward_time');
            time.innerHTML = `${Math.abs(sec)}sec`

            let prev_item = get_el('#player_backword');
            prev_item.removeClass('show');
        }
    }
    static hide_backword_forward(sec) {
        let parent = get_el('#player_backword');
        parent.removeClass('show');
        let time = get_el('#player_backword_time');
        time.innerHTML = ''

        parent = get_el('#player_forward');
        parent.removeClass('show');
        time = get_el('#player_forward_time');
        time.innerHTML = ''
    }
    static recently_watched(data) {

        if (Player.player_type != 'live' || Live.selected_category == 'recently watched') return;


        if (RecentlyWatched.is_watched(data.id) != -1) {

            RecentlyWatched.remove(data.id);

            RecentlyWatched.change_data({ type: Player.player_type, id: data.id, action: 'remove' });

        }

        RecentlyWatched.add(data.id);
        RecentlyWatched.change_data({ type: Player.player_type, id: data.id, action: 'add' });
    }
    static add_to_continue() {


        if (Player.player_type == 'live' || Player.current_time < 15) return;


        let data = {
            data: Player.played_data,
            type: Player.player_type == 'movie' ? 'movies' : 'series',
            selected_movie: MovieInfo.data,
            current_time: Player.current_time,
            duration: Player.duration
        };



        let element = get_el('#movie_info_icons_block')
        if (Player.current_time < Player.duration - 60) {
            ContinueWatch.add(data);
            element && element.addClass('watched')
            Movies.add_watched_icon({
                movie_id: MovieInfo.data.id,
                played_id: Player.played_data.id
            });
        } else {
            ContinueWatch.remove(data);
            Movies.remove_watched_icon({
                movie_id: MovieInfo.data.id,
                played_id: Player.played_data.id
            });
        }

    }

    static set_time_update() {

        let current_time_item = get_el('#player_current_time');
        let duration_time_item = get_el('#player_duration_time');
        let progress_item = get_el('#player_progress_bar_item')

        current_time_item.innerHTML = Player.current_time.toString().toHHMMSS()
        duration_time_item.innerHTML = Player.duration.toString().toHHMMSS();

        let percent = (Player.current_time / Player.duration) * 100;
        percent = percent || 0;
        if (percent > 100) return;
        progress_item.style.width = percent + '%';
    }

    static show_video_settings(e) {

        e.stopPropagation();

        PlayerSettings.show();
        Control.set_current('settings_items');


    }
    static hide_video_settings() {
        PlayerSettings.hide();
        Control.set_previous();
    }

    static video_settings_mouse_enter() {


        Control.set_current('settings')
    }



    static show_selected_open_subtitle() {

        let open_sub = PlayerSettings.selected_open_subtitle[Player.current_time]
        if (open_sub) {

            clearInterval(Player.open_subtitle_timeout);

            let subtitle_text = get_el('#subtitle_text');

            if (!subtitle_text) return;

            Player.recently_showed_subtitle = true;
            let text = open_sub.text;
            subtitle_text.innerHTML = text;

            Player.open_subtitle_timeout = setTimeout(() => {
                if (subtitle_text)
                    subtitle_text.innerHTML = "";

                Player.recently_showed_subtitle = false;
            }, open_sub.time * 1000)
        }
    }

    static stop() {
        ChangePage(pages.previous, {}, true, false)
    }


    static renderControlButton(type, index) {

        return el({
            attr: {
                index: index,
                class: `player-control-button player-control-button-control ${type}`,
                id: `player_control_button_${type}`,
                style: `background-image:url(${require(`./images/${type}.png`)})`,
                onclick: (e) => {

                    e.stopPropagation()

                    Player.button_control_click(type);
                },
                onmouseenter: Player.button_control_mouse_enter
            }
        })

    }

    static button_control_click(type) {

        console.log(type);

        switch (type) {
            case 'backward':
                Player.seekTo(-10, true);
                break;
            case 'prev':
                Player.playNextMovi(-1)
                break;
            case 'play':
                Player.player_instance.play_pause();
                break;
            case 'next':
                Player.playNextMovi(1)
                break;
            case 'forward':
                Player.seekTo(10, true);
                break;
        }

    }

    static button_control_mouse_enter() {

        let index = this.getAttribute('index');

        Control.items.index = index;

        Control.set_current('items')

    }

    static playNextMovi(num) {

        let index = MovieInfo.episodes_similares.findIndex(item => item.id == Player.played_data.id);

        let data = MovieInfo.episodes_similares[index + num];

        if (!data) return;

        Player.that.destroy();

        MovieInfo.play_movie(data)


    }

    destroy() {

        Player.add_to_continue();
        Player.player_instance.destroy();
        Player.played_data = null;
        Player.player_state = null;
        Player.player_instance = null;
        Player.is_player_init = false;
        Player.player_type = '';
        Player.duration = 0;
        Player.current_time = 0;
        Player.change_sec_mode = false;

        let item = get_el('#player_parent');

        if (item) item.remove();

    }

}


export default Player;