import CategoryItem from "../Components/CategoryItem";
import get_word from "../Utils/Words";
import { append, el, get_el, get_els, get_index, item_on_whell } from "../Utils/Utils";
import MovieItem from "../Components/MovieItem/MovieItem";
import './Movies.scss';
import { ChangeControl, ChangePage, Control } from "../Remote/Pages";
import { xtream_instance } from "..";
import Favorite from "../Components/Favorite/Favorite";
import NotHave from "../Components/NotHave/NotHave";
import { keydown } from "../Remote/Keys";



export default class Movies {
    constructor ({ data, is_series = false, cat_name = 'all' }) {
        Movies.data = data;
        Movies.is_series = is_series
        Movies.selected_category = cat_name;
    }
    static root = document.getElementById('root');
    static data;
    static is_series;
    static selected_category;
    static movie_row_count = 3;
    static movie_item_count = 6;
    static categories_item_count = 11;
    static selected_category_row_count = 0;
    static colored_btns = [
        {
            color: 'green',
            name: 'move'
        },
        {
            color: 'blue',
            name: 'lock'
        },
        {
            color: 'yellow',
            name: 'favorites'
        },
    ]
    init() {

        Movies.selected_category_row_count = Math.ceil(Movies.data.data[Movies.selected_category].items.length / Movies.movie_item_count)

        return this.render();
    }
    render() {
        const movie_page = el({
            attr: {
                class: 'movies-page-parent',
                id: 'movie_page_parent'
            },
            children: [
                {
                    attr: {
                        class: 'categories-block',
                        id: 'categories_block',
                        onwheel: item_on_whell
                    },
                    render: (parent) => {
                        let data = Movies.data.data;
                        let categories = Movies.data.categories;
                        let count = categories.length > Movies.categories_item_count ? Movies.categories_item_count : categories.length;

                        let start = categories.findIndex(item => item.name == Movies.selected_category);

                        if (Movies.selected_category == 'all') { start = 0 }
                        let end = start + count;

                        if (end > categories.length) {
                            end = categories.length;
                            start = end - count;
                        }
                        start = start < 0 ? 0 : start;

                        for (let i = start; i < end; i++) {

                            let item = new CategoryItem({
                                data: data[categories[i].name],
                                index: i,
                                callback: Movies.change_category,
                                is_selected: categories[i].name == Movies.selected_category,
                                type: Movies.is_series ? 'series' : 'movies'
                            })
                            append(parent, item.init())
                        }
                    }
                },
                {
                    attr: {
                        class: 'movie-right-block',
                    },
                    children: [
                        {
                            attr: {
                                class: 'movies-block',
                                id: 'movies_block',
                                onwheel: item_on_whell
                            },
                            render: (parent) => {


                                let count = Movies.selected_category_row_count > Movies.movie_row_count ? Movies.movie_row_count : Movies.selected_category_row_count;
                                for (let i = 0; i < count; i++) {
                                    let item = Movies.render_movie_row(i);
                                    append(parent, item)
                                }

                            }
                        },
                        {
                            attr: {
                                class: 'movies-page-footer',
                                id: 'movies_page_footer'
                            },
                            render: (parent) => {
                                Movies.colored_btns.forEach(item => {
                                    let btn = el({
                                        attr: {
                                            class: `movies-page-colored-btn`,
                                            id: `colored_btn-${item.color}`,
                                            onclick: function () {
                                                keydown({ keyName: item.color })
                                            }
                                        },
                                        children: [
                                            {
                                                attr: {
                                                    class: `movies-page-colored-btn-icon ${item.color}`
                                                }
                                            },
                                            {
                                                attr: {
                                                    class: 'movies-page-colored-btn-text',
                                                    id: `colored_btn_text-${item.color}`
                                                },
                                                innerHTML: get_word(item.name)
                                            }
                                        ]

                                    })

                                    append(parent, btn)
                                })

                            }
                        }
                    ]
                }

            ]
        })

        append(Movies.root, movie_page);
    }
    static re_render_category_item({ index, direction }) {

        let categories = Movies.data.categories;

        let item = new CategoryItem({
            data: Movies.data.data[categories[index].name],
            index: index,
            callback: Movies.change_category,
            is_selected: categories[index] == Movies.selected_category,
            type: Movies.is_series ? 'series' : 'movies'
        })

        let parent = get_el('#categories_block');

        if (direction == 'up') {
            parent.insertBefore(item.init(), parent.firstChild)
            parent.lastChild.remove();
        } else {
            append(parent, item.init())
            parent.firstChild.remove();
        }
    }

    static render_movie_row(index, direction = 'up') {

        let data = Movies.data.data[Movies.selected_category].items;

        let start = index * Movies.movie_item_count;

        let end = start + Movies.movie_item_count;

        let movie_row = el({

            attr: {

                class: `movies-row movies-row-control movie_row-${index}`,

                index: index,

            },

            render: (parent) => {

                for (let i = start; i < end; i++) {

                    let item = data[i];

                    if (!item) break;

                    item = new MovieItem({

                        data: item,

                        index: `${index}_${i}`,

                        className: 'movie',

                        is_series: Movies.is_series,

                        callback: Movies.movie_item_click,

                        mouseenter: Movies.movie_item_mouse_enter,

                    });

                    append(parent, item.init())
                }
            }

        })


        return movie_row;
    }

    static re_render_movie_row({ index, direction }) {

        let _row = Movies.render_movie_row(index);

        let parent = get_el('#movies_block');

        if (direction == 'up') {

            parent.insertBefore(_row, parent.firstChild)

            parent.lastChild.remove();

        } else {

            append(parent, _row)

            parent.firstChild.remove();

        }
    }
    static change_category(that) {

        let cat_name = that.getAttribute('cat_name');

        if (cat_name == Movies.selected_category && cat_name != 'search') return;

        Movies.selected_category = cat_name;

        if (Movies.selected_category == 'search') {

            Movies.search();

        }

        let count = Math.ceil(Movies.data.data[Movies.selected_category].items.length / Movies.movie_item_count);

        Movies.selected_category_row_count = count;

        count = count > Movies.movie_row_count ? Movies.movie_row_count : count;

        let parent = get_el('#movies_block');

        parent.innerHTML = '';


        if (count == 0) {

            NotHave.render(parent, 'not have movies');

        } else {

            for (let i = 0; i < count; i++) {

                let item = Movies.render_movie_row(i);

                append(parent, item)

            }
        }


    }
    static movie_item_click(item) {

        Control.set_current('on_hold');

        const stream_id = item.getAttribute('stream_id');

        xtream_instance.get_movie_info(stream_id, Movies.is_series).then((res) => {

            Control.set_previous();

            Movies.show_movie_info(res);

        }).catch(err => {

            Control.set_previous();

            console.log('get info error --->', err);

        })

    }

    static movie_item_mouse_enter({ index }, that) {

        ChangeControl('Movies')

        let rowIndex = get_index(that.parentElement)

        Control.movies.row = rowIndex;

        Control.movies.index = index;

        Control.set_current('movies');

    }

    static show_movie_info(data) {

        let similares;

        if (Movies.is_series) {

            similares = data.episodes[data.seasons[0]];

        } else {

            similares = Movies.data.data[Movies.selected_category].items;

            if (['favorites', 'continue watched'].includes(Movies.selected_category) && similares.length == 1) {

                let cat_id = Movies.data.data[Movies.selected_category].items[0].category_id;

                let cat_name = Movies.data.categories.find(item => item.id == cat_id).name;

                similares = Movies.data.data[cat_name].items;

            }

        }

        ChangePage('MovieInfo', { data: data, similares: similares, is_series: Movies.is_series })


    }

    static add_remove_favorite(that) {
        let id = that.getAttribute('stream_id');

        let type = Movies.is_series ? 'series' : 'movies';

        let is_favorite = Favorite.is_favorite(id, type) >= 0;


        if (is_favorite) {
            Favorite.remove(id, type)
        } else {
            Favorite.add(id, type)
        }
    }

    static hide_movie_info() {
        let parent = get_el('#movie_info_parent');
        if (parent)
            parent.remove();


        Control.set_current('movies')
    }

    static search() {
        let type = Movies.is_series ? 'series' : 'movies';
        ChangePage('Search', { type: type, callback: Movies.search_callback })
    }

    static search_callback() {
        let data = Movies.data.data[Movies.selected_category].items;
        let parent = get_el('#movies_block');
        parent.innerHTML = '';

        let count = Math.ceil(data.length / Movies.movie_item_count);
        Movies.selected_category_row_count = count;
        count = count > Movies.movie_row_count ? Movies.movie_row_count : count;
        if (count == 0) {
            NotHave.render(parent, 'not have movies');
            return
        } else {
            for (let i = 0; i < count; i++) {
                let item = Movies.render_movie_row(i);
                append(parent, item)
            }
        }


    }

    static add_watched_icon({ movie_id, played_id }) {

        let movie_info_icons_block = get_el('#movie_info_icons_block');

        if (movie_info_icons_block)
            movie_info_icons_block.addClass('watched');

        let items = get_els(`.movie_item_${movie_id}`);

        for (let i = 0; i < items.length; i++) {
            items[i].addClass('watched');
        }

        let episode_similares = get_els(`.similare_episode_item-${played_id}`);

        for (let i = 0; i < episode_similares.length; i++) {
            episode_similares[i].addClass('watched');
        }
    }

    static remove_watched_icon({ movie_id, played_id }) {

        let movie_info_icons_block = get_el('#movie_info_icons_block');

        if (movie_info_icons_block)
            movie_info_icons_block.removeClass('watched');

        let items = get_els(`.movie_item_${movie_id}`);

        for (let i = 0; i < items.length; i++) {
            items[i].removeClass('watched');
        }

        let episode_similares = get_els(`.similare_episode_item-${played_id}`);

        for (let i = 0; i < episode_similares.length; i++) {
            episode_similares[i].addClass('watched');
        }
    }

    static colored_btns_for_category(index) {
        Movies.colored_btns.forEach(item => {
            let parent = get_el(`#colored_btn-${item.color}`);

            if (index < 4) {
                parent.addClass('d-none')
                return
            }

            if (item.color == 'yellow')
                parent.addClass('d-none')
            else {
                parent.removeClass('d-none')
            }


        })
    }

    static colored_btns_for_movies() {
        Movies.colored_btns.forEach(item => {
            let parent = get_el(`#colored_btn-${item.color}`);



            if (item.color == 'yellow')
                parent.removeClass('d-none')
            else {
                parent.addClass('d-none')
            }


        })
    }


    static move_category(item) {

        let index = item.getAttribute('index');

        if (index < 4) return


        let type = Movies.is_series ? 'series' : 'movies';
        ChangePage('Move', { data_type: type, item })

    }
    static on_hold_back() {
        xtream_instance.abort();
        let items = get_els('.loading')

        for (let i = 0; i < items.length; i++) {
            items[i].removeClass('loading')
        }
    }
    static destroy() {

    }
}