import AppConfig from "../AppConfig";
import pages, { ChangePage } from "../Remote/Pages";
import { content } from "../Utils/Content";
import { append, el, get_el, get_index, item_on_whell } from "../Utils/Utils";
import get_word from "../Utils/Words";
import './LockCategories.scss';
import Control from "./LockCategoriesControl";

export default class LockCategories {
    constructor ({ type = 'lock' }) {
        LockCategories.type = type;
        LockCategories.data = {
            live: content.live.categories.slice(4),
            movies: content.movies.categories.slice(4),
            series: content.series.categories.slice(4),
        }

    }
    static data;
    static type;
    init() {

        this.marge_hided_categories();

        this.render();
    }
    marge_hided_categories() {
        ['live', 'movies', 'series'].forEach(data_type => {
            let data = AppConfig.hided_categories[data_type];
            for (let key in data) {
                LockCategories.data[data_type].push({ id: key, ...data[key] })
            }

            LockCategories.data[data_type].sort((a, b) => a.position - b.position);
        })


    }
    render() {

        let message = LockCategories.type == 'lock' ? 'lock categories' : 'hide categories';

        const lock_categories = el({
            attr: {
                class: 'lock-categories-parent',
                id: 'lock_categories_parent',
                style: `background-image: url(${AppConfig.background})`
            },
            children: [
                {
                    attr: {
                        class: 'lock-categories-title',
                    },
                    innerHTML: get_word(message)
                },
                {
                    attr: {
                        class: 'lock-categories-items-content',
                    },
                    render: (parent) => {
                        ['live', 'movies', 'series'].forEach((item, rowIndex) => {

                            const _categories_block = el({
                                attr: {
                                    class: 'lock-categories-items-block',
                                },
                                children: [
                                    {
                                        attr: {
                                            class: 'lock-categories-items-block-title',
                                        },
                                        innerHTML: get_word(`${item} categories`)
                                    },
                                    {
                                        attr: {
                                            class: 'lock-categories-items-block-content lock-categories-control',
                                            id: `lock_categories_items_block_content-${item}`,
                                            data_type: item,
                                            index: rowIndex,
                                            onwheel: item_on_whell
                                        },
                                        render: (parent) => {
                                            let data = LockCategories.data[item];

                                            let count = data.length > 9 ? 9 : data.length;

                                            for (let i = 0; i < count; i++) {
                                                let element = LockCategories.render_item({
                                                    data_type: item,
                                                    data: data[i],
                                                    index: i,
                                                    rowIndex,
                                                });

                                                append(parent, element);
                                            }

                                        }
                                    }
                                ]
                            })

                            append(parent, _categories_block)
                        })
                    }
                }
            ]

        })

        append(document.body, lock_categories)
    }

    static render_item({ data_type, data, index, rowIndex }) {
        let cat_id = data.id;
        let item_state;

        if (LockCategories.type == 'lock') {
            item_state = LockCategories.is_locked(cat_id, data_type);
        } else {
            item_state = LockCategories.is_hided(cat_id, data_type);
        }

        return el({
            attr: {
                class: `lock-categories-items-block-content-item ${item_state ? `${LockCategories.type}ed` : ''}`,
                cat_name: data.name,
                cat_id: cat_id,
                data_type: data_type,
                index: index,
                row_index: `${rowIndex}_${index}`,
                onclick: function () {
                    LockCategories.item_click({
                        item: this,
                        data_type: data_type,
                        type: LockCategories.type,
                    })
                },
                onmouseenter: LockCategories.item_mouse_enter
            },
            innerHTML: data.name,
        })
    }

    static re_render_item({ data_type, index, direction }) {

        parent = get_el(`#lock_categories_items_block_content-${data_type}`)

        let data = LockCategories.data[data_type];

        let item = LockCategories.render_item({
            data_type: data_type,
            data: data[index],
            index: index,
            rowIndex: parent.getAttribute('index'),
        });

        if (direction == 'up') {
            parent.insertBefore(item, parent.firstChild)
            parent.lastChild.remove();
        } else {
            parent.appendChild(item);
            parent.firstChild.remove();
        }

    }
    static item_click({ item, data_type, index = -1, type }) {


        let name = item.getAttribute('cat_name');

        if (index < 0)
            index = item.getAttribute('index')


        item.classList.toggle(type == 'lock' ? 'locked' : 'hided');
      
        let data = LockCategories.data[data_type][index];
      
        let cat_id = data.id;

        if (type == 'lock') {
            LockCategories.change_lock_status({ cat_id, data_type, name });
        } else {
            LockCategories.change_hide_status({ data, data_type });
        }
    }

    static item_mouse_enter() {


        let [rowIndex, index] = this.getAttribute('row_index').split('_')

        index = get_index(this)

        Control.lock_category.index = index;

        Control.lock_category.row = rowIndex;

        Control.set_current('lock_category')

    }


    static change_hide_status({ data, data: { name, id, position }, data_type }) {


        let cat_id = id;


        if (LockCategories.is_hided(cat_id, data_type)) {
            delete AppConfig.hided_categories[data_type][cat_id];
            LockCategories.add_hided_in_data(data_type, cat_id, name);
        } else {
            if (!AppConfig.hided_categories[data_type]) AppConfig.hided_categories[data_type] = {};
            AppConfig.hided_categories[data_type][cat_id] = { name, position };

            LockCategories.remove_hided_from_data(data_type, cat_id, name);
        }



        localStorage.setItem(`hided_categories-${AppConfig.selected_server.id}`, JSON.stringify(AppConfig.hided_categories));
    }
    static is_hided(cat_id, data_type) {
        return AppConfig.hided_categories[data_type] && AppConfig.hided_categories[data_type][cat_id]
    }

    static change_lock_status({ cat_id, data_type, name }) {

        if (LockCategories.is_locked(cat_id, data_type)) {
            delete AppConfig.locked_categories[data_type][cat_id];
        } else {
            if (!AppConfig.locked_categories[data_type]) AppConfig.locked_categories[data_type] = {};
            AppConfig.locked_categories[data_type][cat_id] = name;
        }

        localStorage.setItem(`locked_categories-${AppConfig.selected_server.id}`, JSON.stringify(AppConfig.locked_categories));
    }
    static is_locked(cat_id, data_type) {

        return AppConfig.locked_categories[data_type] && AppConfig.locked_categories[data_type][cat_id]
    }

    static lock_category_back() {

        if (LockCategories.type == 'hid') {
            LockCategories.change_all_data();
        }

        ChangePage(pages.activePage, {}, true, false);
    }

    static change_all_data() {

        let hided_data = {
            live: {
                all: {},
                'recently watched': {},
                favorites: {},
            },
            movies: {
                all: {},
                favorites: {},
            },
            series: {
                all: {},
                favorites: {},
            },
        };

        ['live', 'movies', 'series'].forEach(data_type => {


            if(content[data_type].data.all){

                content[data_type].data.all.items = content[data_type].data.all.items.filter((item, index) => {


                    let is_hided = LockCategories.is_hided(item.category_id, data_type);
    
                    if (is_hided) {
                        hided_data[data_type].all[index] = item;
                    }
    
                    return !is_hided;
    
                })

            }
           

            if (data_type == 'live' && content[data_type].data['recently watched']) {


                content[data_type].data['recently watched'].items = content[data_type].data['recently watched'].items.filter((item, index) => {

                    let is_hided = LockCategories.is_hided(item.category_id, data_type);
                    if (is_hided) {
                        hided_data[data_type]['recently watched'][index] = item;
                    }
                    return !is_hided;
                })
            }

            if(content[data_type].data.favorites){
                content[data_type].data.favorites.items = content[data_type].data.favorites.items.filter((item, index) => {
                    let is_hided = LockCategories.is_hided(item.category_id, data_type);
                    if (is_hided) {
                        hided_data[data_type].favorites[index] = item;
                    }
                    return !is_hided;
                })
            }
           

        })

        AppConfig.hided_data.items = hided_data;

    }

    static remove_hided_from_data(data_type, cat_id, name) {

        let data = JSON.parse(JSON.stringify(content[data_type].data[name]));
        let position = content[data_type].categories.find(item => item.name == name).position;
        let item = { name: name, index: content[data_type].categories.indexOf(name), data: data, position: position };

        AppConfig.hided_data[data_type][cat_id] = item;

        delete content[data_type].data[name];
        content[data_type].categories = content[data_type].categories.filter(item => item.name != name);



    }

    static add_hided_in_data(data_type, cat_id, name) {

        let item = AppConfig.hided_data[data_type][cat_id];

        content[data_type].data[name] = item.data;
        content[data_type].categories.push({ id: cat_id, name: name, position: item.position });
        delete AppConfig.hided_data[data_type][cat_id];

        ['all', 'favorites', 'recently watched'].forEach(type => {
            if (data_type != 'live' && type == 'recently watched') return;

            let data = AppConfig.hided_data.items[data_type][type];

            if (data) {
                for (let key in data) {
                    content[data_type].data[type].items.splice(key, 0, data[key]);
                }
            }

        })

        content[data_type].categories.sort((a, b) => a.position - b.position)

    }

    static check_hided_data() {
        ['live', 'movies', 'series'].forEach(data_type => {

            content[data_type].categories.forEach(item => {
                let cat_name = item.name;
                let cat_id = item.id;

                if (LockCategories.is_hided(cat_id, data_type)) {
                    LockCategories.remove_hided_from_data(data_type, cat_id, cat_name);
                }
            })


        })

        LockCategories.change_all_data();

    }

  

}