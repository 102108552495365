import AppConfig from "../../AppConfig";
import { content } from "../../Utils/Content";
import { get_els } from "../../Utils/Utils";


export const favoriteData = {
    movies: null,
    series: null,
    live: null,
}

export default class Favorite {

    static get_data(type) {
        let data = localStorage.getItem(`favorite_${type}-${AppConfig.selected_server.id}`);
        if (data) {
            return JSON.parse(data);
        } else {
            return []
        }
    }
    static get(type) {

        if (!favoriteData[type]) {
            favoriteData[type] = Favorite.get_data(type);
        }
        return favoriteData[type];

    }

    static set({ data, type }) {
        localStorage.setItem(`favorite_${type}-${AppConfig.selected_server.id}`, JSON.stringify(data));
        favoriteData[type] = data;
    }

    static add(id, type) {
        let favorite = Favorite.get(type);
        favorite.unshift(id);
        Favorite.set({ data: favorite, type });
        Favorite.change_data({ type, id, action: 'add' })
    }
    static remove(id, type) {
        let favorite = Favorite.get(type);
        favorite = favorite.filter(item => item != id);
        Favorite.set({ data: favorite, type });
        Favorite.change_data({ type, id, action: 'remove' })
    }
    static is_favorite(id, type) {
        let favorite = Favorite.get(type);
        return favorite.findIndex(item => item == id);
    }
    static change_data({ type, id, action }) {

        let data = content[type].data.all.items.find(item => item.id == id);

        if (action == 'remove') {
            content[type].data.favorites.items = content[type].data.favorites.items.filter(item => item.id != id);
        } else {
            content[type].data.favorites.items.unshift(data);
        }
        Favorite.change_icon_state({ type: action, id });
    }
    static change_icon_state({ type, id }) {
        let items = get_els('.movie_item_' + id);

        items.forEach(item => {

            if (type == 'add') {
                item.classList.add('favorite');
            } else {
                item.classList.remove('favorite');
            }
        });
    }
};

window.favData = favoriteData; // for debug