import Move from '@/src/Move/Move';
import LockCategories from '../../LockCategories/LockCategories';
import Movies from '../../Movies/Movies';
import pages, { ChangePage } from '../../Remote/Pages';
import { append, el } from '../../Utils/Utils';
import ContinueWatch from '../ContinueWatching/ContinueWatching';
import Favorite from '../Favorite/Favorite';
import ImageResizer from '../ResizeImage/ImageResizer';
import './MovieItem.scss';
import Search from '@/src/Search/Search';


export default class MovieItem {
    constructor ({ data, className, index = 0, is_series, callback = () => { }, mouseenter = () => { } }) {
        this.item = data
        this.index = index
        this.className = className;
        this.is_series = is_series;
        this.is_locked = false;
        this.is_favorite = false;
        this.text_scroll = '';
        MovieItem.callback = callback;
        MovieItem.mouseenter = mouseenter;
    }
    static callback
    init() {

        let type = this.is_series ? 'series' : 'movies';
        this.is_favorite = Favorite.is_favorite(this.item.id, type) >= 0 ? ' favorite' : '';

        this.is_locked = LockCategories.is_locked(this.item.category_id, type);
        this.is_locked = this.is_locked && this.is_locked != Movies.selected_category ? ' locked' : '';

        this.is_watched = ContinueWatch.is_watched(this.item.id, type) ? ' watched' : '';

        this.className += `${this.is_locked} ${this.is_favorite} ${this.is_watched}`

        this.text_scroll = this.item.name.length > 14 ? 'scroll-text' : '';

        return this.render()
    }
    render() {


        const that = this;
        const item = el({
            attr: {
                class: `movie-item movie_item_${this.item.id} ${this.className}`,
                stream_id: this.item.id,
                index: this.index,
                type: this.item.stream_type || 'series',
                onclick: function () {
                    that.item_click(this)
                },
                onmouseenter: this.item_mouse_enter
            },
            children: [
                {
                    attr: {
                        class: 'movie-item-status'
                    },
                    render: (parent) => {
                        ['watched', 'favorite', 'lock'].forEach(icon => {
                            let item = el({
                                tagName: 'img',
                                attr: {
                                    class: `movie-item-icon ${icon}`,
                                    src: require(`../images/${icon}.png`)

                                }
                            })
                            append(parent, item)
                        })
                    }
                },
                {
                    tagName: 'img',
                    attr: {
                        class: 'movie-item-play',
                        src: require('../images/play_item.png'),
                    }
                },
                {
                    attr: {
                        class: 'movie-item-image opacity-0',
                        id: this.item.id,
                    },
                    render: (parent) => {

                        setTimeout(async () => {
                            let item = await ImageResizer({ src: that.item.cover, width: 234, height: 344, id: this.item.id })
                            if (item)
                                append(parent, item)

                            parent.classList.remove('opacity-0')
                        }, 500)
                    }
                },
                {
                    attr: {
                        class: 'movie-item-bottom-info'
                    },
                    children: [
                        {
                            attr: {
                                class: 'movie-item-title-container',
                            },
                            children: [
                                {
                                    attr: {
                                        class: `movie-item-title ${this.text_scroll}`,
                                    },
                                    innerHTML: this.item.name
                                }
                            ]
                        },
                        {
                            attr: {
                                class: 'movie-item-icons-container'
                            },
                            render: (parent) => {
                                ['watched', 'favorite', 'locked'].forEach(icon => {
                                    let item = el({
                                        attr: {
                                            class: `movie-item-icon`,
                                            id: `movie_icon-${icon}`
                                        }
                                    })
                                    append(parent, item)
                                })
                            }
                        },
                    ]
                }
            ]
        })


        return item;
    }
    item_click(that) {

        if (Move.move_mode) return

        if (pages.current == 'Search')
            Search.close_search();


        if (this.is_locked) {

            ChangePage('ParentalCode', {
                callback: () => {
                    if (MovieItem.callback) {
                        MovieItem.callback(that)
                    }
                }
            })
        } else {
            if (MovieItem.callback) {
                MovieItem.callback(that)
            }
        }
    }


    item_mouse_enter() {

        let [rowIndex, index] = this.getAttribute('index').split('_')

        let parent = this.parentElement;

        for (let i = 0; i < parent.children.length; i++) {

            if (parent.children[i] == this) {

                index = i;

                break

            }

        }

        MovieItem.mouseenter({
            rowIndex,
            index,
        }, this)
    }
}