import { remove_class } from "../Utils/Utils";
import { move } from "../Remote/Keys";
import Live from "./Live";
import pages, { ChangeControl, ChangePage } from "../Remote/Pages";
import LockCategories from "../LockCategories/LockCategories";



const Control = {
    current: "channels",
    previous: "",
    set_current: function (current) {

        if (this.current != current)
            this.previous = this.current;

        this.current = current;

        move();
    },
    set_previous: function () {
        this.current = this.previous;
        move();
    },

    keydown: function (param) {

        if (Live.full_screen_channels_mode && param == 'back') {

            Live.hide_channels_in_full_screen()

            return true
        }
    },
    categories: {
        index: 0,
        items: document.getElementsByClassName('category-item-control'),

        green: function () {
            // move
            Live.move_category(this.items[this.index])
        },

        blue: function () {
            // lock 

            let item_index = this.items[this.index].getAttribute('index');

            if (item_index < 4) return

            const that = this;
            ChangePage('ParentalCode', {
                callback: () => {
                    new LockCategories({ type: 'lock' });
                    LockCategories.item_click({
                        item: that.items[that.index],
                        index: that.items[that.index].getAttribute('index') - 4,
                        data_type: 'live',
                        type: 'lock'
                    })
                }
            })
        },
        up: function () {
            let item_index = this.items[0].getAttribute('index');
            if (this.index == 0 && item_index != 0) {
                Live.re_render_category_item({
                    index: +item_index - 1,
                    direction: 'up'
                })

            } else if (this.index == 0) {
                return
            } else {
                this.index--;

            }

            this.move();
        },
        down: function () {
            let item_index = this.items[this.items.length - 1].getAttribute('index');
            if (this.index == this.items.length - 1 && item_index != Live.data.categories.length - 1) {
                Live.re_render_category_item({
                    index: +item_index + 1,
                    direction: 'down'
                })
            }
            else if (this.index == this.items.length - 1) {
                return
            }
            else {
                this.index++;
            }

            this.move();
        },
        right: function () {
            if (Control.channels.items.length)
                Control.set_current('channels')
        },
        ok: function () {
            this.items[this.index].click();
        },
        back: function () {
            ChangePage('Menu', {}, true)
        },
        move: function () {
            remove_class('active');
            this.items[this.index].addClass('active');
            Live.colored_btns_for_category(this.index)
        },
    },
    channels: {
        index: 0,
        items: document.getElementsByClassName('live-item-control'),
        left: function () {

            if (pages.current == 'Search') {

                ChangeControl('Search');
                move();
            } else {

                Control.set_current('categories');

            }
        },

        yellow: function () {
            Live.add_remove_favorite(this.items[this.index]);
        },
        blue: function () {
            ChangePage('Menu', {}, true)
        },
        green: function () {
            // move
            if (['search', 'recently watched'].indexOf(Live.selected_category) > -1) return

            Live.move_channels(this.items[this.index])
        },
        up: function () {
            let item_index = this.items[0].getAttribute('index');
            if (this.index == 0 && item_index != 0) {
                Live.re_render_channel_item({
                    index: +item_index - 1,
                    direction: 'up'
                })
            }
            else if (this.index == 0) {
                return
            } else {
                this.index--;
            }

            this.move();
        },
        down: function () {
            let item_index = this.items[this.items.length - 1].getAttribute('index');

            if (this.index == this.items.length - 1 && item_index != Live.data.data[Live.selected_category].items.length - 1) {
                Live.re_render_channel_item({
                    index: +item_index + 1,
                    direction: 'down'
                })
            }
            else if (this.index == this.items.length - 1) {
                return
            } else {
                this.index++;
            }

            this.move();
        },
        ok: function () {
            this.items[this.index].click();
        },
        back: function () {
            ChangePage('Menu', {}, true)
        },
        move: function () {
            remove_class('active');
            this.items[this.index].addClass('active');
            Live.colored_btns_for_channels()
        },

    },
    full_screen: {
        keydown: function (param) {
            if ([0, 1, 2, 3, 4, 5, 6, 7, 8, 9].indexOf(+param) > -1) {
                Live.change_channel_by_number(param)
            }

            if (param == 'ok') {
                Live.show_channels_in_full_screen()
                return
            }

            if (param != 'back' && !Live.is_show_control) {
                Live.show_control()
            }
        },
        up: function () {
            Control.channels.down()
            Control.channels.ok()
        },
        channel_up: function () {
            Control.channels.down()
            Control.channels.ok()
        },
        down: function () {
            Control.channels.up()
            Control.channels.ok()
        },
        channel_down: function () {
            Control.channels.up()
            Control.channels.ok()
        },
        back: function () {


            if (Live.is_show_control) {
                Live.hide_control()
            } else {
                Live.hide_full_screen()
            }
        },
    }

}

export default Control;