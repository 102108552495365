

import { get_el, get_els, remove_class } from "../Utils/Utils";
import { move } from "../Remote/Keys";
import pages, { ChangePage } from "../Remote/Pages";
import Player from "./Player";


const Control = {
    current: "progress_bar",
    previous: "",
    set_current: function (current) {

        if (this.current != current)
            this.previous = this.current;

        this.current = current;

        move();
    },
    set_previous: function () {
        this.set_current(this.previous)
    },
    keydown: function (param) {

        if (this.current == 'settings_items') {
            return false
        }

        if (this.current == 'progress_bar') {
            if (param == 'ok' || param == 'play_pause') {
                Player.player_instance.play_pause();
                return true;
            } else if (['play', 'pause', 'stop'].includes(param)) {
                return false
            }

        }

        if (param != 'back') {

            if (!Player.is_show_player) {
                Player.show_control();
                return true
            } else {
                Player.show_control();
            }
        }

        if (param == 'back') {
            if (Player.is_show_player) {
                Player.hide_control();
            } else {
                ChangePage(pages.previous, {}, true, false)

            }
            return true
        }
    },

    settings: {
        item: document.getElementsByClassName("player-settings-control"),
        down: function () {
            Control.set_current('items');
        },
        ok: function () {
            this.item[0].click();
        },
        move: function () {
            remove_class('active');


            this.item[0].addClass('active');
        }
    },

    items: {
        index: 0,
        items: document.getElementsByClassName('player-control-button-control'),
        up: function () {
            if (Control.settings.item.length) {
                Control.set_current('settings');
            }
        },
        down: function () {
            Control.set_current('progress_bar');
        },
        left: function () {

            if (this.index == 0) return;

            this.index--;

            this.move();
        },
        right: function () {

            if (this.index == this.items.length - 1) return;

            this.index++;

            this.move();
        },
        back: function () {

            ChangePage(pages.previous, {}, true, false)

        },
        ok: function () {
            this.items[this.index].click();
        },
        play: function () {
            Player.player_instance.play_video();
        },
        pause: function () {
            Player.player_instance.pause();
        },
        play_pause: function () {
            Player.player_instance.play_pause();
        },
        stop: function () {
            // Player.player_instance.stop();
            ChangePage(pages.previous, {}, true, false)
        },
        move: function () {
            remove_class('active');

            this.items[this.index].addClass('active');
        }
    },

    progress_bar: {
        item: document.getElementsByClassName("player-item-control"),
        up: function () {
            Control.set_current('items');
        },
        fast_prev: function () {
            Player.seekTo(-15);
        },
        fast_next: function () {
            Player.seekTo(15);
        },
        left: function () {
            Player.seekTo(-15);
        },
        right: function () {
            Player.seekTo(15);
        },
        ok: function () {
            Player.player_instance.play_pause();
        },
        play: function () {
            Player.player_instance.play_video();
        },
        pause: function () {
            Player.player_instance.pause();
        },
        play_pause: function () {
            Player.player_instance.play_pause();
        },
        stop: function () {
            // Player.player_instance.stop();
            ChangePage(pages.previous, {}, true, false)
        },
        move: function () {
            remove_class('active');

            this.item[0].addClass('active');
        }
    },


    settings_items: {
        index: 0,
        items: document.getElementsByClassName('player-settings-item-control'),
        up: function () {
            if (this.index == 0) return;
            this.index--;
            this.move();
        },
        down: function () {
            if (this.index == this.items.length - 1) return;
            this.index++;
            this.move();
        },
        ok: function () {
            this.items[this.index].click();
        },
        back: function () {
            Player.hide_video_settings();
        },
        move: function () {
            remove_class('active', get_el('#player_settings_container'));

            if (this.index > this.items.length - 1) this.index = this.items.length - 1;

            this.items[this.index].addClass('active');

            if (this.index > 5)
                this.scroll();
        },
        scroll: function () {


            let parent = get_el('#player_settings_scroll');
            if (parent) {
                let offset = (this.index - 6) * 9.4;
                parent.style.transform = `translateY(-${offset}rem)`;
            }
        }
    }
}

export default Control;