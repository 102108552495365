import { MovieInfo } from "../../MovieInfo/MovieInfo";
import Player from "../../Player/Player";
import Control from "../../Player/PlayerControl";
import { OS } from "../../Utils/Os";
import { append, el, get_el, get_index, remove_class } from "../../Utils/Utils";
import OpenSubtitle from "../OpenSubtitles/OpenSubtitles";
import Tmdb from "../Tmdb/Tmdb";
import './PlayerSettings.scss';


export default class PlayerSettings {

    static tracks = {
        audio: [],
        subtitle: [],
        video: [],
        aspect_ratio: [],
    }
    static selected_video_index = null;
    static selected_audio_index = null;
    static selected_subtitle_index = null;
    static selected_aspect_ratio_index = 0;
    static video_player
    static type;
    static show_subtitle = false;
    static is_selected_open_subtitle = false;
    static selected_open_subtitle = {};
    static open_subtiles;

    static init(type = 'vod') {
        PlayerSettings.type = type;

        PlayerSettings.video_player = document.getElementById('video_player');

        PlayerSettings.reset_data();

        PlayerSettings.get_tracks();
        PlayerSettings.get_aspect_ratio();


        if (PlayerSettings.type == 'live') {
            PlayerSettings.set_resolution();
        } else {
            PlayerSettings.check_tracks();
        }



    }

    static get_open_subtitles() {
        if (PlayerSettings.type == 'live') return;

        if (!PlayerSettings.open_subtiles) {
            PlayerSettings.open_subtiles = new OpenSubtitle()
            PlayerSettings.open_subtiles.login();
        }

        let tmdb_id = MovieInfo.data.tmdb_id;



        if (tmdb_id) {
            PlayerSettings.search_open_subtitle(tmdb_id);
        } else {

            let name = MovieInfo.data.name;
            let is_series = Player.played_data.stream_type != 'movies'
            Tmdb.search({ name, is_series }, (data) => {
                if (data) {

                    if (is_series) {
                        Tmdb.get_episode_info(data.id, Player.played_data.season, Player.played_data.episode_num, (episode) => {
                            if (episode) {
                                PlayerSettings.search_open_subtitle(episode.id);
                            }
                        },
                            () => {
                                PlayerSettings.search_open_subtitle();

                            })
                    } else {
                        PlayerSettings.search_open_subtitle(data.id);

                    }
                } else {
                    PlayerSettings.search_open_subtitle();
                }
            }, (err) => {
                PlayerSettings.search_open_subtitle();
            })
        }
    }

    static search_open_subtitle(tmdb_id) {

        let query = {};

        if (!tmdb_id) {


            let data = MovieInfo.data;

            if (data.tmdb_id) {
                query.tmdb_id = data.tmdb_id;
            } else {
                query.query = data.name.toLowerCase().split(' ').join('+');

                query.year = data.year;
            }
            if (PlayerSettings.type == "series") {
                query.episode_number = Player.played_data.episode_num;
                query.season_number = Player.played_data.season;

            }
        } else {
            query.tmdb_id = tmdb_id;
        }


        PlayerSettings.open_subtiles.search({
            data: query,
            cb: (data) => {

                let subtitles = data.data;

                let added_names = {};


                subtitles.forEach((item, index) => {
                    let subtitle = item.attributes;

                    if (subtitle.files.length) {
                        if (!added_names[subtitle.language]) added_names[subtitle.language] = { count: 0 };
                        added_names[subtitle.language].count += 1;

                        let name = PlayerSettings.langugaes_iso_codes[subtitle.language];

                        if (!name || name == 'null') name = 'subtitle';


                        PlayerSettings.tracks.subtitle.push({
                            open_subtitle: true,
                            name: name + ' ' + added_names[subtitle.language].count,
                            index: `open_${index}`,
                            file_id: subtitle.files[0].file_id
                        })
                    }
                })
            },
            error: () => {

            }
        })
    }

    static get_aspect_ratio() {
        PlayerSettings.selected_aspect_ratio_index = 0;
        if (OS == 'tizen') {
            PlayerSettings.tracks.aspect_ratio = [
                { index: 0, name: "full screen", type: "PLAYER_DISPLAY_MODE_FULL_SCREEN" },
                { index: 1, name: "aspect ratio", type: "PLAYER_DISPLAY_MODE_AUTO_ASPECT_RATIO" },
                { index: 2, name: "original", type: "PLAYER_DISPLAY_MODE_LETTER_BOX" },

            ]
        } else if (OS == 'android') {
            PlayerSettings.tracks.aspect_ratio = [
                { index: 0, name: "default", type: "16:9" },
                { index: 1, name: "16:9", type: "16:9" },
                { index: 2, name: "4:3", type: "4:3" },
                { index: 3, name: "1:1", type: '1:1' },
                { index: 4, name: "16:10", type: '16:10' },
                { index: 5, name: "5:4", type: '5:4' },
            ]
        } else if (OS == 'webos') {
            PlayerSettings.tracks.aspect_ratio = [
                { index: 0, name: "original", type: "original", aspectRatio: 1 },
                { index: 1, name: "full", type: "16:9", aspectRatio: 1.7778 },
                { index: 2, name: "5:4", type: '5:4', aspectRatio: 1.25 },
                { index: 3, name: "4:3", type: '4:3', aspectRatio: 0.75 },
                { index: 4, name: "3:2", type: '3:2', aspectRatio: 0.6667 },
                { index: 5, name: "16:9", type: '16:9', aspectRatio: 1.7778 },
                { index: 6, name: "21:9", type: '21:9', aspectRatio: 2.3333 },
                { index: 7, name: "3:1", type: '3:1', aspectRatio: 0.3333 },
            ]
        }
    }

    static get_tracks() {


        PlayerSettings.tracks = {
            audio: [],
            subtitle: [],
            video: [],
            aspect_ratio: [],
        }
        if (OS == 'tizen') {


            let state = webapis.avplay.getState();

            if (["READY", "PLAYING", "PAUSED"].indexOf(state) == -1) {

                setTimeout(function () {
                    PlayerSettings.get_tracks();
                }, 1000);

                return

            }
            let info = webapis.avplay.getTotalTrackInfo();
            let current_info = webapis.avplay.getCurrentStreamInfo();



            let track;
            for (let i = 0; i < info.length; i++) {
                try {
                    track = JSON.parse(info[i].extra_info);

                } catch (e) {
                    continue;
                }
                track.index = parseInt(info[i].index);
                let name = track.language || index;
                let index = track.index;
                switch (info[i].type) {
                    case "AUDIO":
                        PlayerSettings.tracks.audio.push({ name: name, index: index });
                        break;
                    case "VIDEO":
                        let width = track.Width;
                        let height = track.Height;
                        PlayerSettings.tracks.video.push({ width: width, height: height, name: width + 'X' + height, index: index });
                        break;
                    case "TEXT":
                        PlayerSettings.tracks.subtitle.push({ name: name, index: index });
                        break;
                }
            }

            for (let i = 0; i < current_info.length; i++) {
                let index = parseInt(current_info[i].index);

                switch (current_info[i].type) {
                    case "VIDEO":
                        PlayerSettings.selected_video_index = index;
                        break;
                    case "AUDIO":
                        PlayerSettings.selected_audio_index = index;
                        break;
                    // case "TEXT":
                    //     PlayerSettings.selected_subtitle_index = index;
                    //     break;
                }
            }

        } else if (OS == 'android') {

            let tracks = window.Android.getTracks();
            if (tracks)
                tracks = JSON.parse(tracks);
            else
                tracks = [];


            for (let i = 0; i < tracks.length; i++) {
                let track = tracks[i];
                let selected = track.is_selected


                if (track.name.toLowerCase() == 'disable') continue;

                switch (track.type) {
                    case "AUDIO":
                        if (selected) PlayerSettings.selected_audio_index = track.index;
                        PlayerSettings.tracks.audio.push(track);
                        break;
                    case "VIDEO":
                        if (selected) PlayerSettings.selected_video_index = track.index;
                        PlayerSettings.tracks.video.push(track);
                        break;
                    case "TEXT":
                        if (selected) PlayerSettings.selected_subtitle_index = track.index;
                        PlayerSettings.tracks.subtitle.push(track);
                        break;
                }
            }

        }
        else {

            if (video_player.audioTracks && video_player.audioTracks.length) {

                for (let i = 0; i < video_player.audioTracks.length; i++) {

                    if (video_player.audioTracks[i].enabled) PlayerSettings.selected_audio_index = i;

                    PlayerSettings.tracks.audio.push({
                        name: video_player.audioTracks[i].language || video_player.audioTracks[i].label || i,
                        index: i
                    });

                }

            }

            if (video_player.videoTracks && video_player.videoTracks.length) {

                for (let i = 0; i < video_player.videoTracks.length; i++) {
                    if (video_player.videoTracks[i].selected) PlayerSettings.selected_video_index = i;

                    let data = {
                        width: video_player.videoTracks[i].width || "" + video_player.videoWidth,
                        height: video_player.videoTracks[i].height || "" + video_player.videoHeight,
                        index: i
                    }
                    data.name = data.width + 'X' + data.height;
                    PlayerSettings.tracks.video.push(data);
                }
            }

            if (video_player.textTracks && video_player.textTracks.length) {

                for (let i = 0; i < video_player.textTracks.length; i++) {

                    if (video_player.textTracks[i].mode == "showing") PlayerSettings.subtitle = i;

                    PlayerSettings.subtitles.push({
                        name: video_player.textTracks[i].language || video_player.textTracks[i].label || i,
                        index: i
                    });

                }

            }
        }


        if (PlayerSettings.selected_subtitle_index && PlayerSettings.selected_subtitle_index > 0) {
            PlayerSettings.show_subtitle = true;
        }
        PlayerSettings.get_open_subtitles();


    }

    static set_resolution() {
        let resolution = PlayerSettings.tracks.video[PlayerSettings.selected_video_index];

        let item = get_el('#live_player_video_resolution');

        if (resolution) {
            if (item) {
                item.innerHTML = resolution.name;

            }
        }
    }

    static check_tracks() {

        for (let key in PlayerSettings.tracks) {
            if (PlayerSettings.tracks[key].length) {
                PlayerSettings.show_item()
                break;
            }
        }
    }

    static show_item() {
        let item = get_el('#player_settings_item');
        if (item) {
            item.removeClass('d-none');
            item.addClass('player-settings-control')
        }
    }
    static set_track(type, index) {



        if (type == "VIDEO") PlayerSettings.selected_video_index = index;
        if (type == "AUDIO") PlayerSettings.selected_audio_index = index;
        if (type == "TEXT") {
            PlayerSettings.selected_subtitle_index = index;
            PlayerSettings.show_subtitle = true;
        }

        if (type == "ASPECT_RATIO") {
            PlayerSettings.selected_aspect_ratio_index = index;
            PlayerSettings.set_aspcet_ratio();

            return
        }

        if (OS == "tizen") {

            try {
                webapis.avplay.setSelectTrack(type, index);
            } catch (e) {
                console.log(e)
            }
        } else if (OS == 'android') {
            index = parseInt(index);
            window.Android.selectTrack(type, index, 0);
        }
        else {

            let key = 'enabled';
          
            let index = PlayerSettings[`selected_${type.toLowerCase()}_index`];
          
            let tracks = video_player[`${type.toLowerCase()}Tracks`];
          
            for (let i = 0; i < tracks.length; i++) {
                let item = tracks[i];
                item[key] = false;
            }

            tracks[index][key] = true;

            // switch (type) {
            //     case 'AUDIO':
            //         video_player.audioTracks[PlayerSettings.selected_audio_index].enabled = true;
            //         break;
            //     case 'VIDEO':
            //         video_player.videoTracks[PlayerSettings.selected_video_index].selected = true;
            //         key = 'selected'
            //         break;
            //     case 'TEXT':
            //         video_player.textTracks[PlayerSettings.selected_video_index].mode = true;
            //         key = 'mode'
            //         break;
            // }
        }


    }
    static set_aspcet_ratio() {

        var ratio = PlayerSettings.tracks.aspect_ratio[PlayerSettings.selected_aspect_ratio_index].type;

        if (OS == 'tizen') {

            webapis.avplay.setDisplayMethod(ratio);


        } else if (OS == 'android') {
            window.Android.setAspectRatio(ratio)
        }
        else {
            let video = get_el('#video_player');
            if (ratio == 'original') {

                video.style.width = '100%'
                video.style.height = '100%'

            } else {

                let ratioWidth = parseInt(ratio.split(":")[0]);
                let ratioHeight = parseInt(ratio.split(":")[1]);

                let width = video.videoWidth;
                let height = video.videoWidth / (ratioWidth / ratioHeight);

                if (width / height > 1.7) {

                    if (width < window.innerWidth) {
                        width = window.innerWidth;
                        height = window.innerWidth / (ratioWidth / ratioHeight);
                    }

                } else {

                    if (height < window.innerHeight) {
                        height = window.innerHeight;
                        width = window.innerHeight * (ratioWidth / ratioHeight);
                    }
                }

                if (width > window.innerWidth) {
                    width = window.innerWidth;
                    height = window.innerWidth / (ratioWidth / ratioHeight);
                } else if (height > window.innerHeight) {
                    height = window.innerHeight;
                    width = window.innerHeight * (ratioWidth / ratioHeight);
                }

                video.style.width = width + "px";
                video.style.height = height + "px";
            }
        }



    }

    static show() {

        if (PlayerSettings.tracks.subtitle.length) {
            let disable = PlayerSettings.tracks.subtitle.find(item => item.index == -1);
            if (!disable)
                PlayerSettings.tracks.subtitle.unshift({ name: 'disable', index: -1 })
        }

        const player_settings_parent = el({
            attr: {
                class: 'player-settings-parent',
                id: 'player_settings_parent'
            },
            children: [
                {
                    attr: {
                        class: 'player-settings-container',
                        id: 'player_settings_container'
                    },
                    children: [
                        {
                            attr: {
                                class: 'player-settings-scroll',
                                id: 'player_settings_scroll'
                            },
                            render: (parent) => {
                                let arr = Object.keys(PlayerSettings.tracks);

                                arr.forEach(item => {
                                    if (PlayerSettings.tracks[item].length)
                                        append(parent, PlayerSettings.render_items(item));
                                })
                            }
                        }
                    ]
                }
            ]
        })

        append(document.body, player_settings_parent);

        setTimeout(() => {
            player_settings_parent.addClass('show');
        }, 10)
    }
    static hide() {
        let item = get_el('#player_settings_parent');
        if (item) {
            item.removeClass('show');
            setTimeout(() => {
                item.remove();
            }, 400)
        }

    }

    static render_items(type) {

        let item = el({
            attr: {
                class: 'player-settings-item-container',
                id: 'player_settings_item_' + type
            },
            render: (parent) => {
                append(parent, PlayerSettings.render_title(type));
                append(parent, PlayerSettings.render_list(type));
            }
        })

        return item;
    }

    static render_title(type) {
        let name = type;

        switch (type) {
            case 'video':
                name = 'resolutions';
                break;
            case 'aspect_ratio':
                name = 'display mode';
                break;
        }

        let title = el({
            attr: {
                class: 'player-settings-title',
                id: 'player_settings_title_' + type
            },
            innerHTML: name
        })

        return title;
    }

    static render_list(type) {

        let list = el({
            attr: {
                class: 'player-settings-list',
                id: 'player_settings_list_' + type
            },
            render: (parent) => {
                PlayerSettings.tracks[type].forEach(item => {
                    append(parent, PlayerSettings.render_item(item, type));
                })
            }
        })

        return list;
    }

    static render_item(item, type) {

        let is_selected = item.index == PlayerSettings[`selected_${type}_index`];


        if (item.index == -1 && type == 'subtitle') is_selected = !PlayerSettings.show_subtitle;


        let element = el({
            attr: {
                class: `player-settings-item player-settings-item-control ${is_selected ? 'selected' : ''}`,
                type: type == 'subtitle' ? 'text' : type,
                index: item.index,
                open_subtitle: item.open_subtitle || false,
                onclick: PlayerSettings.item_click,
                onmouseenter: PlayerSettings.item_mouse_enter,
            },
            innerHTML: item.name || `${type} ${item.index + 1}`,
        })

        return element;
    }

    static item_click(e) {

        e.stopPropagation();

        let parent = this.parentElement;
        remove_class('selected', parent);
        this.addClass('selected');

        let index = this.getAttribute('index');
        let type = this.getAttribute('type').toUpperCase();
        let open_subtitle = this.getAttribute('open_subtitle');


        if (type == 'TEXT' && index == -1) {
            PlayerSettings.show_subtitle = false;
            PlayerSettings.selected_subtitle_index = index;
        } else if (type == 'ASPECT_RATIO') {
            PlayerSettings.selected_aspect_ratio_index = index;
            PlayerSettings.set_aspcet_ratio();
        }
        else {

            if (open_subtitle == 'true' && type == 'TEXT') {
                PlayerSettings.show_open_subtitle(index)
            } else {
                PlayerSettings.set_track(type, parseInt(index))
            }
        }

    }

    static item_mouse_enter() {

        let index = 0;

        let items = document.getElementsByClassName('player-settings-item-control');

        for (let i = 0; i < items.length; i++) {

            if (items[i] == this) {

                index = i;
                break;

            }
        }


        Control.settings_items.index = index;

        Control.set_current('settings_items')
    }

    static show_open_subtitle(index) {

        let item = PlayerSettings.tracks.subtitle.find(item => item.index == index);

        PlayerSettings.show_subtitle = true;

        PlayerSettings.is_selected_open_subtitle = true;

        PlayerSettings.selected_open_subtitle = {};

        PlayerSettings.selected_subtitle_index = index;

        PlayerSettings.open_subtiles.download(item.file_id, (response) => {

            PlayerSettings.selected_open_subtitle = response || {};
        })
    }


    static langugaes_iso_codes = {
        "en": "English",
        "es": "Spanish",
        "fr": "French",
        "de": "German",
        "it": "Italian",
        "nl": "Dutch",
        "pl": "Polish",
        "pt": "Portuguese",
        "ro": "Romanian",
        "ru": "Russian",
        "sv": "Swedish",
        "tr": "Turkish",
        "zh": "Chinese",
        "el": "Greek",
        "ja": "Japanese",
        "ko": "Korean",
        "ar": "Arabic",
        "bg": "Bulgarian",
        "cs": "Czech",
        "da": "Danish",
        "et": "Estonian",
        "fi": "Finnish",
        "he": "Hebrew",
        "hr": "Croatian",
        "hu": "Hungarian",
        "is": "Icelandic",
        "id": "Indonesian",
        "lv": "Latvian",
        "lt": "Lithuanian",
        "no": "Norwegian",
        "fa": "Persian",
        "sk": "Slovak",
        "sl": "Slovenian",
        "sr": "Serbian",
        "th": "Thai",
        "uk": "Ukrainian",
        "vi": "Vietnamese",
        "am": "Amharic",
        "az": "Azerbaijani",
        "eu": "Basque",
        "bn": "Bengali",
        "bs": "Bosnian",
        "ka": "Georgian",
        "gl": "Galician",
        "gu": "Gujarati",
        "hi": "Hindi",
        "is": "Icelandic",
        "kn": "Kannada",
        "kk": "Kazakh",
        "km": "Khmer",
        "ky": "Kirghiz",
        "lo": "Lao",
        "mk": "Macedonian",
        "ms": "Malay",
        "ml": "Malayalam",
        "mn": "Mongolian",
        "ne": "Nepali",
        "pa": "Punjabi",
        "si": "Sinhalese",
        "so": "Somali",
        "sw": "Swahili",
        "ta": "Tamil",
        "te": "Telugu",
        "ur": "Urdu",
        "uz": "Uzbek",
        "zu": "Zulu",
        "af": "Afrikaans",
        "sq": "Albanian",
        "hy": "Armenian",
        "be": "Belarusian",
        "my": "Burmese",
        "ca": "Catalan",
        "ceb": "Cebuano",
        "ny": "Chichewa",
        "co": "Corsican",
        "eo": "Esperanto",
        "fy": "Frisian",
        "gd": "Gaelic",
        "ka": "Georgian",
        "gu": "Gujarati",
        "ha": "Hausa",
        "haw": "Hawaiian",
        "iw": "Hebrew",
        "ig": "Igbo",
        "jw": "Javanese",
        "kn": "Kannada",
        "pt-BR": "Portuguese (Brazil)",
        "la": "Latin",
        "lb": "Luxembourgish",
        "mk": "Macedonian",
        "mg": "Malagasy",
        "pt-MZ": "Portuguese (Mozambique)",
        "pt-PT": "Portuguese (Portugal)",
        "pt-AO": "Portuguese (Angola)",
        "pt-GW": "Portuguese (Guinea-Bissau)",
        "pt-TL": "Portuguese (Timor-Leste)",
        "pt-ST": "Portuguese (Sao Tome and Principe)",
        "pt-CV": "Portuguese (Cape Verde)",
        "pt-MZ": "Portuguese (Mozambique)",
    }

    static reset_data() {
        PlayerSettings.tracks = {
            audio: [],
            subtitle: [],
            video: [],
            aspect_ratio: [],
        }
        PlayerSettings.selected_video_index = null;
        PlayerSettings.selected_audio_index = null;
        PlayerSettings.selected_subtitle_index = null;
        PlayerSettings.selected_aspect_ratio_index = 0;
        PlayerSettings.show_subtitle = false;
        PlayerSettings.is_selected_open_subtitle = false;
        PlayerSettings.selected_open_subtitle = {};
        PlayerSettings.open_subtiles = null;

        Control.settings_items.index = 0;


    }
}