import { keydown } from "../Remote/Keys";
import { Control } from "../Remote/Pages";
import get_word from "./Words";

function el(obj) {

    if (!obj.tagName) obj.tagName = "div";

    var tag = document.createElement(obj.tagName);

    if (obj.tagName == 'img') {
        tag.addEventListener('load', function () {
            if (this.naturalWidth == 1) {
                this.onload = null;
                if (this.onerror)
                    this.onerror();
            }
        })
    }

    if (obj.innerHTML) tag.innerHTML = obj.innerHTML;

    if (obj.attr) {

        for (var key in obj.attr) {

            if (key.slice(0, 2) == 'on') tag[key] = obj.attr[key];

            else tag.setAttribute(key, obj.attr[key]);

            if (key == 'style' && typeof obj.attr[key] == 'object') {
                var myStyle = '';
                for (property in obj.attr[key]) {
                    myStyle += property + ':' + obj.attr[key][property] + '; ';
                }

                tag.setAttribute(key, myStyle)
            }

        }

    }

    if (obj.children) {

        for (var i = 0; i < obj.children.length; i++) {


            append(tag, el(obj.children[i]))

        }

    }

    if (obj.render && typeof obj.render == "function") {
        obj.render(tag);
    }

    return tag

}
function el_tag(tagName, className, id) {

    var tag = document.createElement(tagName);

    if (className) tag.className = className;

    if (id) tag.id = id;

    return tag;

}

function remove_class(className, parent) {

    if (!className) className = 'active';

    if (!parent) parent = document;

    var active = parent.getElementsByClassName(className);

    var count = active.length;

    for (var i = 0; i < count; i++) {
        active[0].classList.remove(className);
    }

}

function append(parent, children, callback) {
    if (!parent) parent = document.body;

    if (callback && typeof callback === 'function') {
        setTimeout(callback, 500);
    }
    return parent.appendChild(children);
}

function get_el(name) {

    return document.querySelector(name);

}
function get_els(name, index = -1) {

    if (index > -1) return document.querySelectorAll(name)[index];

    return document.querySelectorAll(name);

}

// string prototype
String.prototype.toHHMMSS = function () {
    var sec_num = parseInt(this, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }


    return hours + ':' + minutes + ':' + seconds;
}



// set elements prototype

Element.prototype.addClass = function (name) {
    this.classList.add(name);
}
Element.prototype.removeClass = function (name) {
    this.classList.remove(name);
}
Element.prototype.scroll = function (offset, xy, time) {

    if (!this.offset) this.offset = 0;

    var self = this;

    offset = parseFloat(offset);

    cancelAnimationFrame(this.anim);

    var k = (this.offset - offset) / time;

    if (k < 0) k *= -1;

    self.anim = requestAnimationFrame(function animate() {

        if (self.offset == offset) return

        if (self.offset < offset) {
            self.offset += k
            if (self.offset > offset) self.offset = offset
        } else {
            self.offset -= k
            if (self.offset < offset) self.offset = offset
        }

        self.style.transform = "translate" + xy + "(" + self.offset + "rem)";
        self.anim = requestAnimationFrame(animate);

    });

}

function get_release_date_genre_info(date, genre, duration) {

    if (date) {
        date = date && date.split('-');
        genre = genre && genre.split(',');
        genre = genre && genre.splice(0, 2).join(', ')
    }

    duration = +duration;
    if (duration) {
        const hours = Math.floor(duration / 3600)
        const minutes = Math.floor((duration % 3600) / 60)

        duration = hours > 0 ? `${hours}h ${minutes}min` : `${minutes}min`;
    } else {
        duration = ''
    }

    let month = date && months[date[1] - 1] || '';
    let day = date && date[2] || '';
    let year = date && date[0] || '';

    return `${genre ? `${genre} •` : ''} ${month || ''} ${day ? `${day},` : ''} ${year ? `${year} •` : ''} ${duration}`
}


let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "Octomber", "November", "December"]
function get_current_day() {
    let date = new Date();

    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear();
    return day + ' / ' + months[month] + ' / ' + year;

}
function get_current_time() {
    let date = new Date();

    let hours = date.getHours();
    // let ampm = hours >= 12 ? 'PM' : 'AM';

    let minutes = date.getMinutes();
    minutes = minutes < 10 ? '0' + minutes : minutes;

    return hours + ':' + minutes;

    // hours = hours % 12;
    // hours = hours ? hours : 12;
    // hours = hours < 10 ? '0' + hours : hours;

    // return hours + ':' + minutes + ' ' + ampm;

}

let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
function get_current_day_name() {
    let date = new Date();
    return get_word(days[date.getDay()]);
}

function get_device_px_size() {

    var fontSize = 10; // 1rem = 10px (default) 1920x1080

    var k = window.innerWidth / 1920;

    fontSize = fontSize * k;

    document.documentElement.style.fontSize = fontSize + "px";
}

get_device_px_size();

window.addEventListener('resize', get_device_px_size);


function get_exp_date(time) {

    let date = time ? new Date(time) : new Date();
    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear();

    date = day + ' / ' + months[month] + ' / ' + year;
    return date;
}

function getBundleJsHost() {

    let scripts = document.getElementsByTagName("script");

    for (let i = 0; i < scripts.length; i++) {

        let src = scripts[i].src;

        if (src.indexOf("bundle.js") > -1) return src.split("bundle.js")[0];

    }

    return "";

}



let debounce = 100;

let oldStamp = 0;



export function item_on_whell(event) {

    let date = new Date().getTime();


    if (date - oldStamp < debounce) return;

    oldStamp = date;

    if (event.deltaY > 0) {

        keydown({ keyName: 'down' });

    } else {

        keydown({ keyName: 'up' });

    }

}

export function get_index(that) {

    let childs = that.parentElement.children;

    for (let i = 0; i < childs.length; i++) {

        if (childs[i] == that) {

            return i;

        }
    }


    return 0;

}



export { el, getBundleJsHost, el_tag, get_release_date_genre_info, get_exp_date, remove_class, append, get_el, get_els, get_current_time, get_current_day_name, get_current_day }