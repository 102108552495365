import Requests from "./Requests";
import { getBundleJsHost } from "./Utils";


let OS = "webos";



if (window.tizen) {
    OS = "tizen"
} else if (window.Android) {
    document.body.classList.add('android-style');
    window.Android.setPlayer('VLC');
    OS = "android"
}

function OsInit() {


    if (OS == "tizen") {

        var script = document.createElement("script");

        script.setAttribute("async", "true");
        script.src = "$WEBAPIS/webapis/webapis.js";

        document.head.appendChild(script);

        script.onload = get_device_info;
        script.onerror = get_device_info;

        try {

            tizen.tvinputdevice.registerKeyBatch(
                [
                    "0", "1", "2", "3", "4",
                    "5", "6", "7", "8", "9",
                    "ChannelUp",
                    "ChannelDown",
                    "MediaRewind",
                    "MediaFastForward",
                    "MediaPause",
                    "MediaPlay",
                    "MediaStop",
                    "MediaTrackPrevious",
                    "MediaTrackNext",
                    "MediaPlayPause",
                    "ColorF0Red",
                    "ColorF1Green",
                    "ColorF2Yellow",
                    "ColorF3Blue",
                    "ChannelList",
                    "Info"
                ]);

        } catch (e) {
            //console.log(e);
        }

    } else if (OS == 'android') {
        get_device_info();
    }
    else {

        // get_device_info()
        // return
        var script = document.createElement("script");
        script.onload = get_device_info
        script.onerror = get_device_info;

        script.src = getBundleJsHost() + "/webOSTV.js";

        document.head.appendChild(script);

    }

}



function get_device_info() {


    let localMac = localStorage.getItem('mac');
  

    if (localMac) return localMac;


    if (window.Android) {
        OS = 'android';

        var id = window.Android.getDeviceId();
        // var model = window.Android.getModel()
        // var ip = window.Android.getIP()
        var mac = generateMACAddress('AN', id);

        localStorage.setItem('mac', mac);
        localStorage.setItem('uid', id);


        return
    }

    if (OS == "tizen") {

        var uid = webapis.productinfo.getDuid();

        var mac = webapis.network.getMac()

        if (!mac) {

            setTimeout(() => {
                get_device_info();
            }, 1000)

            return
        }


        localStorage.setItem('uid', uid);

        localStorage.setItem("mac", mac);

    } else {

        if (webOS && webOS.platform && !webOS.platform.unknown) {

            OS = "webos";
            
            webOS.service.request("luna://com.webos.service.connectionmanager", {
                method: "getinfo",
              
                parameters: {
                    subscribe: true,
                },
                onSuccess: function (data) {

                    var mac = data.wifiInfo.macAddress ? data.wifiInfo.macAddress : data.wiredInfo.macAddress;
              
                    
                    if (mac) {
                        localStorage.setItem("mac", mac)
                    } else {


                        get_mac_by_uid_webos()
                    }


                },
                onFailure: function (err) {
                    console.log('onfaileerr', err);
                    
                     get_mac_by_uid_webos()

                }
            })

            function get_mac_by_uid_webos() {

                
                webOS.service.request("luna://com.webos.service.sm", {
                    method: "deviceid/getIDs",
                    parameters: {
                        "idType": ["LGUDID"]
                    },
                    onSuccess: function (data) {

                        var mac = '';
                        
                        if (data.idList && data.idList.length && data.idList[0].idValue) {

                         setRandomMacInWebosDb(data.idList[0].idValue)

                        }
                      
                        localStorage.setItem("mac", mac);

                    },
                    onFailure: function (err) {
                        console.log('err',err);
                        
                        setRandomMacInWebosDb()

                    }
                });

            }



        } else {

            OS = "webos";
            localStorage.setItem("mac", "22:22:22:22:22:23");
            localStorage.setItem("uid", "222222222222");

        }

    }

}


function render_mac(inResponse) {


    var mac_address = "";

    var temp = inResponse.replace(/['-]+/g, '');

    for (var i = 0; i <= 5; i++) {
        mac_address += temp.substr(i * 2, 2);
        if (i < 5)
            mac_address += ":";
    }

    return mac_address;
    // return '22:22:22:22:22:22'
}


function generateMACAddress(type, str) {
    var hash = str
        .split("")

    var acc = 0;
    for (var i = 0; i < hash.length; i++) {
        acc += hash[i].charCodeAt(0);
    }
    var macBytes = [];
    for (var i = 0; i < 5; i++) {
        var byte = (acc >> (i * 8)) & 0xff;
        macBytes.unshift(byte.toString(16).padStart(2, "0"));
    }

    return type + ':' + macBytes.join(":");
}
function app_reload() {

    if (OS == 'android') {
        window.Android.reload()
    } else {
        location.reload()
    }
}



function setRandomMacInWebosDb(uid) {
    
 
    
    webOS.service.request('luna://com.palm.db', {
        method: 'search',
        parameters: {
            query: {
                from: 'com.formaticplayer.app:1',

            },
        },
        onSuccess: function (inResponse) {

            var res = inResponse.results;

         
            

            if (!res.length) return putMacInDb(uid);


            for (var i = 0; i < res.length; i++) {

                if (res[i].sample == 'macAddres') {
                                
                    localStorage.setItem('mac', res[i].mac)

                    return
                }

            }
            // To-Do something
        },
        onFailure: function (inError) {
            console.log('Failed to find the object with query');
            console.log('[' + inError.errorCode + ']: ' + inError.errorText);
            // To-Do something

            registerKind(uid)
            return;
        },
    });

}


function random_uid() {

    if (localStorage.getItem("uid")) return localStorage.getItem("uid");

    var result = '';
    var characters = '0123456789QWERTYUIOPASDFGHJKLZXCVBNM';

    var charactersLength = characters.length;

    for (var i = 0; i < 12; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result

}

function putMacInDb(uid) {


    if(!uid) uid = random_uid();

    var mac = render_mac(uid);

    webOS.service.request('luna://com.palm.db', {
        method: 'put',
        parameters: {
            objects: [
                {
                    _kind: 'com.formaticplayer.app:1',
                    sample: 'macAddres',
                    mac: mac,
                },
            ],
        },
        onSuccess: function (inResponse) {
            console.log('The object(s) is(are) added');
         
            localStorage.setItem('mac', mac);


            // To-Do something
        },
        onFailure: function (inError) {

            // if kind not exist create kind

            console.log('Failed to add the object(s)', inError);

            if (inError.errorCode == -3963) {
                setPermission();
                return
            }

            registerKind(uid);
        },
    });

}

function setPermission() {

    webOS.service.request('luna://com.palm.db', {
        method: 'putPermissions',
        parameters: {
            permissions: [
                {
                    operations: {
                        read: 'allow',
                        create: 'allow',
                        update: 'allow',
                        delete: 'allow',
                    },
                    object: 'com.formaticplayer.app:1',
                    type: 'db.kind',
                    caller: 'com.formaticplayer.app',
                },
            ],
        },
        onSuccess: function (inResponse) {
            console.log('The permission(s) is(are) set');
            // To-Do something

            Requests.request("file:///sys/class/net/eth0/address", function (mac) {
                console.log(mac);
                
            },(err)=>{
                console.log("errror",err);
                
            })
            putMacInDb();
        },
        onFailure: function (inError) {
            console.log('Failed to set the permission(s)');
            console.log('[' + inError.errorCode + ']: ' + inError.errorText);
            // To-Do something
            return;
        },
    });
}

function registerKind(uid) {

    webOS.service.request('luna://com.palm.db', {
        method: 'putKind',

        parameters: {
            id: 'com.formaticplayer.app:1',
            owner: 'com.formaticplayer.app',
            indexes: [
                {
                    name: '4Matic',
                    props: [{ name: 'DeviceRandomMac' }],
                },
            ],
        },
        onSuccess: function (inResponse) {
            console.log('The kind is created');
            console.log(inResponse);
            putMacInDb(uid);
            // To-Do something
        },
        onFailure: function (inError) {
            console.log('Failed to create the kind');
            console.log('[' + inError.errorCode + ']: ' + inError.errorText);
            // To-Do something
            return;
        },
    });
}

export default OsInit
export { OS, app_reload }

