import AppConfig from "../../AppConfig";
import { content } from "../../Utils/Content";


const continueWatchData = {
    movies: null,
    series: null,
}

export default class ContinueWatch {

    static get_data(type) {
        let data = localStorage.getItem(`continue_watch_${type}-${AppConfig.selected_server.id}`);
        if (data) {
            return JSON.parse(data);
        } else {
            return {
                keys: [],
            }
        }
    }
    static get(type) {

        if (!continueWatchData[type]) {
            continueWatchData[type] = ContinueWatch.get_data(type);
        }

        return continueWatchData[type];
    }

    static set({ data, type }) {

        localStorage.setItem(`continue_watch_${type}-${AppConfig.selected_server.id}`, JSON.stringify(data));
    }

    static add(sendedData) {

        const { data, type, selected_movie } = sendedData

        let id = data.id;
        let continue_watch = ContinueWatch.get(type);

        let saved_data;



        if (type == 'series') {
            saved_data = ContinueWatch.add_series({
                data: sendedData,
                selected_movie,
                watched_data: continue_watch[selected_movie.id]
            })
            id = selected_movie.id;

        } else {
            saved_data = ContinueWatch.add_movie(sendedData)
        }


        continue_watch.keys = continue_watch.keys.filter(item => item != id);


        continue_watch[id] = saved_data;

        continue_watch.keys.unshift(id)

        ContinueWatch.set({ data: continue_watch, type: type });

        ContinueWatch.change_data({
            data_type: type,
            data: type == 'series' ? selected_movie : data,
            type: 'add'
        })

    }

    static remove(data) {
        const { data: { id }, type, selected_movie } = data;

        let continue_watch = ContinueWatch.get(type);
        if (type == 'series' && continue_watch[selected_movie.id]) {

            //data, selected_movie 

            delete continue_watch[selected_movie.id][id];

            if (Object.keys(continue_watch[selected_movie.id]).length == 1 && continue_watch[selected_movie.id].last_key) {
                delete continue_watch[selected_movie.id];
                continue_watch.keys = continue_watch.keys.filter(item => item != selected_movie.id);
            }
        } else {
            delete continue_watch[id];
            continue_watch.keys = continue_watch.keys.filter(item => item != id);
        }


        ContinueWatch.set({ data: continue_watch, type: type });

        ContinueWatch.change_data({
            data_type: type,
            data: type == 'series' ? selected_movie : data,
            type: 'remove'
        })
    }

    static add_movie(data) {
        const { current_time, duration } = data


        return {
            current_time: current_time,
            progress: (current_time / duration) * 100,
        }
    }
    static add_series({ data, watched_data }) {

        if (!watched_data) watched_data = {}

        const { data: { id, season, episode_num }, current_time, duration } = data;

        let key = `${season}-${episode_num}`

        watched_data[key] = {
            current_time: current_time,

            progress: (current_time / duration) * 100,
        }

        watched_data.keys = watched_data.keys || [];

        watched_data.keys.push(id)

        watched_data.last_key = key;

        return watched_data


    }


    static is_watched(id, type) {
        let continue_watch = ContinueWatch.get(type);
        return continue_watch[id]
    }

    static get_watched_index(id, type) {
        let continue_watch = ContinueWatch.get(type);
        return continue_watch.keys.findIndex(item => item == id)
    }

    static change_data({ data_type, data, type }) {

        content[data_type].data['continue watched'].items = content[data_type].data['continue watched'].items.filter(item => item.id != data.id)
        if (type == 'add') {
            content[data_type].data['continue watched'].items.unshift(data)
        }
    }
}