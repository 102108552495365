
import LockCategories from "../LockCategories/LockCategories";
import Move from "../Move/Move";
import MoveChannels from "../MoveChannels/MoveChannels";
import { ChangePage, Control } from "../Remote/Pages";
import { el, get_el, remove_class } from "../Utils/Utils";
import get_word from "../Utils/Words";

import './CategoryItem.scss'
import ImageResizer from "./ResizeImage/ImageResizer";


export default class CategoryItem {
    constructor ({ data, index = 0, callback, is_selected = false, type = '' }) {
        this.data = data;
        this.index = index;
        CategoryItem.callback = callback || null
        this.is_selected = is_selected,
            this.is_locked = false;
        this.page_type = type;
        this.text_scroll = '';
    }
    static callback
    init() {
        this.is_locked = LockCategories.is_locked(this.data.category_id, this.page_type);

        this.text_scroll = this.data.category_name.length > 17 ? 'scroll-text' : '';
        return this.render();
    }
    render() {

        const that = this;

        let cat_icon = this.data.category_image || require('../images/all.png');
        const category_item = el({
            attr: {
                class: `category-item category-item-control ${this.is_selected ? 'selected' : ''}${this.is_locked ? ' locked' : ''}`,
                id: `category_item-${this.data.category_id}`,
                cat_id: this.data.category_id,
                index: this.index,
                page_type: this.page_type,
                cat_name: this.data.category_name,
                onclick: function () {
                    that.item_click(this)
                },
                onmouseenter: this.mouseEnter
            },
            children: [
                {
                    attr: {
                        class: 'category-lock-icon'
                    }
                },
                {
                    attr: {
                        class: 'category-item-icon opacity-0',
                        id: this.data.category_id,
                    },
                    render: (parent) => {

                        setTimeout(async () => {
                            let icon = await ImageResizer({ src: cat_icon, width: 74, height: 74, type: 'image/png', id: this.data.category_id })
                            if (icon)
                                parent.appendChild(icon)
                            parent.classList.remove('opacity-0')
                        }, 500)
                    }
                },

                {
                    attr: {
                        class: 'category-item-name-container',
                    },
                    children: [
                        {
                            attr: {
                                class: `category-item-name ${this.text_scroll}`,
                            },
                            innerHTML: get_word(this.data.category_name)
                        }
                    ]
                }
            ]
        })

        return category_item;
    }
    item_click(item) {

        if (Move.move_mode || MoveChannels.move_mode) return

        if (LockCategories.is_locked(item.getAttribute('cat_id'), item.getAttribute('page_type'))) {
            const that = this;
            ChangePage('ParentalCode', {
                callback: () => {
                    that.change_category(item)
                }
            })
        } else {
            this.change_category(item)
        }


    }
    change_category(that) {


        remove_class('selected', get_el('#categories_block'))
        that.addClass('selected')
        if (CategoryItem.callback)
            CategoryItem.callback(that)
    }


    mouseEnter() {

        if (Move.move_mode || MoveChannels.move_mode) return

        let parent = this.parentElement;

        let index = 0;

        for (let i = 0; i < parent.children.length; i++) {

            if (parent.children[i] == this) {

                index = i;

                break;

            }
        }


        Control.categories.index = index;

        Control.set_current('categories')


    }
}