import './Index.scss'
import SplashScreen from './SplashScreen/SplashScreen'
import OsInit, { OS } from './Utils/Os'
import Requests from './Utils/Requests'
import Xtream from './Utils/Xtream'
import {  get_selected_playlist_content, get_categories, getDemoPlaylist } from './Utils/Content'
import { ChangePage } from './Remote/Pages'
import AppConfig from './AppConfig'
import { socketSetup } from './Utils/Socket'


export let xtream_instance;


let login_request_retry_count = 0;
let retry_count = 0;
let timeout = null;
let deviceLoginTimeout = null;

window.addEventListener('load', () => {

    document.body.style.backgroundImage = `url(${AppConfig.background})`;

    SplashScreen.show();

    retry_count = 0;

    socketSetup();

    OsInit();

    device_login();
})

async function device_login() {

    clearTimeout(timeout);


    let mac = localStorage.getItem('mac');

    if (!mac) {
   
        deviceLoginTimeout =  setTimeout(device_login, 500);
   
        return
   
    }

    clearTimeout(deviceLoginTimeout);

   if(mac.indexOf('"') != -1){

        mac = replaceString(mac, '"', '');
   }
   
   try{

    let device_token = await new Promise((resolve, reject) => {

        Requests.post({
            url: Requests.login_url(),
            data: {
                mac: mac,
                platform: OS
            }
        }, resolve, reject)
        
    })

    localStorage.setItem('token', device_token);

    let profile = await  get_device_profile();

    AppConfig.servers = profile.rows[0].playlists || [];


    if(!AppConfig.servers.length){
        
        AppConfig.servers = getDemoPlaylist();
       
    }

    const isGeted = await get_playlist_data(AppConfig.servers)

    console.log(isGeted,'isGeted');
    
    // for watching  download process
    timeout = setTimeout(() => {

    if (isGeted && !isGeted.error) {
    
        ChangePage('Menu'); 
    
    } else {
    
            ChangePage('Info', {message:isGeted.message}, true, true);
    }

    SplashScreen.hide();

   },1000)

   }catch(e){

    if(login_request_retry_count < 3){

        login_request_retry_count++;
        device_login();

    }else{

        ChangePage('Info', {}, true, true);
        SplashScreen.hide();
    }
  
   }

}


export async function get_device_profile() {
    
    const _device_profile = new Promise((resolve, reject) => {
    
        Requests.get({
       
            url: Requests.profile(),
       
        }, resolve, reject)
    
    })

    let profile = await _device_profile;

    return profile;

}


async function get_playlist_data(data = []) {

    try {
        
        if (!data.length) return false;

        let selected_playlist = localStorage.getItem('selected_playlist');
        
        let playlist = null;

        if (selected_playlist) {
         
            selected_playlist = JSON.parse(selected_playlist);
         
            playlist = data.find(playlist => playlist.id == selected_playlist.id);

        }

        if (!playlist) {

            playlist = data[retry_count];

            if(!playlist) return false;

            localStorage.setItem('selected_playlist', JSON.stringify({
               
                username: playlist.username,
               
                password: playlist.password,
               
                host: playlist.server.host,
               
                id: playlist.id,
               
                device_id: playlist.device_id,
               
                server_id: playlist.server_id,
               
                server_name: playlist.server.name
            
            }));

        }

        set_appconfig_status()

        const { server: { host }, username, password } = playlist;
      
        xtream_instance = new Xtream({ host, username, password });
      
        await xtream_instance.init();

        await get_categories(playlist.server_id);

        await get_selected_playlist_content()

        retry_count = 0;

        return true;

    } catch (e) {
        console.log(e);
        

        localStorage.removeItem('selected_playlist');
        
        if (data.length && retry_count < data.length -1) {

            retry_count++;

            return await get_playlist_data(AppConfig.servers);


        } else {

            return { error: true, message: e || 'error' };
        
        }

    }
}


export function set_appconfig_status() {
    
    AppConfig.selected_server = JSON.parse(localStorage.getItem('selected_playlist'));

    AppConfig.locked_categories = localStorage.getItem(`locked_categories-${AppConfig.selected_server.id}`) || '{}';
    
    AppConfig.locked_categories = JSON.parse(AppConfig.locked_categories);

    AppConfig.hided_categories = localStorage.getItem(`hided_categories-${AppConfig.selected_server.id}`) || '{}';
    
    AppConfig.hided_categories = JSON.parse(AppConfig.hided_categories);

    AppConfig.hided_data = {
        live: {},
        movies: {},
        series: {},
        items: {},
    };





}

function replaceString(string, search, replace) {
    return string.split(search).join(replace);
}