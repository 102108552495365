
import { xtream_instance } from "..";
import CategoryItem from "../Components/CategoryItem";
import LiveItem from "../Components/LiveItems/LiveItem";
import NotHave from "../Components/NotHave/NotHave";
import Favorite from "../Components/Favorite/Favorite";
import Player from "../Player/Player";
import { keydown, move } from "../Remote/Keys";
import pages, { ChangeControl, ChangePage, Control } from "../Remote/Pages";
import { append, el, get_current_day, get_current_time, get_el, item_on_whell } from "../Utils/Utils";
import get_word from "../Utils/Words";
import './Live.scss'
import AppConfig from "../AppConfig";

import Move from "../Move/Move";
import ImageResizer from "../Components/ResizeImage/ImageResizer";
import Search from "../Search/Search";
import MoveChannels from "../MoveChannels/MoveChannels";

export default class Live {
    constructor (data, category = 'all') {
        Live.data = data;
        Live.selected_category = category;

    }
    static data;
    static root = document.getElementById('root');
    static selected_category;
    static selected_channel = null;
    static set_time_interval = null;
    static live_player = null;
    static hide_controle_timeout = null;
    static selected_channel_epg = [];
    static set_epg_timeout = null;
    static change_channel_by_number_timeout = null;
    static categories_item_count = 11;
    static channel_item_count = 11;
    static moved_channels_data;
    static is_show_control = false;
    static full_screen_channels_mode = false;


    static colored_btns = [


        {
            color: 'green',
            cat_name: 'move',
            channel_name: 'move',
        },
        {
            color: 'yellow',
            channel_name: 'favorites'

        },
        {
            color: 'blue',
            cat_name: 'lock',
            channel_name: 'menu'
        }
    ]
    init() {

        this.render();

        let item = get_el('.live-item-control');

        // first selected item
        if(item)    item.click();
         
    }
    render() {
        const live_page = el({
            attr: {
                class: 'live-page',
                id: 'live_page'
            },
            children: [
                {
                    attr: {
                        class: 'live-page-left-side',
                        id: 'live_page_left_side'
                    },
                    children: [
                        {
                            attr: {
                                class: 'live-categories-block',
                                id: 'live_categories_block'
                            },
                            children: [
                                {
                                    attr: {
                                        class: 'live-categories-content',
                                        id: 'categories_block',
                                        onwheel: item_on_whell
                                    },
                                    render: (parent) => {
                                        let data = Live.data.data;
                                        let categories = Live.data.categories;
                                        let count = categories.length > Live.categories_item_count ? Live.categories_item_count : categories.length;


                                        let start = categories.findIndex(item => item.name == Live.selected_category);

                                        if (Live.selected_category == 'all') { start = 0 }


                                        let end = start + count;

                                        if (end > categories.length) {
                                            end = categories.length;
                                            start = end - count;
                                        }
                                        start = start < 0 ? 0 : start;
                                        for (let i = start; i < end; i++) {

                                            let category = categories[i]

                                            let item = new CategoryItem({
                                                data: data[category.name],
                                                index: i,
                                                callback: Live.change_category,
                                                is_selected: categories[i].name == Live.selected_category,
                                                type: 'live'
                                            });
                                            append(parent, item.init())
                                        }
                                    }
                                }
                            ]
                        },
                        {
                            attr: {
                                class: 'live-channels-block',
                                id: 'live_channels_block'
                            },
                            children: [
                                {
                                    attr: {
                                        class: 'live-channels-content',
                                        id: 'live_channels_content',
                                        onwheel: item_on_whell
                                    },
                                    render: (parent) => {
                                        let data = Live.data.data[Live.selected_category].items;
                                        let count = data.length > Live.channel_item_count ? Live.channel_item_count : data.length;

                                        
                                        for (let i = 0; i < count; i++) {


                                            let item = Live.render_channel_item({ data: data[i], index: i })

                                            if (item == 'hided') continue;
                                            append(parent, item)
                                        }
                                    }
                                }
                            ]
                        },
                    ]
                },
                {
                    attr: {
                        class: 'live-player-block',
                    },
                    children: [
                        {
                            attr: {
                                class: 'live-player-top'
                            },
                            children: [
                                {
                                    attr: {
                                        class: 'live-player-top-day',
                                        id: 'live_player_top_day'
                                    },
                                    innerHTML: get_current_day()
                                },
                                {
                                    attr: {
                                        class: 'live-player-top-time',
                                        id: 'live_player_top_time'
                                    },
                                    innerHTML: get_current_time()
                                }
                            ]
                        },
                        {
                            attr: {
                                class: 'live-player-parent',
                                id: 'player_parent',
                            }
                        },
                        {
                            attr: {
                                class: 'live-player-selected-channel-block',
                                id: 'live_player_selected_channel_block'
                            },
                            children: [
                                {

                                    attr: {
                                        class: 'selected-channel-number',
                                        id: 'selected_channel_number'
                                    },
                                    innerHTML: '1'

                                },
                                {
                                    attr: {
                                        class: 'selected-channel-logo opacity-0',
                                        id: 'selected_channel_logo',

                                    }
                                },
                                {
                                    attr: {
                                        class: 'selected-channel-name-epg-block',
                                    },
                                    children: [
                                        {
                                            attr: {
                                                class: 'selected-channel-name',
                                                id: 'selected_channel_name'
                                            },
                                            innerHTML: 'channel name'
                                        },
                                        {
                                            attr: {
                                                class: 'channel-epg-container current',
                                            },
                                            children: [
                                                {
                                                    attr: {
                                                        class: 'selected-channel-epg-time',
                                                        id: 'current_epg_time'
                                                    },
                                                    innerHTML: '--:--'
                                                },
                                                {
                                                    attr: {
                                                        class: 'selected-channel-epg',
                                                        id: 'current_epg',
                                                    },
                                                    innerHTML: get_word('program not found')
                                                },
                                            ]
                                        },
                                        {
                                            attr: {
                                                class: 'channel-epg-container',
                                            },
                                            children: [
                                                {
                                                    attr: {
                                                        class: 'selected-channel-epg-time',
                                                        id: 'next_epg_time'
                                                    },
                                                    innerHTML: '--:--'
                                                },

                                                {
                                                    attr: {
                                                        class: 'selected-channel-epg',
                                                        id: 'next_epg',
                                                    },
                                                    innerHTML: get_word('program not found')
                                                }
                                            ]
                                        },

                                    ]
                                },

                            ]
                        },
                        {
                            attr: {
                                class: 'colored-btns-info-block',
                            },
                            render: (parent) => {

                                Live.colored_btns.forEach(item => {
                                    append(parent, el({
                                        attr: {
                                            class: 'colored-btn-item',
                                            id: `colored_btn-${item.color}`,
                                            type: item.color,
                                            onclick: Live.colored_btn_click
                                        },
                                        children: [
                                            {
                                                attr: {
                                                    class: `colored-btn-color ${item.color}`,
                                                }
                                            },
                                            {
                                                attr: {
                                                    class: 'colored-btn-name',
                                                }

                                            }
                                        ]
                                    }))
                                })
                            }
                        }

                    ]
                }
            ]
        })


        append(Live.root, live_page);

        this.set_time();
    }
    set_time() {
        Live.set_time_interval = setInterval(() => {
            get_el('#live_player_top_time').innerHTML = get_current_time();
            get_el('#live_player_top_day').innerHTML = get_current_day()
        }, 30000)
    }
    static get_selected_channel(index = 0) {
        let selected_channel = Live.data.data[Live.selected_category].items[index];
        if (Live.selected_channel) {
            if (Live.selected_channel.stream_id == selected_channel.stream_id) {
                Live.set_full_screen();
                return
            }
        }

        let resolution = get_el('#live_player_video_resolution');
        if (resolution) resolution.innerHTML = '';

        Live.selected_channel = selected_channel;

        if (!Live.live_player) {
            Live.live_player = new Player()
        }

        Live.live_player.init(Live.selected_channel, 'live')

        if (AppConfig.xtream_epg == 1)
            Live.get_selected_channel_epg();

        Live.set_selected_channel_info(index);

    }
    static get_selected_channel_epg() {
        clearTimeout(Live.set_epg_timeout);
        Live.selected_channel_epg = [];
        let url = xtream_instance.getEpg(Live.selected_channel.stream_id);
        xtream_instance.request(url, (data) => {
            Live.selected_channel_epg = data.epg_listings;

            Live.set_current_next_epg();
        })
    }
    static set_current_next_epg() {
        clearTimeout(Live.set_epg_timeout);
        let now = parseInt(Date.now() / 1000);
        for (let i = 0; i < Live.selected_channel_epg.length; i++) {
            let item = Live.selected_channel_epg[i];
            if (item.start_timestamp < now && item.stop_timestamp > now) {
                if (!get_el('#current_epg')) return;
                get_el('#current_epg').innerHTML = Live.decode_epg(item.title);
                get_el('#current_epg_time').innerHTML = Live.get_epg_time(item.start);
                get_el('#live_player_program_name').innerHTML = `${Live.get_epg_time(item.start)} ${Live.decode_epg(item.title)} `;

                get_el('#next_epg').innerHTML = Live.decode_epg(Live.selected_channel_epg[i + 1].title);
                get_el('#next_epg_time').innerHTML = Live.get_epg_time(Live.selected_channel_epg[i + 1].start);

                break;
            }
        }

        Live.set_epg_timeout = setTimeout(Live.set_current_next_epg, 60000)

    }
    static get_epg_time(time) {
        let date = new Date(time);

        let hours = date.getHours();
        let minutes = date.getMinutes();

        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }

        return hours + ':' + minutes;
    }
    static decode_epg(str) {
        return decodeURIComponent(atob(str).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }
    static async set_selected_channel_info(index) {

        let image = get_el('#selected_channel_logo')


        let icon = get_el('#live_player_icon');


        image.classList.add('opacity-0')

        icon.classList.add('opacity-0')

        let img = await ImageResizer({ src: Live.selected_channel.stream_icon, width: 130, height: 130, type: 'image/png' });

        icon.innerHTML = '';

        image.innerHTML = '';

        image.appendChild(img);
        // console.log(image);
        icon.appendChild(img.cloneNode());

        image.classList.remove('opacity-0')

        icon.classList.remove('opacity-0')

        get_el('#selected_channel_name').innerHTML = Live.selected_channel.name;
        get_el('#selected_channel_number').innerHTML = +index + 1;


        let channel_name = get_el('#live_player_channel_name');
        let program_name = get_el('#live_player_program_name');
        let channel_number = get_el('#live_player_channel_number');

        get_el('#current_epg_time').innerHTML = '--:--';
        get_el('#current_epg').innerHTML = get_word('program not found');


        get_el('#next_epg').innerHTML = get_word('program not found');
        get_el('#next_epg_time').innerHTML = '--:--'





        channel_name.innerHTML = Live.selected_channel.name;
        program_name.innerHTML = `--:-- ${get_word('program not found')}`;
        channel_number.innerHTML = +index + 1;
    }
    static change_category(that) {



        let cat_name = that.getAttribute('cat_name');

        if (cat_name == Live.selected_category && cat_name != 'search') return;

        Live.selected_category = cat_name;

        let parent = get_el('#live_channels_content');
        parent.innerHTML = '';

        Control.channels.index = 0;

        if (Live.selected_category == 'search') {
            Live.search();
        }

        let data = Live.data.data[Live.selected_category].items;

        let count = data.length > Live.channel_item_count ? Live.channel_item_count : data.length;

        if (count == 0) {
            NotHave.render(parent, 'not have channels');

        } else {
            for (let i = 0; i < count; i++) {
                let item = Live.render_channel_item({
                    data: data[i],
                    index: i
                })
                if (item == 'hided') continue;
                append(parent, item)
            }
        }



    }

    static re_render_category_item({ index, direction }) {

        let data = Live.data.data[Live.data.categories[index].name];


        let item = new CategoryItem({
            data: data,
            index,
            callback: Live.change_category,
            is_selected: data.name == Live.selected_category,
            type: 'live'
        });
        let parent = get_el('#categories_block');
        if (direction == 'up') {
            parent.insertBefore(item.init(), parent.firstChild);
            parent.lastChild.remove()
        } else {
            parent.appendChild(item.init());
            parent.firstChild.remove()
        }

    }
    static render_channel_item({ data, index }) {

        let item = new LiveItem(data, index);

        return item.init()
    }
    static re_render_channel_item({ index, direction }) {


        let item = Live.render_channel_item({
            data: Live.data.data[Live.selected_category].items[index],
            index: index
        });


        let parent = get_el('#live_channels_content');

        if (direction == 'up') {
            parent.insertBefore(item, parent.firstChild);
            parent.lastChild.remove()
        } else {
            parent.appendChild(item);
            parent.firstChild.remove()
        }


    }


    static set_full_screen() {


        if (pages.current == 'Search')
            Search.close_search();

        Player.set_full_screen()
        Control.set_current('full_screen')
    }
    static hide_full_screen() {
        Player.hide_full_screen()
        Control.set_current('channels')
    }


    static change_channel_by_number(number) {
        let item = get_el('#channel_number');

        if (item.innerHTML.length > 6) return;

        clearTimeout(Live.change_channel_by_number_timeout);

        item.innerHTML += number;
        item.addClass('show');

        Live.change_channel_by_number_timeout = setTimeout(() => {
            let index = +item.innerHTML - 1;

            item.innerHTML = '';
            item.removeClass('show');

            let data = Live.data.data[Live.selected_category].items;

            if (index > data.length - 1 || index < 0)
                return;

            Live.select_channel_by_number(index)

        }, 1000)
    }

    static select_channel_by_number(index) {
        let data = Live.data.data[Live.selected_category].items;
        Live.selected_channel = data[index];

        let start = index;
        let end = start + Live.channel_item_count;

        if (end > data.length) {
            end = data.length
            start = end - Live.channel_item_count;
            start = start < 0 ? 0 : start;
        }

        let parent = get_el('#live_channels_content');
        parent.innerHTML = '';
        let controle_index = 0;

        for (let i = start; i < end; i++) {

            let item = new LiveItem(data[i], i);
            append(parent, item.init())
            if (data[i].stream_id == Live.selected_channel.stream_id) {
                controle_index = i - start;
            }
        }

        Control.channels.index = controle_index;
        Live.live_player.init(Live.selected_channel, 'live')
        if (AppConfig.xtream_epg == 1)
            Live.get_selected_channel_epg();

        Live.set_selected_channel_info(index);

    }
    static show_control() {
        clearTimeout(Live.hide_controle_timeout);

        let item = get_el('#player_parent');

        if (!item) return

        item.addClass('show')

        Live.is_show_control = true;

        Live.hide_controle_timeout = setTimeout(() => {
            Live.hide_control()
        }, 5000)
    }

    static hide_control() {

        clearTimeout(Live.hide_controle_timeout);

        let item = get_el('#player_parent');

        item.removeClass('show');

        Live.is_show_control = false;

    }
    static colored_btn_click() {
        let type = this.getAttribute('type');

        if (Move.move_mode || MoveChannels.move_mode) return

        keydown({ keyName: type })

        // switch (type) {
        //     case 'red':
        //         break;
        //     case 'green':
        //         break;
        //     case 'yellow':
        //         // Live.set_full_screen();

        //         break;
        //     case 'blue':
        //         break;
        // }
    }
    static add_remove_favorite(that) {

        let id = that.getAttribute('stream_id');

        let is_favorite = Favorite.is_favorite(id, 'live') >= 0;
        that.classList.toggle('favorite');
        if (is_favorite) {
            Favorite.remove(id, 'live')
        } else {
            Favorite.add(id, 'live')
        }
    }

    static move_category(item) {

        let index = item.getAttribute('index');

        if (index < 4) return

        ChangePage('Move', { data_type: 'live', item })

    }
    static move_channels(item) {
        ChangePage('MoveChannels', { item, selected_category: Live.selected_category })
    }

    static colored_btns_for_category(index) {

        Live.colored_btns.forEach(item => {

            let parent = get_el(`#colored_btn-${item.color}`);

            if (index < 4) {

                parent.addClass('d-none')

                return
            }

            if (item.color == 'yellow') {

                parent.addClass('d-none')

                return
            }

            parent.removeClass('d-none')

            let name = item.cat_name;

            let element = parent.querySelector('.colored-btn-name');

            element.innerHTML = name;

        })

    }
    static colored_btns_for_channels() {


        let dont_show_move = ['search', 'recently watched'].indexOf(Live.selected_category) > -1;

        Live.colored_btns.forEach(item => {

            let parent = get_el(`#colored_btn-${item.color}`)

            if (item.color == 'green' && dont_show_move) {

                parent.addClass('d-none')

            } else {

                parent.removeClass('d-none')

                let name = item.channel_name;

                let element = parent.querySelector('.colored-btn-name');

                element.innerHTML = name;
            }



        })
    }

    static search() {
        ChangePage('Search', { type: 'live', callback: Live.search_callback })
    }
    static search_callback() {
        let data = Live.data.data[Live.selected_category].items;
        let parent = get_el('#live_channels_content');
        parent.innerHTML = '';

        let count = data.length > Live.channel_item_count ? Live.channel_item_count : data.length;

        if (count == 0) {
            NotHave.render(get_el('#live_channels_content'), 'not have channels');
            return
        } else {
            for (let i = 0; i < count; i++) {
                let item = Live.render_channel_item({
                    data: data[i],
                    index: i
                });

                append(parent, item)
            }
        }


    }



    // show channels in fullScreen mode
    static show_channels_in_full_screen() {

        // set class for show channels and categories in full screen 

        let parent = get_el('#live_page_left_side');

        parent.classList.add('full-screen');

        Control.set_current('channels');

        Live.full_screen_channels_mode = true;

    }

    static hide_channels_in_full_screen() {


        let parent = get_el('#live_page_left_side');

        parent.classList.remove('full-screen');

        Control.set_current('full_screen');

        Live.full_screen_channels_mode = false;

    }


    static destroy() {

        clearTimeout(Live.set_epg_timeout);
        clearInterval(Live.set_time_interval);
        clearTimeout(Live.hide_controle_timeout);
        clearTimeout(Live.change_channel_by_number_timeout);

        Live.live_player?.destroy();
        Live.live_player = null;
        Live.selected_channel = null;

    }
}