import { keydown, move } from "../Remote/Keys";
import pages, { ChangeControl, ChangePage } from "../Remote/Pages";
import { content } from "../Utils/Content";
import { append, el, get_el, remove_class } from "../Utils/Utils";
import './Search.scss';
import Control from "./SearchControl";


export default class Search {
    constructor ({ type, callback }) {
        this.type = type;
        this.data = content[type].data.all.items;
        this.keyboard_type = 'letters';
        this.selected = 'abc';
        this.selected_lang = localStorage.getItem('selected_keyboard_lang') || 'en',
            this.callback = callback;
        this.uppercase = false;
        this.keyboard = {
            top_controls: ['abc', 'lang', '&123'],
            letters: {
                en: [
                    ['a', 'b', 'c', 'd', 'e', 'f', 'g'],
                    ['h', 'i', 'j', 'k', 'l', 'm', 'n'],
                    ['o', 'p', 'q', 'r', 's', 't', 'u'],
                    ['v', 'w', 'x', 'y', 'z', ':', '‘'],
                ],
                ar: [
                    ['ض', 'ص', 'ث', 'ق', 'ف', 'غ', 'ع'],
                    ['ه', 'خ', 'ح', 'ج', 'ش', 'س', 'ي'],
                    ['ب', 'ل', 'ا', 'ت', 'ن', 'م', 'ك'],
                    ['ة', 'ء', 'ظ', 'ط', 'ذ', 'د', 'ز'],
                    ['ر', 'و', 'ى', 'ؤ', ':', '.', '?']

                ],


            },
            numbers: [
                ['1', '2', '3', '4', '5', '6', '7'],
                ['8', '9', '0', '@', '_', '.', ','],
                ['!', '#', '$', '%', '&', '*', '?'],
                ['+', '-', '/', '(', ')', '[', ']'],
            ],
            bottom_controls: ['space', 'del', 'clear', 'search']
        };

        this.search_timeout = null;

        content[type].data.search.items = [];
    }
    init() {


        this.render();
    }
    render() {

        const that = this;
        const search_parent = el({
            attr: {
                class: 'search-parent',
                id: 'search_parent'
            },
            children: [
                {
                    tagName: 'input',
                    attr: {
                        class: `search-input`,
                        id: 'search_input',
                        onclick: function () {
                            this.blur()
                        },
                        onchange: function () {
                            that.search_items(this);
                        },
                    }
                },
                {
                    attr: {
                        class: 'search-keyboard',
                        id: 'search_keyboard'
                    },
                    render: (parent) => {
                        let keyboard = that.render_keyboard();

                        append(parent, keyboard);
                    }
                }
            ]
        })

        append(document.body, search_parent);
    }

    render_keyboard() {
        let keyboard_top = this.render_keyboard_row({
            rowIndex: 0,
            row: this.keyboard.top_controls,
            type: 'top'
        });

        let keyboard = this.keyboard[this.keyboard_type]
        if (this.keyboard_type == 'letters') {
            keyboard = this.keyboard[this.keyboard_type][this.selected_lang]
        }

        let keyboard_items = keyboard.map((row, index) => {

            return this.render_keyboard_row({
                rowIndex: index + 1,
                row,
                type: this.keyboard_type,
            })
        })


        let bottomIndex = this.selected_lang == 'ar' ? 6 : 5;

        let keyboard_bottom = this.render_keyboard_row({
            rowIndex: bottomIndex,
            row: this.keyboard.bottom_controls,
            type: 'bottom'
        });

        return el({
            attr: {
                class: 'search-keyboard-container'
            },
            render: (parent) => {

                append(parent, keyboard_top);
                keyboard_items.forEach((item) => {
                    append(parent, item);

                })
                append(parent, keyboard_bottom);
            }
        })
    }
    render_keyboard_row({ row, type, rowIndex }) {

        const that = this;

        return el({
            attr: {
                class: `search-keyboard-row ${type} search-keyboard-control`,
            },
            render: (parent) => {

                row.forEach((element, index) => {


                    let item = that.render_keyboard_item({
                        rowIndex,
                        index,
                        item: element,
                        selected: that.selected == element,
                        type: type
                    });

                    append(parent, item)
                });
            }
        })
    }

    render_keyboard_item({ item, selected, type, rowIndex, index }) {

        const that = this;

        let name = item == 'del' || item == 'lang' ? ' ' : item;

        if (type != 'bottom')
            name = this.uppercase ? name.toUpperCase() : name;

        return el({
            attr: {

                class: `search-keyboard-item ${item == '&123' ? 'numbers' : item} ${selected ? ' selected' : ''}`,
                type: item,
                simbol: name,
                onclick: function () {
                    that.item_click(this)
                },
                onmouseenter: function () {
                    that.item_mouse_enter({
                        rowIndex,
                        index
                    })
                },
            },
            innerHTML: name,
        })
    }

    item_click(that) {

        let search_input = document.getElementById('search_input');

        if (this.selected_lang == 'ar') {
            search_input.classList.add('right')
        } else {
            search_input.classList.remove('right')
        }

        if (!search_input) return;

        let type = that.getAttribute('type');
        let simbol = that.getAttribute('simbol');

        let parent = get_el('#search_keyboard')

        switch (type) {
            case 'del':
                search_input.value = search_input.value.slice(0, -1);
                break;
            case 'clear':
                search_input.value = '';
                break;
            case 'space':
                search_input.value += ' ';
                break;
            case 'search':
                Search.close_search();
                return
            case 'abc':
            case '&123':

                this.keyboard_type = 'letters';
                this.selected = type;


                if (type == '&123') this.keyboard_type = 'numbers';
                else if (type == 'abc') this.uppercase = !this.uppercase;


                parent.innerHTML = '';
                append(parent, this.render_keyboard());
                move();
                return

            case 'lang':
                this.selected_lang = this.selected_lang == 'en' ? 'ar' : 'en';
                localStorage.setItem('selected_keyboard_lang', this.selected_lang);

                parent.innerHTML = '';

                append(parent, this.render_keyboard());
                move();
                return

            default:
                search_input.value += simbol;
                break;
        }


        search_input.onchange();
    }

    item_mouse_enter({ rowIndex = 0, index = 0 }) {

        ChangeControl('Search')


        Control.search.row = rowIndex;

        Control.search.index = index;

        move();

    }
    search_items(item) {

        clearTimeout(this.search_timeout)

        const that = this;
        this.search_timeout = setTimeout(() => {
            let search = item.value.toLowerCase();

            let search_result = that.data.filter((item) => {
                return item.name.toLowerCase().includes(search);
            });

            search_result.sort((a, b) => { return a.name.indexOf(search) - b.name.indexOf(search) })
            content[that.type].data.search.items = search_result;

            if (that.callback) that.callback()

        }, 500);
    }

    static close_search() {

        ChangePage(pages.activePage, {}, true, false)

        keydown({ keyName: 'right' })
    }

}