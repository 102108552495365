

import { get_els, remove_class } from "../Utils/Utils";
import { move } from "../Remote/Keys";
import Search from "./Search";



const Control = {
    current: "search",
    previous: "",
    set_current: function (current) {

        if (this.current != current)
            this.previous = this.current;

        this.current = current;

        move();
    },
    set_previous: function () {
        this.current = this.previous;
        move();
    },

    search: {
        index: 0,
        row: 0,
        items: document.getElementsByClassName("search-keyboard-control"),
        up: function () {
            return
            Control.set_current('settings');
        },
        left: function () {
            if (this.index == 0) {
                this.index = this.items[this.row].childNodes.length - 1;
            } else {
                this.index--;
            }
            this.move();
        },
        right: function () {
            if (this.index == this.items[this.row].childNodes.length - 1) {
                this.index = 0;
            } else {
                this.index++;
            }

            this.move();
        },
        up: function () {
            if (this.row == 0) {
                this.row = this.items.length - 1;
            } else {
                this.row--;
            }

            this.check_index();
            this.move();
        },
        down: function () {

            if (this.row == this.items.length - 1) {

                this.row = 0;

            } else {

                this.row++;

            }

            this.check_index();

            this.move();

        },

        ok: function () {

            this.items[this.row].childNodes[this.index].click();

        },

        back: function () {

            Search.close_search();

        },

        move: function () {

            remove_class('active');

            this.items[this.row].childNodes[this.index].addClass('active');

        },
        check_index: function () {

            if (this.row == 0) {

                if (this.index <= 1) {

                    this.index = 0;

                } else if (this.index >= 2 && this.index <= 3) {

                    this.index = 1;

                } else {

                    this.index = 2

                }

            } else if (this.row == this.items.length - 1) {

                if (this.index <= 1) {

                    this.index = 0;

                } else if (this.index == 2) {

                    this.index = 1;

                } else if (this.index >= 3 && this.index <= 4) {

                    this.index = 2;

                } else {

                    this.index = 3

                }
            }
        }
    },

}

export default Control;