import AppConfig from '../AppConfig';
import { move } from '../Remote/Keys';
import pages, { ChangePage } from '../Remote/Pages';
import { el, append, remove_class, get_el, get_els } from '../Utils/Utils';
import get_word from '../Utils/Words';
import './ParentalCode.scss';
import Control from './ParentalCodeControl';

export default class ParentalCode {
    constructor ({ type = 'check', callback = () => { }, back_callback = () => { } }) {
        ParentalCode.type = type;
        ParentalCode.pin_code = '';
        ParentalCode.callback = callback;
        ParentalCode.back_callback = back_callback;
    }
    static parental_code_item_index = 0;
    static pin_code = '';
    static type = '';
    static callback;
    static back_callback;
    init() {

        this.render();
    }
    render() {

        ParentalCode.parental_code_item_index = 0;

        const parental_code_popup = el({
            attr: {
                class: 'parental-code-popup',
                id: 'parental_code_popup',
                style: `background-image: url(${AppConfig.background})`
            },
            children: [
                {
                    attr: {
                        class: 'parental-code-popup-title ',
                        id: 'parental_code_popup_title',

                    },
                    innerHTML: get_word('enter parental code')
                },
                {
                    attr: {
                        class: 'parental-code-popup-input',
                        id: 'parental_code_popup_input',
                    },
                    children: [
                        {
                            attr: {
                                class: 'parental-code-popup-input-conent',
                                id: 'parental_code_popup_input_conent',

                            },
                            render: (parent) => {
                                for (let i = 0; i < 4; i++) {
                                    append(parent, el({
                                        attr: {
                                            class: 'parental-code-popup-input-item ',
                                            index: i,
                                        }
                                    }))
                                }
                            }
                        },
                        {
                            attr: {
                                class: 'parental-code-popup-input-error',
                                id: 'parental_code_popup_input_error',
                            },

                        }
                    ]
                },
                {
                    attr: {
                        class: 'parental-code-popup-keyboard',
                        id: 'parental_code_popup_keyboard'
                    },
                    render: (parent) => {
                        ['0', 1, 2, 3, 4, 5, 6, 7, 8, 9, 'delete'].forEach((item, index) => {

                            let element = el({
                                attr: {
                                    class: 'parental-code-popup-keyboard-item parental-code-popup-keyboard-item-control',
                                    index: index,
                                    onclick: function () {
                                        ParentalCode.parental_code_popup_keyboard_item_click(this)
                                    },
                                    onmouseenter: ParentalCode.parental_code_popup_keyboard_item_mouse_enter,
                                    value: item
                                },

                            })

                            if (item == 'delete') element.addClass('delete')
                            else
                                element.innerHTML = item;

                            append(parent, element)
                        })
                    }
                }

            ]

        })


        append(document.body, parental_code_popup)
    }

    static parental_code_popup_keyboard_item_click(that) {

        let value = that.getAttribute('value');

        that.addClass('clicked');

        setTimeout(() => {
            that.removeClass('clicked');
        }, 50)

        let item = get_els('.parental-code-popup-input-item ')[ParentalCode.parental_code_item_index];

        get_el('#parental_code_popup_input_error').innerHTML = '';

        if (value == 'delete') {
            if (ParentalCode.parental_code_item_index == 0) return;

            ParentalCode.pin_code = ParentalCode.pin_code.substring(0, ParentalCode.pin_code.length - 1);
            --ParentalCode.parental_code_item_index;
            item = get_els('.parental-code-popup-input-item')[ParentalCode.parental_code_item_index];
            item.setAttribute('value', '');
            item.removeClass('filled');

            return;
        } else if (ParentalCode.parental_code_item_index == 4) return;


        item.setAttribute('value', value);
        item.addClass('filled');
        ParentalCode.pin_code += '' + value;
        ++ParentalCode.parental_code_item_index;

        if (ParentalCode.parental_code_item_index == 4) {

            if (ParentalCode.type == 'set') {
                ParentalCode.set_parental_code()
                return
            }

            let result = ParentalCode.parental_code_popup_check();

            setTimeout(() => {
                if (result) {
                    if (ParentalCode.type == 'check') {
                        ParentalCode.parental_code_back();
                        ParentalCode.callback();
                        return true;
                    } else {
                        ParentalCode.change_parental_code();
                    }
                }
            }, 500)
        }
    }

    static parental_code_popup_keyboard_item_mouse_enter() {

        let index = this.getAttribute('index');

        Control.parental_code.index = index;

        Control.set_current('parental_code')

    }

    static set_parental_code() {


        localStorage.setItem('parental_code', ParentalCode.pin_code);
        AppConfig.parental_code = ParentalCode.pin_code;

        let item = get_el('#parental_code_popup_input_error');
        item.addClass('success');
        item.innerHTML = get_word('password successfully changed')

        setTimeout(() => {
            ParentalCode.parental_code_back();

        }, 1000)
    }
    static change_parental_code() {
        Control.set_current('on_hold');
        Control.parental_code.index = 0;
        ParentalCode.type = 'set';

        get_el('#parental_code_popup_title').innerHTML = get_word('enter new parental code');
        setTimeout(() => {
            ParentalCode.reset_values();
            Control.set_previous();
        }, 500)

    }
    static parental_code_popup_check() {

        if (ParentalCode.pin_code == AppConfig.parental_code) {
            return true;
        } else {
            get_el('#parental_code_popup_input_error').innerHTML = get_word('wrong code');

            Control.set_current('on_hold');
            Control.parental_code.index = 0;
            setTimeout(() => {
                ParentalCode.reset_values();
                Control.set_previous();
            }, 500)
            return false;
        }
    }
    static reset_values() {
        let items = get_els('.parental-code-popup-input-item');
        for (let i = 0; i < items.length; i++) {
            items[i].removeClass('filled');
            items[i].removeAttribute('value');
        }
        ParentalCode.parental_code_item_index = 0;
        ParentalCode.pin_code = '';
    }
    static parental_code_back() {
        ParentalCode.back_callback();
        ChangePage(pages.previous, {}, true, false);
    }
}