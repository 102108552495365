
// import Request from "../../Utils/Requests";
// import { el, append } from "../../Utils/Utils";
import { xtream_instance } from "..";
import PlayerSettings from "../Components/PlayerSettings/PlayerSettings";
import { get_el } from "../Utils/Utils";
import Player from "./Player";
import pauseIcon from './images/pause.png'
import playIcon from './images/play.png'
// import PlayerSettings from "./PlayerSettings";


export default class NativePlayer {
    constructor () { }
    static player_data = null;
    static current_time = 0;
    static retry_count = 0;
    static retry_timeout = null;


    async init(data, current_time) {

        this.destroy();


        if (data) {
            NativePlayer.player_data = data
            Player.played_data = data;

        };
        NativePlayer.current_time = current_time || 0;
        if (data.stream_type == 'live') {
            Player.recently_watched(data)
        }
        Player.video_loading();
        Player.video_error('remove');


        NativePlayer.retry_count = 0;


        let url = xtream_instance.get_stream_url(data);

        // url = 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/Sintel.mp4'
        // url = 'https://media.istockphoto.com/id/1426881162/video/soccer-football-championship-stadium-with-crowd-of-fans-blue-team-attack-and-score-goal.mp4?s=mp4-640x640-is&k=20&c=5uaH1mM-5nF9n6dqQmo7-Wp4n21EaUqnVanyH9Th4Rs='

        this.set_web_listeners();

        this.play(url)

    }
    set_web_listeners() {

        video_player.onloadedmetadata = () => {
            Player.video_error('remove');
            Player.video_loading('remove');


            // get total track info
            let type = Player.played_data.stream_type || 'series';
            PlayerSettings.init(type);

            if (NativePlayer.current_time && Player.played_data.stream_type != 'live') {
                video_player.currentTime = NativePlayer.current_time;


            }

            if (Player.play_button) Player.play_button.setAttribute('style', `background-image: url(${pauseIcon})`)

        }

        video_player.onplay = () => {

            Player.change_sec_mode = false;
        }

        video_player.oncanplaythrough = () => {
            if (NativePlayer.current_time && sec_to) {
                video_player.currentTime = NativePlayer.current_time;
                sec_to = false;
            }

        }

        let sec_to = true;


        video_player.ontimeupdate = function () {

            if (!NativePlayer.player_data) return;

            if (Player.played_data.stream_type != 'live') {

                if (!Player.change_sec_mode && Player.is_player_init) {

                    let current_time = parseInt(video_player.currentTime) || 0;

                    let duration = video_player.duration || 0;

                    if (Player.current_time <= Player.duration) {

                        Player.current_time = current_time;

                        Player.duration = duration;

                        Player.set_time_update();
                    }

                    if (PlayerSettings.show_subtitle && PlayerSettings.is_selected_open_subtitle) {

                        Player.show_selected_open_subtitle();

                    }
                }


            }

        }

        video_player.onerror = () => {

            if (NativePlayer.retry_count < 5 && Player.played_data.stream_type == 'live') {

                NativePlayer.retry_timeout = setTimeout(() => {

                    NativePlayer.retry_count++;

                    Player.player_instance.play(xtream_instance.get_stream_url(NativePlayer.player_data));

                }, 1000);

            } else {

                Player.video_loading('remove')

                Player.video_error();

                Player.player_state = 'pause';

            }

        }

        video_player.onended = () => {

            if (Player.played_data.stream_type == 'live') {

                Player.player_instance.init(NativePlayer.player_data);

            } else {

                Player.stop();

            }

        }

    }

    play(url) {
        try {
            // url = 'https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4'


            if (video_player) {

                video_player.src = url;

                video_player.play();

                Player.player_state = 'play';
            }

            if (Player.pause_icon) Player.pause_icon.removeClass('show');

            if (Player.play_button) Player.play_button.setAttribute('style', `background-image: url(${pauseIcon})`)

        } catch (e) {
            console.log(e);
        }

    }
    play_video() {

        if (document.getElementById('video_player')) {

            video_player.play();

            Player.player_state = 'play';

            if (Player.pause_icon) Player.pause_icon.removeClass('show');

            if (Player.play_button) Player.play_button.setAttribute('style', `background-image: url(${pauseIcon})`)

        }

    }
    pause() {

        if (document.getElementById('video_player')) {
            video_player.pause();
            Player.player_state = 'pause';
            if (Player.pause_icon) Player.pause_icon.addClass('show');

            if (Player.play_button) Player.play_button.setAttribute('style', `background-image: url(${playIcon})`)
        }
    }
    stop() {
        console.error('stop')
        if (document.getElementById('video_player')) {
            video_player.pause();
            video_player.currentTime = 0;
            Player.player_state = 'pause';
            if (Player.pause_icon) Player.pause_icon.addClass('show');

            if (Player.play_button) Player.play_button.setAttribute('style', `background-image: url(${playIcon})`)
        }
    }
    play_pause() {


        if (Player.player_state == 'pause') {

            video_player.play();

            if (Player.pause_icon) Player.pause_icon.removeClass('show');

            if (Player.play_button) Player.play_button.setAttribute('style', `background-image: url(${pauseIcon})`)

            Player.player_state = 'play';

        } else {

            video_player.pause();

            Player.player_state = 'pause';

            if (Player.play_button) Player.play_button.setAttribute('style', `background-image: url(${playIcon})`)

            if (Player.pause_icon) Player.pause_icon.addClass('show');
        }
    }
    seek_to() {
        console.error('seek_to')
        video_player.currentTime = Player.current_time;
        if (Player.state_after_seek == 'play') {
            Player.player_instance.play_video()
        }


    }
    set_full_screen() {
        let item = get_el('#player_parent')
        item.addClass('full-screen');
    }
    hide_full_screen() {
        let item = get_el('#player_parent')
        item.removeClass('full-screen');
    }
    destroy() {
        clearTimeout(NativePlayer.retry_timeout);
        video_player.pause();
        video_player.src = '';
        video_player.onerror = null;
        video_player.onplay = null;
        video_player.ontimeupdate = null;
        video_player.onended = null;
        video_player.onloadedmetadata = null;
        NativePlayer.player_data = null;

    }

}